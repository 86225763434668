import React from "react";
import Arrow from 'assets/icons/CardArrowVector.svg'
import { Cell, Column, } from "react-table";

const fetchColumns = (
  toggleCheckStatus: (id: number | undefined) => void,
  toggleAllCheckbox: () => void,
  toggleAllStatus: boolean
) => {
  const columns: Column[] = [
    {
      Header: () => {
        return (
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className="checkbox header-checkbox m-auto"
              checked={toggleAllStatus}
              onChange={() => toggleAllCheckbox()}
            />
            <span className="ml-3">External Description</span>
          </div>

        );
      },
      id: 'checkbox',
      accessor: '',
      minWidth: 230,
      columns: [
        {
          Header: '',
          accessor: 'Long',
          minWidth: 100,
          Cell: ({ value, row }: any) => {
            return <div className="d-flex">
              <input
                type="checkbox"
                className="checkbox header-checkbox padding-checkbox m-0 "
                checked={row?.original?.isSelected}
                onChange={() => toggleCheckStatus(row?.id)}
              />
              <div role="link" tabIndex={0} className="ml-4"  >{value}</div>
            </div>
          }
        },
      ]
    },
    {
      Header: 'Tag',
      minWidth: 200,
      columns: [
        {
          Header: '',
          accessor: 'Tag',
          minWidth: 100,
          Cell: (cell: Cell) => <div role="link" tabIndex={0} >{cell.value}</div>
        },
      ]
    },
    {
      Header: 'Periodicity',
      minWidth: 200,
      columns: [
        {
          Header: '',
          accessor: 'Periodicity',
          minWidth: 100,
          Cell: (cell: Cell) => <div role="link" tabIndex={0} >{cell.value}</div>
        },
      ]
    },
    {
      Header: 'Duration',
      accessor: '',
      minWidth: 100,
      columns: [
        {
          Header: 'Year',
          accessor: 'YearBr',
          minWidth: 100,
          Cell: (cell: Cell) => <div className="cell-padding" role="link" tabIndex={0}  >{cell.value}</div>
        },
        {
          Header: 'Quarter',
          accessor: 'QuarterBr',
          minWidth: 100,
          Cell: (cell: Cell) => <div role="link" tabIndex={0}  >{cell.value}</div>
        },
        {
          Header: 'Month',
          accessor: 'MonthBr',
          minWidth: 100,
          Cell: (cell: Cell) => <div role="link" tabIndex={0}  >{cell.value}</div>
        },
        {
          Header: 'Week',
          accessor: 'WeekBr',
          minWidth: 100,
          Cell: (cell: Cell) => <div role="link" tabIndex={0}  >{cell.value}</div>
        },
      ]
    },
    {
      Header: 'Period number',
      minWidth: 200,
      columns: [
        {
          Header: 'Min',
          accessor: 'MinPeriodNumBr',
          minWidth: 100,
          Cell: (cell: Cell) => <div role="link" tabIndex={0} >{cell.value}</div>
        },
        {
          Header: 'Max',
          accessor: 'MaxPeriodNumBr',
          minWidth: 100,
          Cell: (cell: Cell) => <div role="link" tabIndex={0} >{cell.value}</div>
        },
      ]
    },
    {
      Header: 'Country week start day',
      minWidth: 200,
      columns: [
        {
          Header: '',
          accessor: 'WeekStartDayCountry',
          minWidth: 100,
          Cell: (cell: Cell) => <div role="link" tabIndex={0} >{cell.value}</div>
        },
      ]
    },
    {
      Header: 'Date',
      accessor: 'fileName',
      minWidth: 40,
      columns: [
        {
          Header: 'Start',
          accessor: 'PeriodStartDate',
          minWidth: 100,
          Cell: (cell: Cell) => <div role="link" tabIndex={0} >{cell.value}</div>
        },
        {
          Header: 'End',
          accessor: 'PeriodEndDate',
          minWidth: 100,
          Cell: (cell: Cell) => <div role="link" tabIndex={0} >{cell.value}</div>
        },
      ]
    },
    {
      Header: '',
      id: 'icon',
      Cell: (cell: Cell) => <div role="link" tabIndex={0} className='cell-hyperlink'>
        <img src={Arrow} alt='alt' />
      </div>
    }
  ];

  return columns
}

export default fetchColumns