
import {serializable, alias, object, list, primitive} from 'serializr';

export class MarketUnProcessedRecord { 

	@serializable(alias('Id', primitive()))
	Id?: number;

	@serializable(alias('FileName', primitive()))
	FileName?: string;

	@serializable(alias('Tag', primitive()))
	Tag?: string;

	@serializable(alias('Long', primitive()))
	Long?: string;

	@serializable(alias('HierName', primitive()))
	HierName?: string;

	@serializable(alias('HierLevelName', primitive()))
	HierLevelName?: string;

	@serializable(alias('Country', primitive()))
	Country?: string;

	@serializable(alias('Category', primitive()))
	Category?: string;

	@serializable(alias('Cell', primitive()))
	Cell?: string;

	@serializable(alias('Createdon', primitive()))
	Createdon?: string;

}