import React from 'react';
import './Dropdown.scss';

interface optionType {
  value?: string | undefined
  label?: string | undefined
}
interface SelectInputProps {
  options: optionType[]
  height: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  placeholder?: string,
  className?: string,
  value?: string
}

const SelectInput: React.FC<SelectInputProps> = (props: SelectInputProps) => {

  const { options, height, onChange, placeholder, className, value } = props
  let _options = options
  if (placeholder) {
    _options = [
      {
        label: placeholder,
        value: ""
      },
      ...options
    ]
  }

  const style = {
    height: height ?? '40px',
    fontSize: '12px',
    color: '#576571'
  };
  return (
    <select style={style} value={value} onChange={onChange} className={`dropdown-styling ${className} `} >
      {_options.map((option: optionType) => (
        <option key={option.value} value={option.label} style={style}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
export default SelectInput;
