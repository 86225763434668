
import {serializable, alias, object, list, primitive} from 'serializr';

export class IndividualFileVolatility { 

	@serializable(alias('LogId', primitive()))
	id?: string;

	@serializable(alias('COUNTRY', primitive()))
	COUNTRY?: string;

	@serializable(alias('FILELASTMODIFIEDDATE', primitive()))
	FILELASTMODIFIEDDATE?: string;

	@serializable(alias('FILENAME', primitive()))
	FILENAME?: string;

	@serializable(alias('CATEGORY', primitive()))
	CATEGORY?: string;

	@serializable(alias('LOADDESC', primitive()))
	LOADDESC?: string;

	@serializable(alias('LOADENDTIME', primitive()))
	LOADENDTIME?: string;

	@serializable(alias('LOADSTARTTIME', primitive()))
	LOADSTARTTIME?: string;

	@serializable(alias('LogId', primitive()))
	LogId?: number;

	@serializable(alias('MARKET', primitive()))
	MARKET?: string;

	@serializable(alias('PIPELINERUNID', primitive()))
	PIPELINERUNID?: string;

	@serializable(alias('PIPELINESTATUS', primitive()))
	PIPELINESTATUS?: string;

	@serializable(alias('SOURCE', primitive()))
	SOURCE?: string;

	@serializable(primitive())
	isSelected?: boolean = false;

	@serializable(primitive())
	isChecked?: boolean = false;
	
	@serializable(alias('createdAt', primitive()))
	createdAt?: string;

	@serializable(alias('updatedAt', primitive()))
	updatedAt?: string;



}