import React, { useState, forwardRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './SidebarDropdown-component.scss';
import DropdownIcon from 'assets/icons/dropdown-icon.svg';
import DropdownCross from 'assets/icons/dropdown-cross.svg';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { addToken } from 'interceptor/axiosInstance';
import Loader from 'shared/components/Loader';

const CustomToggle = forwardRef<
  HTMLAnchorElement,
  { resetIcon?: boolean; children: React.ReactNode; onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void }
>(({ children, onClick, resetIcon }, ref) => (
  /*eslint-disable */
  <>
    <a
      href=''
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className='toggle-button'
    >
      {children}
      <div className='icon-container'>
        <p>{resetIcon}</p>
        <div className='dd-separator' />
        <img src={DropdownIcon} alt='dropdown icon' className='dd-down' />
      </div>
    </a>
  </>
  /*eslint-enable */
));

const CustomMenu = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode; style: React.CSSProperties; className: string; 'aria-labelledby': string }
>(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <SearchInputField onChange={(e: any) => setValue(e.target.value)} placeholder='Search' width='354px' height='48px' />
      <ul className='list-unstyled'>
        {React.Children.toArray(children).filter(
          (child) => !value || (child as React.ReactElement<{ children: string }>).props.children?.toLowerCase()?.includes(value)
        )}
      </ul>
    </div>
  );
});

interface NewEDDropdown {
  value?: string;
  className?: string;
  currentItemIndex: number;
  updateValue: (name: string) => void;
  defaultValue: () => string;
  resetIcon?: boolean;
  resetFunc: () => void;
  isDisabled: () => boolean;
}

const NewEDDropdown = (props: NewEDDropdown) => {
  const { defaultValue, updateValue, resetFunc, isDisabled } = props;
  const [optionItem, setOptionItem] = useState([]);
  const fetchOptions = async () => {
    const url = ApiRoutes.BASE_URL;
    const data = await fetch(`${url}${ApiRoutes.FACT_DROPDOWN}${defaultValue()}`, {
      method: 'GET',
      headers: await getToken()
    });
    const res = await data.json();
    setOptionItem(res);
  };

  const [dropdownValue, setDropdownValue] = useState<string>();

  const internalResetFunction = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    e.preventDefault();
    resetFunc();
  };

  const getToken = async () => {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${await addToken()}`);
    return myHeaders;
  };

  return (
    <Dropdown
      className={isDisabled() ? 'search-dropdown-container-product disabled' : 'search-dropdown-container-product'}
      onClick={() => fetchOptions()}
    >
      <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' className='toggle-container' resetIcon={props.resetIcon}>
        <div className='value-container'>
          <p>{props.value}</p>
        </div>
        <div className='separator' />
        {props.value ? (
          <div
            className='dd-cross-container'
            onKeyDown={internalResetFunction}
            role='button'
            tabIndex={0}
            onClick={() => {
              internalResetFunction;
            }}
          >
            <img src={DropdownCross} alt='dropdown icon' className='dd-cross' />{' '}
          </div>
        ) : null}
      </Dropdown.Toggle>
      {optionItem ? (
        <Dropdown.Menu as={CustomMenu} className='toggle-menu'>
          {optionItem.map((item: any) => {
            return (
              <Dropdown.Item
                onClick={() => {
                  setDropdownValue(item.name);
                  updateValue(item.name);
                }}
              >
                {item.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      ) : null}
    </Dropdown>
  );
};

export default NewEDDropdown;
