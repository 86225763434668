// Table.js

import React, { useEffect } from 'react';
import { useTable } from 'react-table';
import './Table-component.scss';

interface Props {
  columns: any;
  data: any;
  tableFor?:string;
}

const Table: React.FC<Props> = ({ columns, data,tableFor }: Props) => {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  }); /*
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */

  return (
    <table style={{ width: '100%' }} className={tableFor==='highMedium'?'table-component-highMedium':'table-component'} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className='table-header'>
            {headerGroup.headers.map((column) => {
              const data = column.render('Header');
              return (
                <th
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width }
                  })}
                  className={typeof data === 'string' ? 'data-exist' : 'empty'}
                >
                  {data}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps({
                      style: {
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                        wordBreak:'break-all'
                      }
                    })}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default Table;
