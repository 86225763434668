import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Tabs.scss';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
interface content {
  key: string;
  title: string | JSX.Element;
  component: React.ReactNode;
}
interface Props {
  components: content[];
  small?: boolean;
  active: string;
}

const TabComponent: React.FC<Props> = ({ components, active, small }: Props) => {
  const { id } = useParams();
  const queryP = new URLSearchParams(useLocation().search);
  const fileName = queryP.get('FileName');
  const navigate = useNavigate();

  function onTabChange(key: any) {
    navigate({
      pathname: `../fact/${id}/${key}`,
      search: createSearchParams({
        FileName: fileName as string
      }).toString()
    }, {replace: true});
  }
  
  return (
    <div>
      <Tabs defaultActiveKey={active} id='uncontrolled-tab-example' className='mb-3 tab-component' onSelect={onTabChange}>
        {components.map((component, index) => {
          return (
            <Tab
              className={index === components.length - 1 ? 'nav-link last-child' : 'nav-link'}
              eventKey={component.key}
              title={component.title}
            >
              {component.component}
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default TabComponent;
