import React, { useEffect, useState } from 'react';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import { Col, Container, Row } from 'react-bootstrap';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import BreadcrumbComponent from 'shared/components/Breadcrumb-component/Breadcrumb-component';
import NewEDDropdown from 'shared/components/NewEDDropdown';
import { useNavigate } from 'react-router';
import DimensionDetails from './DimensionDetails';
import DqCheckService from 'services/DqCheckService/dqCheck.service';
import './dqCheckPage.scss';
import { MetaData } from 'models/MetaData/metaData.model';
import MetaService from 'services/MetaService/meta.service';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import { SkeletonLoaderSizeTypes } from 'enums/skeletonLoaderType';

export interface DQFilterParams {
  limit?: number;
  offset?: number;
  filter_by_delivery_period?: string;
  filter_by_country?: string;
  filter_by_data_provider?: string;
  filter_by_category?: string;
  filter_by_in_success: boolean;
  filter_by_in_failure: boolean;
  filter_by_in_progress: boolean;
}

const DqCheckPage = () => {
  const navigate = useNavigate();

  const [changedFilter, setChangedFilter] = useState<keyof DQFilterParams>();

  const [filters, setFilters] = useState<DQFilterParams>({
    limit: 10,
    offset: 0,
    filter_by_delivery_period: '',
    filter_by_country: '',
    filter_by_data_provider: '',
    filter_by_category: '',
    filter_by_in_success: false,
    filter_by_in_failure: false,
    filter_by_in_progress: false
  });

  const updateFilters = (filterName: keyof DQFilterParams, value: string | boolean) => {
    setFilters({
      ...filters,
      [filterName]: value
    });

    switch (filterName) {
      case 'filter_by_category':
        setChangedFilter(filterName);
        fetchMeta(ApiRoutes.DQ_CHECKS_DELIVERY_COUNTRY_META, setCountryMeta, MetaData, { category: String(value) });
        break;
      case 'filter_by_country':
        setChangedFilter(filterName);
        fetchMeta(ApiRoutes.DQ_CHECKS_DELIVERY_CATEGORY_META, setCategoryMeta, MetaData, { country: String(value) });
        break;
    }
  };

  const {
    buttonLoading,
    fetchDqChecks,
    dqChecks,
    loading,
    dqChecksPagination: pagination,
    fetchDQCardTotalCells,
    fetchDQCardTotalFiles,
    fetchDQCardFVSuccessRate,
    fetchDQCardAverageFVError,
    fetchDQCardScore,
    fetchDQCardTotalScore,
    dqStats,
    fetchDqChecksPaginationData,
    paginationLoading
  } = DqCheckService();

  const { loading: metaLoading, fetchMeta } = MetaService();

  const [countryMeta, setCountryMeta] = useState<MetaData[]>([]);

  const [categoryMeta, setCategoryMeta] = useState<MetaData[]>([]);

  const [providerMeta,setProviderMeta] = useState<MetaData[]>([]);

  useEffect(() => {
    handleFetchMeta();
  }, []);

  useEffect(() => {
    fetchDQCardTotalCells(),
    fetchDQCardTotalFiles(),
    fetchDQCardFVSuccessRate(),
    fetchDQCardAverageFVError(),
    fetchDQCardScore()
    fetchDQCardTotalScore()
  }, []);

  useEffect(()=>{
    fetchDqChecks(filters);
    fetchDqChecksPaginationData(filters);
  }, [filters])

  const breadcrumbLinks = [
    { link: 'Reports', active: false, onClick: () => navigate(`/${AppRoutes.FILE_VOLATILITY_HOME}`) },
    { link: 'Data Quality', active: true }
  ];

  const handlePagination = (pageNumber: number, recordsPerPage?: number) => {
    const currentPageSize = pagination?.pageSize ?? 0;
    const limit = recordsPerPage ?? currentPageSize;
    const offset = (pageNumber - 1) * limit;
    fetchDqChecks({
      ...filters,
      limit,
      offset
    });
    fetchDqChecksPaginationData({
      ...filters,
      limit,
      offset
    });
  };

  const handleFetchMeta = () => {
    fetchMeta(ApiRoutes.DQ_CHECKS_DELIVERY_COUNTRY_META, setCountryMeta, MetaData);
    fetchMeta(ApiRoutes.DQ_CHECKS_DELIVERY_CATEGORY_META, setCategoryMeta, MetaData);
  };

  // const fvSuccessRate = dqStats?.fvSuccessRate ?? 0;


  const statsHeaderData = [
    {
      headerName: 'Total cells',
      value:  dqStats.TotalCells
    },
    {
      headerName: 'Total Files',
      value: dqStats.TotalFiles
    },
    {
      headerName: 'File Volatility Succes Rate',
      value: dqStats.FVSuccessRate ? +(dqStats.FVSuccessRate*100).toFixed(2)+'%' : '-'
    },
    {
      headerName:'Average FV Errors',
      value: dqStats.AverageFVError ? +(dqStats.AverageFVError*100).toFixed(2) : '-'
    },
    {
      headerName:'DQ Score',
      value: dqStats.DQScore ? +(dqStats.DQScore*100).toFixed(2) : '-'
    },
    {
      headerName:'Provider Total DQ Score',
      value: dqStats.TotalDQScore ? + (dqStats.TotalDQScore*100).toFixed(2) : '-'
    }
  ];

  return (
    <DashboardContainer>
      <Container fluid className='page-container dq-check-page'>
        <Row>
          <Col sm={6}>
            <div className='breadcrumb-container'>
              <p className='page-heading'>Data Quality</p>
              <BreadcrumbComponent links={breadcrumbLinks} />
            </div>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col sm={12}>
            <div className='summary-header d-flex justify-content-between'>
              {statsHeaderData.map((item, index) => (
                <div key={index} className='aligh-item-center d-flex flex-column'>
                  <span className='header-name'>{item.headerName}</span>
                  {buttonLoading ? (
                    <SkeletonLoader xs={8} size={SkeletonLoaderSizeTypes.SMALL} className='skeleton-loading' />
                  ) : (
                    <span className='header-value'> {item.value}</span>
                  )}
                </div>
              ))}
            </div>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col sm={6}>
            <div className='filters-container'>
              <div className='filter-container'>
                <NewEDDropdown
                  loading={changedFilter ? changedFilter === 'filter_by_category' && metaLoading : metaLoading}
                  optionItems={countryMeta}
                  value={filters.filter_by_country}
                  currentItemIndex={2}
                  labelText='Country'
                  resetFunc={() => updateFilters('filter_by_country', '')}
                  resetIcon={true ? filters.filter_by_country !== '' : false}
                  defaultValue=''
                  updateValue={(value) => updateFilters('filter_by_country', value)}
                />
              </div>
              <div className='filter-container'>
                <NewEDDropdown
                  loading={changedFilter ? changedFilter === 'filter_by_country' && metaLoading : metaLoading}
                  optionItems={categoryMeta}
                  value={filters.filter_by_category}
                  currentItemIndex={2}
                  resetIcon={true ? filters.filter_by_category !== '' : false}
                  resetFunc={() => updateFilters('filter_by_category', '')}
                  labelText='Category'
                  defaultValue='All(22)'
                  updateValue={(value) => updateFilters('filter_by_category', value)}
                />
              </div>
              {/* <div className='filter-container'>
                <NewEDDropdown
                  loading={metaLoading}
                  optionItems={providerMeta}
                  value={filters.filter_by_data_provider}
                  currentItemIndex={2}
                  resetIcon={true ? filters.filter_by_category !== '' : false}
                  resetFunc={() => updateFilters('filter_by_data_provider', '')}
                  labelText='Provider'
                  defaultValue=''
                  updateValue={(value) => updateFilters('filter_by_data_provider', value)}
                />
              </div> */}
            </div>
          </Col>
        </Row>

        <DimensionDetails
          tableData={dqChecks}
          loading={loading}
          pagination={pagination}
          handlePagination={handlePagination}
          updateFilters={updateFilters}
          filters={filters}
          paginationLoading={paginationLoading}
        />
      </Container>
    </DashboardContainer>
  );
};

export default DqCheckPage;
