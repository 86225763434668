import React, { FC } from 'react'
import WipTrolley from 'assets/icons/wip-trolley.png'

import "./workInProgress.scss"

interface WorkInProgressProps { }

const WorkInProgress: FC<WorkInProgressProps> = (props) => {

    const { } = props

    return (
        <div className="work-in-progress">
            <img src={WipTrolley} alt='alt' />
            <p>
                Work in progress
            </p>
        </div>
    )
}

export default WorkInProgress