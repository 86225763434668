export const SidebarDropDownProduct = [
  {
    attributeName: 'Division Name',
    internal: 'Divisionname',
    external: 'NA',
    apiEndPoint: 'division-name',
    dependsOn: [],
    apiDependsOn:[],
    value: null
  },
  {
    attributeName: 'Category Name',
    internal: 'Categoryname',
    external: 'NA',
    apiEndPoint: 'category-name',
    dependsOn: ['Divisionname'],
    apiDependsOn: ['Divisionname'],
    value: null
  },
  {
    attributeName: 'Company Name',
    external: 'Company',
    internal: null,
    apiEndPoint: '',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: 'Hierarchy Name',
    external: 'Hiername',
    internal: null,
    apiEndPoint: '',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: 'Hierarchy Level Name',
    external: 'Hierlevelname',
    internal: null,
    apiEndPoint: '',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: 'Market Name',
    internal: 'Marketname',
    external: 'NA',
    apiEndPoint: 'market-name',
    dependsOn: ['Categoryname', 'Divisionname'],
    apiDependsOn: ['Categoryname', 'Divisionname'],
    value: null
  },
  {
    attributeName: 'Corporate Brand Name',
    internal: 'Corporatebrandname',
    external: 'Brand',
    apiEndPoint: 'corporate-brand-name',
    dependsOn: ['Marketname', 'Categoryname', 'Divisionname'],
    apiDependsOn: ['Marketname', 'Categoryname', 'Divisionname'],
    value: null
  },
  {
    attributeName: 'Sector Name',
    internal: 'Sectorname',
    external: 'NA',
    apiEndPoint: 'sector-name',
    dependsOn: ['Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    apiDependsOn: ['Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    value: null
  },
  {
    attributeName: 'Segment Name',
    internal: 'Segmentname',
    external: 'NA',
    apiEndPoint: 'segment-name',
    dependsOn: ['Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    apiDependsOn: ['Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    value: null
  },
  {
    attributeName: 'Product Form Name',
    internal: 'Productformname',
    external: 'NA',
    apiEndPoint: 'product-form-name',
    dependsOn: ['Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    apiDependsOn: ['Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    value: null
  },
  {
    attributeName: 'Form Name',
    internal: 'Formname',
    external: 'NA',
    apiEndPoint: 'form-name',
    dependsOn: ['Productformname', 'Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    apiDependsOn: ['Productformname', 'Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    value: null
  },
  {
    attributeName: 'Subform Name',
    internal: 'Subformname',
    external: 'NA',
    apiEndPoint: 'sub-form-name',
    dependsOn: [
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null
  },
  {
    attributeName: 'Sub Product Form Variant Name',
    internal: 'Spfvname',
    external: 'NA',
    apiEndPoint: 'sub-product-form-variant-name',
    dependsOn: [
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null
  },
  {
    attributeName: 'Product Pack Form Name',
    internal: 'Productpackformname',
    external: 'NA',
    apiEndPoint: 'product-pack-form-name',
    dependsOn: [
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null
  },
  {
    attributeName: 'Product Packsize Name',
    internal: 'Productpacksizename',
    external: 'NA',
    apiEndPoint: 'product-pack-size-name',
    dependsOn: [
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null
  },
  {
    attributeName: 'Product Variant Name',
    internal: 'Productvariantname',
    external: 'NA',
    apiEndPoint: 'product-variant-name',
    dependsOn: [
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null
  },
  {
    attributeName: 'Product Code Name',
    internal: 'Productcodename',
    external: 'NA',
    apiEndPoint: 'product-code-name',
    dependsOn: [
      'Productvariantname',
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Productvariantname',
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null
  },
  {
    attributeName: 'Product Description',
    external: 'Externaldesc',
    internal: 'Productname',
    apiEndPoint: 'product-name',
    dependsOn: [
      'Productcodename',
      'Productvariantname',
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Productcodename',
      'Productvariantname',
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null
  }
];
