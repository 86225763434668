
import { serializable, alias, object, list, primitive } from 'serializr';

export class SmartMappingMappedResult {

	@serializable(alias('Id', primitive()))
	id?: number;

	@serializable(alias('TAG', primitive()))
	TAG?: string;

	@serializable(alias('Filename', primitive()))
	Filename?: string;

	@serializable(alias('Internaldesc', primitive()))
	UNILEVERDESC?: string;

	@serializable(alias('Externaldesc', primitive()))
	VENDORDESC?: string;

	@serializable(alias('Skucode', primitive()))
	SKUCODE?: string;

	@serializable(alias('Categoryname', primitive()))
	CATEGORY?: string;

	@serializable(alias('Divisionname', primitive()))
	DIVISION?: string;

	@serializable(alias('Categorygroupname', primitive()))
	CATEGORYGROUP?: string;

	@serializable(alias('Segmentname', primitive()))
	SEGMENT?: string;

	@serializable(alias('Productformmediumname', primitive()))
	productFormMediumName?: string;

	@serializable(alias('Brandformname', primitive()))
	brandFormName?: string;

	@serializable(alias('Confidencelevel', primitive()))
	MAPPEDSTATUS?: boolean;

	@serializable(alias('createdAt', primitive()))
	createdAt?: string;

	@serializable(alias('updatedAt', primitive()))
	updatedAt?: string;

	@serializable(primitive())
	isSelected?: boolean = false;

	@serializable(primitive())
	isChecked?: boolean = false;

}