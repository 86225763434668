import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import UnmappedCup from 'assets/icons/unmapped-cup.svg';
import ArrowImg from 'assets/icons/CardArrowVector.svg';
import ValidationFactService from 'services/ValidationFactService/validationFact.service';
import { useLocation, useParams } from 'react-router';
import ToastComponent from 'shared/components/Toast-component/Toast-component';
import './ValidationFactDetailsLow.scss';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import Loader from 'shared/components/Loader';
import { addToken } from 'interceptor/axiosInstance';
import Table from '../Component/Table-component/Table-component';
import CustomButton from 'shared/components/Button-component/Button';
import ValidationService from 'services/ValidationService/validation.service';

const ValidatioFactDetailsLow = (props: any) => {
  let data = props.Data;
  const { id, confidenceLevel } = useParams();
  const queryP = new URLSearchParams(useLocation().search);
  const fileName = queryP.get('FileName');
  const [showTable, setshowTable] = React.useState<number | undefined>();
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const { toggleMappedCheckStatus, onSubmit, factDetailRes } = ValidationFactService();
  const [descResponse, setDescResponse] = useState([]);
  const [selectedInternalDesc, setSelectedInternalDesc] = useState('');
  let [selectedExternalDesc, setSelectedExternalDesc] = useState('');
  const [selectedFactType, setSelectedFactType] = useState('');
  const detail: any = factDetailRes || undefined;
  const [nearestMatchCount, setNearestMatchCount] = useState(0);

  const { buttonLoading, downloadExcelFile } = ValidationService();

  const onClickExternalDesc = async (Description: any) => {
    const url = ApiRoutes.BASE_URL;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${await addToken()}`);
    const response = await fetch(`${url}${ApiRoutes.FILE_VOLATILITY_MAPPING_FACT_LOW}?Externaldesc=${Description}`, {
      method: 'GET',
      headers: myHeaders
    });
    const data = await response.json();
    setNearestMatchCount(data.data.count);
    setDescResponse(data.data.rows);
  };

  const handleShowTable = (index: string | undefined, id: string | undefined, Externaldesc: any) => {
    if (!index) return;
    let selectedIndex = parseInt(index);
    toggleMappedCheckStatus(id);
    setshowTable(selectedIndex);
    onClickExternalDesc(Externaldesc);
    setSelectedExternalDesc(Externaldesc);
  };

  const toggleUnMappedCheckStatus = (id: any) => {
    const updatedvalue: any = descResponse.map((item: any) => {
      if (id === item.id) {
        setSelectedInternalDesc(item.Internaldesc);
        setSelectedFactType(item.Facttype);
        item['isChecked'] = true;
      } else item['isChecked'] = false;
      return item;
    });
    setDescResponse(updatedvalue);
  };

  const getToken = async () => {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${await addToken()}`);
    return myHeaders;
  };

  const onUpdate = async () => {
    const url = ApiRoutes.BASE_URL;
    const res = await onSubmit(fileName, selectedExternalDesc, selectedInternalDesc, selectedFactType);
    const response = await fetch(`${url}${ApiRoutes.VALIDATION_FACT_DETAIL}/low?FileName=${fileName}`, {
      method: 'GET',
      headers: await getToken()
    });
    // const pagination = deserialize(Pagination, response) as Pagination;
    // setPagination(pagination);
    const resData = await response.json();
    data = resData;
    data = [...data];
    toggleToast();
  };

  const toggleToast = () => {
    setShowToast(!showToast);
  };

  const columns: any = [
    {
      accessor: 'isChecked',
      Cell: ({ value, row }: any) => {
        return <input type='radio' className='radio' checked={value} onChange={() => toggleUnMappedCheckStatus(row?.original?.id)} />;
      },
      minWidth: 30
    },
    {
      Header: 'Description',
      accessor: 'Internaldesc',
      minWidth: 500
    },
    {
      Header: 'Type',
      accessor: 'Facttype',
      minwidth: 500
    }
  ];

  if (!id) return null;

  const handleDownload = () => {
    if (id && confidenceLevel && fileName)
      downloadExcelFile(ApiRoutes.DOWNLOAD_FACT_EXCEL, id, {
        confidenceLevel,
        fileName
      });
  };

  return props?.loading ? (
    <Loader />
  ) : (
    <Container fluid className='container-unmapped'>
      <ToastComponent showState={showToast} toggleShow={toggleToast} message={detail?.data.message} />
      <Col sm={4}>
        <CustomButton disabled={!props.recordsCount} onClick={handleDownload}>
          {buttonLoading ? 'Downloading...' : 'Download File'}
        </CustomButton>
      </Col>
      <Row className='header-row'>
        <Col sm={3} className='unmapped-items-header'>
          <div className={`count ${showTable !== undefined ? '' : ' disabled'} `}>{data?.length}</div>
          <div className='heading-container'>
            <h2>External description</h2>
            <p>From data provider’s file</p>
          </div>
        </Col>
        <Col sm={5} className='nearest-match-header'>
          <div className={`count ${showTable !== undefined ? '' : ' disabled'} `}>{showTable !== undefined ? nearestMatchCount : 0}</div>
          <div className='heading-container'>
            <h2>Nearest matches</h2>
            <p>From Unilever database selected item</p>
          </div>
        </Col>
        <Col sm={4}>
          <SearchInputField placeholder='Search...' width='354px' height='48px' />
        </Col>
      </Row>
      <Row>
        <Col sm={3} className='list-container'>
          {data?.map((item: any, index: any) => {
            return (
              <button
                className='list-item-container'
                type='button'
                onClick={() => {
                  handleShowTable(index.toString(), item?.id?.toString(), item.Externaldesc);
                  setSelectedInternalDesc('');
                  setSelectedFactType('');
                }}
                style={{
                  border: showTable === index ? '1px solid #09a75e' : '1px solid #D9D9D9'
                }}
              >
                {item.Externaldesc}
                {showTable === index && <img src={ArrowImg} alt='arrow' className='arrow-logo-btn' style={{ marginLeft: '0.5rem' }} />}
              </button>
            );
          })}
        </Col>
        <Col sm={9} className='span-area'>
          {showTable !== undefined && data ? (
            <div className='table-container-low'>{!descResponse.length ? <Loader /> : <Table columns={columns} data={descResponse} />}</div>
          ) : (
            <>
              <img src={UnmappedCup} alt='cup' />
              <p>Click one of the unmapped Item to view the nearest match. </p>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={9} className='footer-unmapped'>
          <Button
            variant='outline-secondary'
            onClick={() => {
              setSelectedExternalDesc('');
              setSelectedInternalDesc('');
              setSelectedFactType('');
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' disabled={selectedInternalDesc === '' ? true : false} onClick={onUpdate} className='submit-button'>
            Submit
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ValidatioFactDetailsLow;
