export const SideBarDropDownFact = [
  {
    attributeName: 'Long Description',
    external: 'Externaldesc',
    internal: 'Harmonizedname',
    apiEndPoint: 'internal-fact-description',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: 'Short Description',
    external: 'Short',
    internal: null,
    apiEndPoint: 'NA',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: 'Currency',
    external: 'Currency',
    internal: null,
    apiEndPoint: 'NA',
    dependsOn: [],
    apiDependsOn: [],
    value: null,
  },
  {
    attributeName: 'Precision',
    external: 'Precision',
    internal: null,
    apiEndPoint: 'NA',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: 'Denominator',
    external: 'Denominator',
    internal: null,
    apiEndPoint: 'NA',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: 'Unique TAG',
    external: 'Uniqueidentifier',
    internal: null,
    apiEndPoint: 'NA',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: 'Fact Type',
    external: 'NA',
    internal: 'Facttype',
    apiEndPoint: 'internal-fact-type',
    dependsOn: ['Harmonizedname'],
    apiDependsOn: [],
    value: null
  }
];
