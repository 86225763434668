import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React, { useState } from 'react';
import AppRoutes from './routes';
import { ProfileData } from 'shared/components/ProfileData';
// import { callMsGraph } from "./graph";
import './styles/_main.scss';
import SSOscreen from 'views/SSOscreen';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <>
      {isAuthenticated ? <AppRoutes /> : <SSOscreen authentication={(value:boolean)=>setIsAuthenticated(value)}/>}

      {/* <AuthenticatedTemplate>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
      </UnauthenticatedTemplate> */}
    </>
  );
};

export default App;
