import React, { FC, useEffect } from 'react';
import './unprocessRecords.scss';
import { Row, Col } from 'react-bootstrap';
import CustomButton from 'shared/components/Button-component';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import { searchUtil } from 'shared/utils/searchUtil';
import Loader from 'shared/components/Loader';
import Table from 'shared/components/Table-component/Table-component';
import EDPagination from 'shared/components/Pagination';
import ValidationMarketDimensionService from 'services/ValidationMarketDimensionService/validationMarketDimension.service';
import { useParams } from 'react-router-dom';
import columns from './columns';
import ValidationService from 'services/ValidationService/validation.service';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';

interface UnprocessRecordsProps {
  fileName?: string;
  recordsCount?: number;
}

const UnprocessRecords: FC<UnprocessRecordsProps> = (props) => {
  const { fileName, recordsCount } = props;
  const {
    paginationLoading,
    fetchUnProcessedRecordsPagination,
    fetchUnProcessedRecords,
    unprocessedRecords,
    loading,
    validationMarketDimensionsPagination: pagination
  } = ValidationMarketDimensionService();
  const { downloadExcelFile, buttonLoading } = ValidationService();

  const searchRecords = (searchString: string) => {
    id &&
      fetchUnProcessedRecords(id, {
        Filename:fileName,
        search: searchString
      });
  };

  const { id, confidenceLevel } = useParams();

  useEffect(() => {
    if (id && fileName) {
      fetchUnProcessedRecords(id,{Filename:fileName});
      fetchUnProcessedRecordsPagination(id,{Filename:fileName});
    }
  }, []);

  const handlePagination = (pageNumber: number, recordsPerPage?: number) => {
    const currentPageSize = pagination?.pageSize ?? 0;
    const limit = recordsPerPage ?? currentPageSize;
    const offset = (pageNumber - 1) * limit;
    if (id) {
      fetchUnProcessedRecords(id, {
        Filename:fileName,
        limit,
        offset
      });
     id && fetchUnProcessedRecordsPagination(id, {
        Filename:fileName,
        limit,
        offset
      });
    }
  };

  const handleDownload = () => {
    if (id && confidenceLevel && fileName)
      downloadExcelFile(ApiRoutes.DOWNLOAD_MARKET_EXCEL, id, {
        confidenceLevel,
        fileName
      });
  };

  return (
    <div className='unprocess-records'>
      <Row className='justify-content-between'>
        <Col sm={4}>
          <CustomButton disabled={!recordsCount} onClick={handleDownload}>
            {buttonLoading ? 'Downloading...' : 'Download File'}
          </CustomButton>
        </Col>
        <Col sm={4} className='search-container'>
          <SearchInputField
            onChange={searchUtil((e: React.ChangeEvent<HTMLInputElement>) => searchRecords(e?.target?.value))}
            placeholder='Search...'
            width='354px'
            height='48px'
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className='table-container table-container-scroll'>
            {loading ? <Loader /> : <Table columns={columns} data={unprocessedRecords} />}
          </div>
          {paginationLoading ? (
            <SkeletonLoader />
          ) : (
            pagination && (
              <EDPagination
                page={pagination?.page ?? 0}
                pageSize={pagination?.pageSize ?? 0}
                totalCount={pagination?.totalCount ?? 0}
                onPageItemClick={handlePagination}
              />
            )
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UnprocessRecords;
