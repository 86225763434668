import React, { useEffect } from 'react';
import { Container, Navbar, Row, Col } from 'react-bootstrap';
import './Nav-component.scss';
import Network from 'assets/icons/network.svg';
import NotificationBell from 'assets/icons/notification-bell.svg';
import ProfilePlaceholder from 'assets/icons/profilePicPlaceholder.svg';
import SearchInputField from '../Search-input-field/search-input-field';
import Dropdown from 'shared/components/dropdown-component';
import CustomButton from '../Button-component/Button';
import ProfileService from 'services/ProfileService/profile.service';

const NavComponent: React.FC = () => {
  const { getUserPhoto, userPhoto } = ProfileService();

  useEffect(() => {
    getUserPhoto();
  }, []);

  /* Comment for future use */
  // const languageDropdownOptions = [
  //   {
  //     value: 'english',
  //     label: 'English'
  //   },
  //   {
  //     value: 'arabic',
  //     label: 'Arabic'
  //   }
  // ];

  return (
    <Navbar expand='lg'>
      <Container fluid className='navbar-template'>
        <Row className='w-100'>
          <Col xs={3} className='navbar-search'>
            <div className='search'>
              <SearchInputField
                placeholder='Type here to search file, report...'
                height='40px'
                width='120px'
                paddingLeft='40px'
                paddingRight='50px'
              />
            </div>
          </Col>
          <Col sm={6} className='container-center'>
            {/* Comment for future use */}
            {/* <div className='zoom-controls-container'>
            <div>
            <CustomButton height="37px" buttonNav>A-</CustomButton>
            </div>
            <div>
            <CustomButton height="37px" buttonNav>A</CustomButton>
            </div>
            <div>
            <CustomButton height="37px" buttonNav>A+</CustomButton>
            </div>
            
            </div>
            <div className='nav-dropdown-container'>
            <Dropdown  height='36px' options={languageDropdownOptions} />
            </div>  
            <div className='screen-reader-access'>
              <div className='separator'></div>
              <p>Screen Reader Access</p>
              <div className='separator'></div>
            </div> */}
          </Col>
          <Col xs={2} className='container-right'>
            <div className='icon-container'>
              <div>
                <img src={Network} alt='networking' />
              </div>
              <div>
                <img src={NotificationBell} alt='notifications' />
              </div>
              <div className='profile-pic-wrapper'>
                <img src={userPhoto || ProfilePlaceholder} alt='profile' className='profile-pic' />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default NavComponent;
