
import {serializable, alias, object, list, primitive} from 'serializr';

export class VolatilityDashboard { 

	@serializable(alias('total_count', primitive()))
	totalCount?: number;

	@serializable(alias('excel_count', primitive()))
	excelCount?: number;

	@serializable(alias('csv_count', primitive()))
	csvCount?: number;

	@serializable(alias('doc_count', primitive()))
	docCount?: number;

}