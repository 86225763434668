/* eslint-disable */
import axios from 'axios';
import { ApiRoutes } from "../routes/routeConstants/apiRoutes";
import { loginRequest, msalInstance } from 'authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-common';

export const getHeaders = (): any => {
    let headers, user;
    if (localStorage.getItem('user')) {
        user = JSON.parse(localStorage.getItem('user') || '');
    }
    headers = {
        'Content-Type': 'application/json',
    };
    return headers;
};

export const addToken = async () => {
    try {
        const accountObj = msalInstance.getAllAccounts()[0];
        const tokenResponse = await msalInstance.acquireTokenSilent({account: accountObj, scopes: loginRequest.scopes});
        return tokenResponse.accessToken;
    } catch(error) {
        if (error instanceof InteractionRequiredAuthError) {
            return msalInstance.acquireTokenRedirect(loginRequest)
        }
    }   
}

const axiosInstance = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    timeout: 120000,
});

axiosInstance.interceptors.request.use(async function (config) {
    config.headers = {
        ...config.headers,
        ...getHeaders(),
        Authorization: `Bearer ${await addToken()}`,
    };

    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
    (response): any => {
        return {
            data: response.data,
            message: response.statusText,
            status: response.status,
            headers: response.headers
        }
    },
    (error) => {
        const { response } = error;

        switch (response.status) {
            case 401:
                // TODO: Need to show notification for Unauthorised
                break;

            case 422:
                // TODO: Need to show notification for Unhandled data
                break;

            case 500:
                // TODO: Need to show notification for Something went wrong
                break
            default:
            // TODO: Need to show notification for Something went wrong

        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
