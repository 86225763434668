import React, { FC } from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';
import DropdownComponent from '../dropdown-component';
import PageLink from './PageLink';
import './pagination.scss';

interface EDPaginationProps {
  page: number;
  totalCount: number;
  pageSize: number;
  onPageItemClick: (pageNumber: number, pageSize?: number) => void;
}

const EDPagination: FC<EDPaginationProps> = (props) => {
  const { page, totalCount, pageSize, onPageItemClick } = props;

  let active = page;
  let totalPages = Math.ceil(totalCount / pageSize);
  let items = [];

  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  const noop = () => {};

  // Determine the range of pages to display
  let start = Math.max(1, active - 1);
  let end = Math.min(totalPages, active + 2);
  if (start !== 1) {
    items.push(
      <Pagination.Item key={1} onClick={() => props.onPageItemClick(1, pageSize)}>
        {1}
      </Pagination.Item>
    );
    if (start > 2) {
      items.push(<Pagination.Ellipsis key='ellipsis-first' disabled />);
    }
  }

  for (let i = start; i <= end; i++) {
    items.push(
      <Pagination.Item key={i} active={i === active} onClick={() => props.onPageItemClick(i, pageSize)}>
        {i}
      </Pagination.Item>
    );
  }
  if (end !== totalPages) {
    if (end < totalPages - 1) {
      items.push(<Pagination.Ellipsis key='ellipsis-last' disabled />);
    }
    items.push(
      <Pagination.Item key={totalPages} onClick={() => props.onPageItemClick(totalPages, pageSize)}>
        {totalPages}
      </Pagination.Item>
    );
  }

  const recordsDropdown = [
    {
      label: '10',
      value: '10'
    },
    {
      label: '20',
      value: '20'
    },
    {
      label: '30',
      value: '30'
    },
    {
      label: '40',
      value: '40'
    }
  ];

  return (
    <div className='pagination w-100'>
      <Row className='w-100 align-items-center'>
        <Col xs={2}>
          <p className='records-label'>{totalCount} record(s) found</p>
        </Col>
        <Col xs={3}>
          <p className='records-label'>
            Rows per page
            <DropdownComponent
              className='ml-3'
              height='40px'
              options={recordsDropdown}
              value={pageSize.toString()}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onPageItemClick(page, parseInt(e.target.value))}
            />
          </p>
        </Col>
        <Col xs={7} className='pr-0 pl-0'>
          <Pagination className='float-right'>
            <PageLink
              onPageItemClick={isFirstPage ? noop : () => onPageItemClick(active - 1, pageSize)}
              disabled={isFirstPage}
              type='prev'
              redirectPageNumber={active - 1}
            />
            {items}
            <PageLink
              onPageItemClick={isLastPage ? noop : () => onPageItemClick(active + 1, pageSize)}
              disabled={isLastPage}
              type='next'
              redirectPageNumber={active + 1}
            />
          </Pagination>
        </Col>
      </Row>
    </div>
  );
};

export default EDPagination;
