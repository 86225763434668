import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from '../../routes/routeConstants/apiRoutes';
import { ValidationDashboard } from 'models/ValidationDashboard/validationDashboard.model';
import { SmartMappingList } from 'models/SmartMappingList/smartMappingList.model';
import { Pagination } from 'models/Pagination/pagination.model';
import { generatePath } from 'react-router';
import smartMappingMappedResult from 'dummy/smartMappingMappedResult';
import { SmartMappingMappedResult } from 'models/SmartMappingMappedResult/smartMappingMappedResult.model';
import { ValidationFilters } from 'views/ValidationFlow/ProductDimension/ValidationDashboard/ValidationDashboard';
import deepClone from 'shared/utils/deepClone';
import { LowSuggestion } from 'models/LowSuggestion/lowSuggestion.model';
import { ProductUnProcessedRecord } from 'models/ProductUnProcessedRecord/productUnProcessedRecord.model';
import { ResponseTypes } from 'enums/responseTypes';

const ValidationService = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [validationDashboardDetails, setValidationDashboardDetails] = useState<ValidationDashboard>();

  const [pageSize, setPageSize] = useState<number>(10);

  const [smartMappingList, setMartMappingList] = useState<SmartMappingList[]>([]);

  const [smartMapping, setMartMapping] = useState<SmartMappingList>();

  const [mappedData, setMappedData] = useState<SmartMappingMappedResult[]>([]);

  const [pagination, setPagination] = useState<Pagination>();

  const [toggleAllStatus, setToggleAllStatus] = useState<boolean>(false);

  const [nearestMatchCount, setNearestMatchCount] = useState<number>(0);

  const [unprocessedRecords, setUnprocessedRecords] = useState<ProductUnProcessedRecord[]>([]);

  const fetchValidationDashboard = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.VALIDATION_DASHBOARD);
      const data = deserialize(ValidationDashboard, response.data['data']) as ValidationDashboard;
      setValidationDashboardDetails(data);
    } catch (error) {
      setValidationDashboardDetails(undefined);
    } finally {
      setLoading(false);
    }
  };

  const fetchSmartMappingList = async (params?: ValidationFilters, limit: number = 10, offset: number = 0) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.SMART_MAPPING_LIST, {
        params: {
          ...params,
          limit,
          offset
        }
      });
      const data = deserialize(SmartMappingList, response.data['result']) as SmartMappingList[];
      setMartMappingList(data);
    } catch (error) {
      console.log('Error is ', error);
      setValidationDashboardDetails(undefined);
    } finally {
      setLoading(false);
    }
  };
  const fetchSmartMappingListPagination = async (params?: ValidationFilters, limit: number = 10, offset: number = 0) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setPaginationLoading(true);
      const response = await axiosInstance.get(ApiRoutes.SMART_MAPPING_LIST_COUNT, {
        params: {
          ...params,
          limit,
          offset
        }
      });
      const pagination = deserialize(Pagination, response.data) as Pagination;
      setPagination(pagination);
    } catch (error) {
      console.log('Error is ', error);
      setPagination(undefined);
    } finally {
      setPaginationLoading(false);
    }
  };

  const fetchIndividualSmartMapping = async (id: string) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const url = generatePath(ApiRoutes.INDIVIDUAL_SMART_MAPPING, { id });
      const response = await axiosInstance.get(url);
      const data = deserialize(SmartMappingList, response.data) as SmartMappingList;
      setMartMapping(data);
    } catch (error) {
      console.log('Error is ', error);
      setMartMapping(undefined);
    } finally {
      setLoading(false);
    }
  };

  const fetchMappedData = async (
    id: string,
    key: string,
    params?: {
      limit?: number;
      offset?: number;
      search?: string;
    }
  ) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const url = generatePath(ApiRoutes.SMART_MAPPING_MAPPED, { id, key });
      const response = await axiosInstance.get(url, { params });
      const data = deserialize(SmartMappingMappedResult, response.data['result']) as SmartMappingMappedResult[];
      setMappedData(deepClone(data));
    } catch (error) {
      const response = smartMappingMappedResult;
      const data = deserialize(SmartMappingMappedResult, response['result']) as SmartMappingMappedResult[];
      setMappedData(data);
      console.log('Error is ', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMappedDataPagination = async (
    id: string,
    key: string,
    params?: {
      limit?: number;
      offset?: number;
      search?: string;
    }
  ) => {
    try {
      setPaginationLoading(true);
      const url = generatePath(ApiRoutes.SMART_MAPPING_MAPPED_COUNT, { id, key });
      const response = await axiosInstance.get(url, { params });
      const pagination = deserialize(Pagination, response.data) as Pagination;
      setPagination(pagination);
    } catch (error) {
      const response = smartMappingMappedResult;
      const pagination = deserialize(Pagination, response) as Pagination;
      setPagination(pagination);
      console.log('Error is ', error);
    } finally {
      setPaginationLoading(false);
    }
  };

  const fetchUnMappedData = async (
    id: string,
    params?: {
      limit?: number;
      offset?: number;
      search?: string;
    }
  ) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const url = generatePath(ApiRoutes.SMART_MAPPING_UN_MAPPED, { id });
      const response = await axiosInstance.get(url, { params });
      const data = deserialize(SmartMappingMappedResult, response.data['result']) as SmartMappingMappedResult[];
      const pagination = deserialize(Pagination, response.data) as Pagination;
      setPagination(pagination);
      setMappedData(data);
    } catch (error) {
      console.log('Error is ', error);
      setMappedData([]);
    } finally {
      setLoading(false);
    }
  };

  const toggleMappedCheckStatus = (id: string | undefined) => {
    const updatedData = mappedData.map((item) => {
      if (item.Id === id) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });
    setToggleAllStatus(false);
    setMappedData(updatedData);
  };

  const toggleAllCheckbox = () => {
    const updatedData = mappedData.map((item) => {
      item.isSelected = !toggleAllStatus;
      return item;
    });
    setToggleAllStatus(!toggleAllStatus);
    setMappedData(updatedData);
  };

  const toggleUnMappedCheckStatus = async (id: string | undefined, recordIndex: number | undefined) => {
    if (!id) return;
    if (recordIndex === undefined) return;
    const updatedData = mappedData[recordIndex].lowRecords?.map((item) => {
      if (item.id?.toString() === id.toString()) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
      return item;
    });
    mappedData[recordIndex].lowRecords = updatedData;
    mappedData[recordIndex].searchResult = updatedData;
    mappedData[recordIndex].isChecked = true;
    setToggleAllStatus(false);
    setMappedData(deepClone(mappedData));
  };

  const fetchLowRecordSuggestion = async (id: string) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    const mappedIndex = mappedData.findIndex((item) => item.Id?.toString() === id);
    const lowRecords = mappedData[mappedIndex].lowRecords;
    if (lowRecords !== undefined && lowRecords.length > 0) {
      setNearestMatchCount(lowRecords.length);
      return;
    }

    const url = generatePath(ApiRoutes.SMART_MAPPING_LOW_RECORD_SUGGESTIONS, { id });
    const response = await axiosInstance.get(url);
    const data = deserialize(LowSuggestion, response.data) as LowSuggestion[];

    setNearestMatchCount(data.length);
    mappedData[mappedIndex].lowRecords = data;
    mappedData[mappedIndex].searchResult = data;
    setMappedData(deepClone(mappedData));
  };

  const resetCheckStatus = () => {
    const updatedData = mappedData.map((item) => {
      item.isSelected = false;
      return item;
    });
    setMappedData(updatedData);
    setToggleAllStatus(false);
  };

  const submitUnMappedResult = async (id: string) => {
    let mappingDetails: any = [];
    mappedData.forEach((mappingRecord) => {
      if (mappingRecord.lowRecords && mappingRecord.lowRecords?.length > 0) {
        const selectedRecordIndex: number = mappingRecord.lowRecords?.findIndex((item) => item.isChecked);
        if (selectedRecordIndex !== -1) {
          mappingDetails.push({
            source: mappingRecord.Id?.toString(),
            target: mappingRecord.lowRecords[selectedRecordIndex].id?.toString()
          });
        }
      }
    });

    const url = generatePath(ApiRoutes.INDIVIDUAL_SMART_MAPPING, { id });
    await axiosInstance.put(url, {
      mapping: mappingDetails
    });

    setNearestMatchCount(0);
  };

  const searchSuggestions = (activeIndex: number | undefined, searchString: string) => {
    if (activeIndex === undefined) return;
    const filteredResults = mappedData[activeIndex].lowRecords?.filter((item) =>
      item.Internaldesc?.toLowerCase()?.includes(searchString.toLowerCase())
    );
    mappedData[activeIndex].searchResult = filteredResults;
    setMappedData(deepClone(mappedData));
  };

  const resetSearchSuggestion = (activeIndex: number | undefined) => {
    if (activeIndex === undefined) return;
    mappedData[activeIndex].searchResult = mappedData[activeIndex].lowRecords;
    setMappedData(deepClone(mappedData));
  };

  const fetchUnProcessedRecords = async (
    id: string,
    params?: {
      search?: string;
      limit?: number;
      offset?: number;
    }
  ) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const url = generatePath(ApiRoutes.SMART_MAPPING_UN_PROCESSED_RECORDS, { id });
      const response = await axiosInstance.get(url, { params });
      const data = deserialize(ProductUnProcessedRecord, response.data['result']) as ProductUnProcessedRecord[];
      setUnprocessedRecords(data);
    } catch (error) {
      setUnprocessedRecords([]);
    } finally {
      setLoading(false);
    }
  };
  const fetchUnProcessedRecordsPagination = async (
    id: string,
    params?: {
      search?: string;
      limit?: number;
      offset?: number;
    }
  ) => {
    try {
      setPaginationLoading(true);
      const url = generatePath(ApiRoutes.SMART_MAPPING_UN_PROCESSED_RECORDS_COUNT, { id });
      const response = await axiosInstance.get(url, { params });
      const pagination = deserialize(Pagination, response.data);
      setPagination(pagination);
    } catch (error) {
      setPagination(undefined);
    } finally {
      setPaginationLoading(false);
    }
  };

  const downloadUnProcessedRecords = async (id: string) => {
    try {
      setButtonLoading(true);
      const url = generatePath(ApiRoutes.SMART_MAPPING_UN_PROCESSED_RECORDS_DOWNLOAD, { id });
      const response = await axiosInstance.get(url, {
        responseType: 'blob'
      });
      const contentDisposition = response.headers['content-disposition'];
      const matchName = contentDisposition.replace('attachment; filename=', '');
      const filename = matchName.split('.')[0] + '.xlsx';

      const urlLink = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = urlLink;
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('Error is ', error);
    } finally {
      setButtonLoading(false);
    }
  };

  const downloadExcelFile = async (
    url: string,
    id: string,
    params: {
      confidenceLevel: string;
      fileName: string;
    }
  ) => {
    try {
      setButtonLoading(true);
      const API_URL = generatePath(url, { id });
      const response = await axiosInstance.get(API_URL, {
        responseType: ResponseTypes.BLOB,
        params
      });
      const contentDisposition = response.headers['content-disposition'];
      const matchName = contentDisposition.replace('attachment; filename=', '');
      const filename = matchName.split('.')[0] + '.xlsx';

      const urlLink = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = urlLink;
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('Error is ', error);
    } finally {
      setButtonLoading(false);
    }
  };

  return {
    loading,
    buttonLoading,
    validationDashboardDetails,
    smartMappingList,
    pageSize,
    pagination,
    mappedData,
    toggleAllStatus,
    smartMapping,
    nearestMatchCount,
    unprocessedRecords,
    toggleAllCheckbox,
    setPageSize,
    fetchUnMappedData,
    fetchValidationDashboard,
    fetchSmartMappingList,
    fetchMappedData,
    toggleMappedCheckStatus,
    fetchIndividualSmartMapping,
    toggleUnMappedCheckStatus,
    submitUnMappedResult,
    resetCheckStatus,
    fetchLowRecordSuggestion,
    searchSuggestions,
    resetSearchSuggestion,
    fetchUnProcessedRecords,
    downloadUnProcessedRecords,
    fetchSmartMappingListPagination,
    paginationLoading,
    fetchMappedDataPagination,
    fetchUnProcessedRecordsPagination,
    downloadExcelFile
  };
};

export default ValidationService;
