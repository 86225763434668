import axiosInstance from 'interceptor/axiosInstance';
import { useState } from 'react';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { deserialize } from 'serializr';
import { ValidationFactFilters } from 'views/Validations/ValidationFactSummary/ValidationFactSummary';
import { Pagination } from 'models/Pagination/pagination.model';
import { SmartMappingList } from 'models/SmartMappingList/smartMappingList.model';
import { ValidationDashboard } from 'models/ValidationDashboard/validationDashboard.model';
import { SmartMappingMappedResult } from 'views/Validations/FactModel/smartMappingFactModel';
import smartMappingMappedResult from 'dummy/smartMappingMappedResult';
import { generatePath } from 'react-router-dom';
import { FactUnProcessedRecord } from 'models/FactUnProcessedRecord/FactUnProcessedRecord.model';
import { ResponseTypes } from 'enums/responseTypes';

const ValidationFactService = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>();
  const [validationDashboardDetails, setValidationDashboardDetails] = useState<ValidationDashboard>();
  const [factRecords, setfactRecords] = useState<SmartMappingList[]>([]);
  const [toggleAllStatus, setToggleAllStatus] = useState<boolean>(false);
  const [mappedData, setMappedData] = useState<SmartMappingMappedResult[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [factDetailRes, setFactDetailRes] = useState();
  const [unprocessed,setUnprocessed] =useState<FactUnProcessedRecord[]>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [unprocessedPagination,setUnprocessedPagination]=useState<Pagination>();

  const fetchFactRecords = async (params?: ValidationFactFilters, limit: number = 10, offset: number = 0) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.VALIDATION_FACT, {
        params: {
          ...params,
          limit,
          offset
        }
      });
      const data = deserialize(SmartMappingList, response.data['result']) as SmartMappingList[];
      setfactRecords(data);
    } catch (error) {
      console.log('Error is ', error);
      setValidationDashboardDetails(undefined);
    } finally {
      setLoading(false);
    }
  };

  const fetchFactRecordsPagination = async (params?: ValidationFactFilters, limit: number = 10, offset: number = 0) => {
    try {
      setPaginationLoading(true);
      const response = await axiosInstance.get(ApiRoutes.VALIDATION_FACT_COUNT, {
        params: {
          ...params,
          limit,
          offset
        }
      });
      const pagination = deserialize(Pagination, response.data) as Pagination;
      setPagination(pagination);
    } catch (error) {
      console.log('Error is ', error);
      setPagination(undefined);
    } finally {
      setPaginationLoading(false);
    }
  };

  const fetchMappedData = async (params?: {
    limit?: number;
    offset?: number;
    search?: string;
    Filename?: string | null;
    confidenceLevel?: string;
  }) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.VALIDATION_FACT_DETAIL, { params });
      const data = response.data.result;
      setMappedData(data);
    } catch (error) {
      const response = smartMappingMappedResult;
      const data = deserialize(SmartMappingMappedResult, response['result']) as SmartMappingMappedResult[];
      setMappedData(data);
      console.log('Error is ', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchMappedDataPagination = async (params?: {
    limit?: number;
    offset?: number;
    search?: string;
    Filename?: string | null;
    confidenceLevel?: string;
  }) => {
    try {
      setPaginationLoading(true);
      const response = await axiosInstance.get(ApiRoutes.VALIDATION_FACT_DETAIL_COUNT, { params });
      const pagination = deserialize(Pagination, response.data) as Pagination;
      setPagination(pagination);
    } catch (error) {
      const response = smartMappingMappedResult;
      const pagination = deserialize(Pagination, response) as Pagination;
      setPagination(pagination);
      console.log('Error is ', error);
    } finally {
      setPaginationLoading(false);
    }
  };

  const fetchUnMappedData = async (fileName: string, search: string) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const url = ApiRoutes.BASE_URL;
      const response = await fetch(`${url}${ApiRoutes.VALIDATION_FACT_DETAIL}?FileName=${fileName}&search=${search}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.log('Error is ', error);
      setMappedData([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchFactDetails = async (fileName: string, confidenceLevel: string) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const detailsRes = await axiosInstance.get(
        `${ApiRoutes.VALIDATION_FACT_DETAIL}?FileName=${fileName}&Confidencelevel=${confidenceLevel}`
      );

      return detailsRes;
    } catch (error) {
      console.log('Error is', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleMappedCheckStatus = (id: string | undefined) => {
    const updatedData = mappedData.map((item) => {
      if (item.id === id) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });
    setToggleAllStatus(false);
    setMappedData(updatedData);
  };

  const toggleAllCheckbox = () => {
    const updatedData = mappedData.map((item) => {
      item.isSelected = !toggleAllStatus;
      return item;
    });
    setToggleAllStatus(!toggleAllStatus);
    setMappedData(updatedData);
  };

  const toggleUnMappedCheckStatus = (id: any) => {
    mappedData.map((item: any) => {
      if (item.Id === id) {
        item.isSelected = !item.isSelected;
      }
    });
  };

  const resetCheckStatus = () => {
    const updatedData = mappedData.map((item) => {
      item.isSelected = false;
      return item;
    });
    setMappedData(updatedData);
    setToggleAllStatus(false);
  };

  const onSubmit = async (fileName: any, Externaldesc: string, Internaldesc: string, FactType: string) => {
    if (fileName === '' || Externaldesc === '' || Internaldesc === '' || FactType === '') return;

    const details = await axiosInstance.put(
      ApiRoutes.VALIDATION_FACT_DETAIL_UPDATE,
      {
        Externaldesc,
        Internaldesc,
        FactType
      },
      {
        params: {
          FileName: fileName
        }
      }
    );
    setFactDetailRes(details as any);
  };


  const fetchUnprocessedRecords=async (
    params?: {
      limit?: number;
    offset?: number;
    // search?: string;
    Filename?: string | null;
    confidenceLevel?: string;
    }
  ) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const url = generatePath(ApiRoutes.SMART_MAPPING_UN_PROCESSED_FACT);
      const response = await axiosInstance.get(url, { params });
      const data = deserialize(FactUnProcessedRecord, response.data['result']) as FactUnProcessedRecord[];
      setUnprocessed(data);
    } catch (error) {
      setUnprocessed([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchUnProcessedRecordsPagination = async (
    params?: {
      Filename?:string|null;
      search?: string;
      limit?: number;
      offset?: number;
    }
  ) => {
    try {
      setPaginationLoading(true);
      const url = generatePath(ApiRoutes.  SMART_MAPPING_UN_PROCESSED_FACT_COUNT );
      const response = await axiosInstance.get(url, { params });
      const pagination = deserialize(Pagination, response.data);
      setUnprocessedPagination(pagination);
    } catch (error) {
      setUnprocessedPagination(undefined);
    } finally {
      setPaginationLoading(false);
    }
  };

  const downloadExcelFile = async (
    url: string,
    id: string,
    params: {
      confidenceLevel: string;
      fileName: string;
    }
  ) => {
    try {
      setButtonLoading(true);
      const API_URL = generatePath(url, { id });
      const response = await axiosInstance.get(API_URL, {
        responseType: ResponseTypes.BLOB,
        params
      });
      const contentDisposition = response.headers['content-disposition'];
      const matchName = contentDisposition.replace('attachment; filename=', '');
      const filename = matchName.split('.')[0] + '.xlsx';

      const urlLink = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = urlLink;
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('Error is ', error);
    } finally {
      setButtonLoading(false);
    }
  };

  return {
    factRecords,
    loading,
    pagination,
    validationDashboardDetails,
    fetchFactRecords,
    fetchMappedData,
    fetchFactDetails,
    toggleAllCheckbox,
    toggleUnMappedCheckStatus,
    resetCheckStatus,
    toggleMappedCheckStatus,
    onSubmit,
    fetchUnMappedData,
    mappedData,
    pageSize,
    factDetailRes,
    fetchFactRecordsPagination,
    paginationLoading,
    fetchMappedDataPagination,
    unprocessed,
    fetchUnprocessedRecords,
    buttonLoading,
    downloadExcelFile,
    fetchUnProcessedRecordsPagination,
    unprocessedPagination
  };
};

export default ValidationFactService;
