import { Dropdown } from "react-bootstrap"

export const AppRoutes = {
  ROOT: '/',
  CHECKS: 'checks',
  ROLE_SELECTION: 'role-selection',
  VALIDATION_RECORDS: 'validation-records',
  VALIDATION_RECORD_DETAILS:'validation-record-details/:id/:confidenceLevel',
  FILE_VOLATALITY_RECORDS: 'file-volatility',
  FILE_VOLATILITY_HOME: 'reports',
  FILE_VOLATILITY_MAPPED: 'mapping/:id',
  FILE_VOLATILITY_MARKET_MAPPED: 'mapping/:id/market',
  OPERATION_GRAPH:'/home',
  LEADERSHIP_GRAPH:'/leadership',
  SSO_TEST: '/test',

  // fact routes
  VALIDATION:'validations',
  VALIDATION_FACT:'fact',
  VALIDATION_FACT_DETAILS:'fact/:id/:confidenceLevel',  
  FILE_VOLATILITY_FACT_MAPPED:'mapping/:id/fact',
  FILE_VOLATILITY_PERIOD_MAPPED:'mapping/:id/period',

  // Period Dimensions routes
  VALIDATION_PERIOD:'period',
  VALIDATION_PERIOD_DETAILS:'period/:id/:confidenceLevel', 

  VALIDATION_MARKET:'market',
  VALIDATION_MARKET_DETAILS:'market/:id/:confidenceLevel',  

  DQ_CHECK: 'dq-checks',
}
