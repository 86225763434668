import React, { useEffect, useState } from 'react';
import './LoadStatistics.scss';
import { Container, Row, Col } from 'react-bootstrap';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import Table from 'shared/components/Table-component/Table-component';
import CustomButton from 'shared/components/Button-component/Button';
import DownloadArrow from 'assets/icons/download.svg';
import ArrowIcon from 'assets/icons/CardArrowVector.svg';
import MenuStack from 'assets/icons/menu-stack.svg';
import GraphView from 'assets/icons/graph-view-btn.svg';
import SortIcon from 'assets/icons/up-down-icon.svg';
import EDPagination from 'shared/components/Pagination';
import Loader from 'shared/components/Loader';
import VolatilityService from 'services/VolatilityService/volatility.service';
import { useNavigate } from 'react-router';
import { searchUtil } from 'shared/utils/searchUtil';
import ToastComponent from 'shared/components/Toast-component/Toast-component';
import moment from 'moment';
import NewEDDropdown from 'shared/components/NewEDDropdown';
import { FileVolatilityStatus } from 'enums/fileVolatilityStatus';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import MetaService from 'services/MetaService/meta.service';
import { MetaData } from 'models/MetaData/metaData.model';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import { CountryMeta } from 'models/CountryMeta/countryMeta.model';
import EDDatePicker from 'shared/components/EDDatePicker';
import { displayDateTimeFormat } from 'constants/formats';

export interface VolatilityFilters {
  search: string;
  filter_by_provider: string;
  filter_by_category: string;
  filter_by_country: string;
  filter_by_success: boolean;
  filter_by_fail: boolean;
  order_by_id: string;
  order_by_provider: string;
  filter_by_in_progress: boolean;
  start_date: string;
  end_date: string;
}

const LoadStatistics = () => {
  const navigate = useNavigate();

  const {
    loading,
    VolatilityListData,
    fetchVolatilityList,
    pagination,
    pageSize,
    toggleMappedCheckStatus,
    toggleAllCheckbox,
    toggleAllStatus,
    resetAllCheckbox,
    paginationLoading,
    fetchVolatilityListPagination
  } = VolatilityService();

  const { loading: metaLoading, fetchMeta } = MetaService();

  const [showSortBy, setShowSortBy] = useState(false);

  const [filters, setFilters] = useState<VolatilityFilters>({
    search: '',
    filter_by_provider: '',
    filter_by_category: '',
    filter_by_country: '',
    filter_by_success: false,
    filter_by_fail: false,
    filter_by_in_progress: false,
    order_by_id: '',
    order_by_provider: '',
    start_date: '',
    end_date: ''
  });

  const [countryMeta, setCountryMeta] = useState<CountryMeta[]>([]);

  const [providerMeta, setProviderMeta] = useState<MetaData[]>([]);

  const [categoryMeta, setCategoryMeta] = useState<MetaData[]>([]);

  const [showA, setShowA] = useState(false);

  const [changedFilter, setChangedFilter] = useState<keyof VolatilityFilters>();

  useEffect(() => {
    handleFetchMeta();
  }, []);

  useEffect(() => {
    if (filters) {
      fetchVolatilityList(filters);
      fetchVolatilityListPagination(filters);
    }
  }, [filters]);

  const handleShowSortBy = () => {
    setShowSortBy(!showSortBy);
  };

  const sortItems = (orderName: keyof VolatilityFilters, value: 'asc' | 'desc') => {
    handleShowSortBy();
    updateDataOrdering(orderName, value);
  };

  const toggleShowA = () => setShowA(!showA);

  const handleCellNavigation = (cell: any) => {
    navigate(`mapping/${cell?.row?.original.id}?FileName=${cell?.row?.values?.FILENAME}`, { state: cell?.row?.original.PIPELINESTATUS });
  };

  const handlePagination = (pageNumber: number, recordsPerPage?: number) => {
    const limit = recordsPerPage ?? pageSize;
    const offset = (pageNumber - 1) * limit;
    fetchVolatilityList(filters, limit, offset);
    fetchVolatilityListPagination(filters, limit, offset);
  };

  const handleFetchMeta = () => {
    fetchMeta(ApiRoutes.COUNTRY_META, setCountryMeta, MetaData);
    fetchMeta(ApiRoutes.CATEGORY_META, setCategoryMeta, MetaData);
    fetchMeta(ApiRoutes.PROVIDER_META, setProviderMeta, MetaData);
  };

  const updateFilters = (filterName: keyof VolatilityFilters, value: string | boolean) => {
    setFilters({
      ...filters,
      [filterName]: value
    });

    switch (filterName) {
      case 'filter_by_category':
        setChangedFilter(filterName);
        fetchMeta(ApiRoutes.COUNTRY_META, setCountryMeta, MetaData, { category: String(value) });
        break;
      case 'filter_by_country':
        setChangedFilter(filterName);
        fetchMeta(ApiRoutes.CATEGORY_META, setCategoryMeta, MetaData, { country: String(value) });
        break;
    }
  };

  const updateDateFilter = (startDate: string, endDate: string) => {
    setFilters({
      ...filters,
      start_date: startDate,
      end_date: endDate
    });
  };

  const updateDataOrdering = (orderName: keyof VolatilityFilters, value: 'asc' | 'desc') => {
    setFilters({
      ...filters,
      order_by_id: '',
      order_by_provider: '',
      [orderName]: value
    });
  };

  const columns: any = [
    {
      id: 'checkboxes',
      accessor: 'isSelected',
      Cell: ({ value, row }: any) => {
        return <input type='checkbox' className='checkbox' checked={value} onChange={() => toggleMappedCheckStatus(row?.original?.id)} />;
      },
      Header: () => {
        return (
          <input type='checkbox' className='checkbox header-checkbox' checked={toggleAllStatus} onChange={() => toggleAllCheckbox()} />
        );
      },
      minWidth:80
    },
    {
      Header: 'Data Provider',
      accessor: 'SOURCE',
      minWidth: 150,
      Cell: (cell: any) => (
        <div onClick={() => handleCellNavigation(cell)} onKeyDown={() => handleCellNavigation(cell)} role='link' tabIndex={0}>
          {cell.value}
        </div>
      )
    },
    {
      Header: 'Country',
      accessor: 'COUNTRY',
      minWidth: 150,
      Cell: (cell: any) => (
        <div onClick={() => handleCellNavigation(cell)} onKeyDown={() => handleCellNavigation(cell)} role='link' tabIndex={0}>
          {cell.value}
        </div>
      )
    },
    {
      Header: 'Category',
      accessor: 'CATEGORY',
      minWidth: 150,
      Cell: (cell: any) => (
        <div onClick={() => handleCellNavigation(cell)} onKeyDown={() => handleCellNavigation(cell)} role='link' tabIndex={0}>
          {cell.value}
        </div>
      )
    },
    {
      Header: 'Filename',
      accessor: 'FILENAME',
      Cell: (cell: any) => (
        <div
          onClick={() => handleCellNavigation(cell)}
          onKeyDown={() => handleCellNavigation(cell)}
          role='link'
          tabIndex={0}
          className='cell-hyperlink'
        >
          {cell.value}
        </div>
      ),
      minWidth: 350
    },
    {
      Header: 'Loaded On',
      accessor: 'LOADSTARTTIME',
      Cell: ({ cell }: any) => (
        <div onClick={() => handleCellNavigation(cell)} onKeyDown={() => handleCellNavigation(cell)} role='link' tabIndex={0}>
          {moment.utc(cell.value).format(displayDateTimeFormat)}
        </div>
      ),
      minWdth: 120
    },
    {
      Header: 'Status',
      accessor: 'PIPELINESTATUS',
      minWidth: 100,
      Cell: (cell: any) => (
        <div onClick={() => handleCellNavigation(cell)} onKeyDown={() => handleCellNavigation(cell)} role='link' tabIndex={0}>
          {cell.value === FileVolatilityStatus?.SUCCESS ? (
            <div className='success-tag'>{cell.value}</div>
          ) : cell.value === FileVolatilityStatus?.FAILURE ? (
            <div className='failed-tag'>{cell.value}</div>
          ) : cell.value === FileVolatilityStatus?.IN_PROGRESS ? (
            <div className='progress-tag'>{cell.value}</div>
          ) : (
            <div>{cell.value}</div>
          )}
        </div>
      )
    },
    {
      Header: '',
      id: 'arrow',
      Cell: (cell: any) => (
        <div onClick={() => handleCellNavigation(cell)} onKeyDown={() => handleCellNavigation(cell)} role='link' tabIndex={0} >
          <img src={ArrowIcon} alt='arrow' />
        </div>
      ),
     width:80
    }
  ];

  if (!VolatilityListData) return <Loader />;

  const isSampleButtonEnable = VolatilityListData?.some((item) => item.isSelected === true);

  const selectedDataCount = VolatilityListData?.filter((item) => item.isSelected === true).length;

  return (
    <Container fluid className='container-mapped'>
      <ToastComponent showState={showA} toggleShow={toggleShowA} message='Sample downloaded successfully' />
      <Row>
        <div className='load-filters-container'>
          <div className='filter-container filter-container-same-line'>
            <NewEDDropdown
              optionItems={providerMeta ?? []}
              value={filters.filter_by_provider}
              currentItemIndex={2}
              resetFunc={() => updateFilters('filter_by_provider', '')}
              labelText='Data Provider'
              defaultValue='All(22)'
              updateValue={(value) => updateFilters('filter_by_provider', value)}
              loading={!changedFilter && metaLoading}
            />
          </div>
          <div className='filter-container filter-container-same-line'>
            <NewEDDropdown
              loading={changedFilter ? changedFilter === 'filter_by_category' && metaLoading : metaLoading}
              optionItems={countryMeta ?? []}
              value={filters.filter_by_country}
              currentItemIndex={2}
              labelText='Country'
              resetFunc={() => updateFilters('filter_by_country', '')}
              defaultValue='All(22)'
              updateValue={(value) => updateFilters('filter_by_country', value)}
            />
          </div>
          <div className='filter-container filter-container-same-line'>
            <NewEDDropdown
              optionItems={categoryMeta ?? []}
              value={filters.filter_by_category}
              currentItemIndex={2}
              labelText='Category'
              resetFunc={() => updateFilters('filter_by_category', '')}
              defaultValue='All(22)'
              updateValue={(value) => updateFilters('filter_by_category', value)}
              loading={changedFilter ? changedFilter === 'filter_by_country' && metaLoading : metaLoading}
            />
          </div>
          <div className='filter-container filter-container-same-line'>
            <EDDatePicker onDateSelected={(startDate, endDate) => updateDateFilter(startDate, endDate)} />
          </div>
          <div className='filter-container-same-line input-search-container'>
            <SearchInputField
              onChange={searchUtil((e: React.ChangeEvent<HTMLInputElement>) => updateFilters('search', e?.target?.value))}
              placeholder='Search...'
              width='auto'
              height='44px'
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className='change-view-btns-container'>
          <div className='sort-container'>
            <div
              className='sort-by-button'
              onClick={() => handleShowSortBy()}
              onKeyDown={() => handleShowSortBy()}
              role='button'
              tabIndex={0}
            >
              <h6 className='sort-by-button-txt'>
                Sort By: <img src={SortIcon} alt='sortIcon' />
              </h6>
            </div>
            {showSortBy && (
              <div className='sortby-container'>
                <div
                  role='button'
                  onClick={() => sortItems('order_by_provider', 'asc')}
                  onKeyDown={() => sortItems('order_by_provider', 'asc')}
                  tabIndex={0}
                  className='sort-item'
                >
                  <p>Data Provider(A-Z)</p>
                </div>
                <div
                  onClick={() => sortItems('order_by_provider', 'desc')}
                  onKeyDown={() => sortItems('order_by_provider', 'asc')}
                  tabIndex={0}
                  role='button'
                  className='sort-item'
                >
                  <p>Data Provider(Z-A)</p>
                </div>
                <div
                  onClick={() => sortItems('order_by_id', 'desc')}
                  onKeyDown={() => sortItems('order_by_provider', 'asc')}
                  tabIndex={0}
                  role='button'
                  className='sort-item'
                >
                  <p>Latest File First</p>
                </div>
              </div>
            )}
          </div>
          <p className='view-information-as-txt'>View Information as: </p>
          <CustomButton primary>
            <img className='view-information-as-icon' src={MenuStack} alt='table-icon' />
          </CustomButton>
          <CustomButton secondary>
            <img className='view-information-as-icon' src={GraphView} alt='table-icon' />
          </CustomButton>
        </div>
      </Row>
      <Row className='table-top-container'>
        <Col sm={4} className='left-container'>
          <div className=''>
            {isSampleButtonEnable ? (
              <CustomButton
                primary
                onClick={() => {
                  toggleShowA();
                  resetAllCheckbox();
                }}
              >
                <div className=''>
                  Sample
                  <img style={{ marginLeft: '8px' }} src={DownloadArrow} alt='down-arrow' />
                </div>
              </CustomButton>
            ) : (
              <CustomButton disabled>
                <div className=''>
                  Sample
                  <img style={{ marginLeft: '8Px' }} src={DownloadArrow} alt='down-arrow' />
                </div>
              </CustomButton>
            )}
            {selectedDataCount !== 0 ? <p>(Selected Record: {selectedDataCount})</p> : null}
          </div>
        </Col>

        <Col sm={8} className='right-container'>
          <p>Total Records: {pagination?.totalCount}</p>
          <div className='separator'></div>
          <div className='success-btn-container small-button'>
            <CustomButton
              height='34px'
              secondary={!filters.filter_by_in_progress}
              SecondaryPressed={filters.filter_by_in_progress}
              onClick={() => updateFilters('filter_by_in_progress', !filters.filter_by_in_progress)}
            >
              {FileVolatilityStatus.IN_PROGRESS}
            </CustomButton>
            <CustomButton
              height='34px'
              secondary={!filters.filter_by_success}
              SecondaryPressed={filters.filter_by_success}
              onClick={() => updateFilters('filter_by_success', !filters.filter_by_success)}
            >
              {FileVolatilityStatus.SUCCESS}
            </CustomButton>
            <CustomButton
              height='34px'
              secondary={!filters.filter_by_fail}
              SecondaryPressed={filters.filter_by_fail}
              onClick={() => updateFilters('filter_by_fail', !filters.filter_by_fail)}
            >
              {FileVolatilityStatus.FAILURE}
            </CustomButton>
          </div>
        </Col>
      </Row>
      <div className='pagination-container'>
        <div className='table-container'>{loading ? <Loader /> : <Table columns={columns} data={VolatilityListData} />}</div>
        {paginationLoading ? (
          <SkeletonLoader />
        ) : (
          pagination && (
            <EDPagination
              page={pagination?.page ?? 0}
              pageSize={pagination?.pageSize ?? 0}
              totalCount={pagination?.totalCount ?? 0}
              onPageItemClick={handlePagination}
            />
          )
        )}
      </div>
    </Container>
  );
};

export default LoadStatistics;
