
import { serializable, alias, object, list, primitive } from 'serializr';

export class LowSuggestion {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('Filename', primitive()))
	Filename?: string;

	@serializable(alias('Tag', primitive()))
	Tag?: string;

	@serializable(alias('Externaldesc', primitive()))
	Externaldesc?: string;

	@serializable(alias('Internaldesc', primitive()))
	Internaldesc?: string;

	@serializable(alias('Skucode', primitive()))
	Skucode?: string;

	@serializable(alias('Divisionname', primitive()))
	Divisionname?: string;

	@serializable(alias('Categoryname', primitive()))
	Categoryname?: string;

	@serializable(alias('Marketname', primitive()))
	Marketname?: string;

	@serializable(alias('Corporatebrandname', primitive()))
	Corporatebrandname?: string;

	@serializable(primitive())
	isChecked?: boolean = false;

}