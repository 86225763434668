import React, { useEffect, useState } from 'react';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import BreadcrumbComponent from 'shared/components/Breadcrumb-component/Breadcrumb-component';
import Databar from 'shared/components/Databar-component/Databar';
import { Container, Row, Col } from 'react-bootstrap';
import './ValidationFactDetails.scss';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import TabComponent from '../Component/Tab-component/Tab-component';
import { addToken } from 'interceptor/axiosInstance';
import moment from 'moment';
import Sidebar from 'react-sidebar';
import SidebarHighMedium from '../Component/SidebarHighMedium-component/SidebarHighMedium';
import ValidationFactDetailsHighMedium from '../ValidationFactDetailsHighMedium/ValidationFactDetailsHighMedium';
import ValidationFactDetailsLow from '../ValidationFactDetailsLow/ValidationFactDetailsLow';
import RedirectLeft from 'assets/icons/redirect-left.svg';
import RedirectRight from 'assets/icons/redirect-right.svg';
import ValidationFactService from 'services/ValidationFactService/validationFact.service';
import { displayDateTimeFormat } from 'constants/formats';
import { ConfidenceLevels } from 'enums/confidenceLevels';
import { SmartMappingList } from 'models/SmartMappingList/smartMappingList.model';
import { deserialize } from 'serializr';
import ValidationFactDetailsUnprocessed from '../ValidationFactDetailsUnprocessed/ValidationFactDetailsUnprocessed';

const ValidationFactDetails = React.memo(() => {
  const {
    fetchMappedData,
    fetchMappedDataPagination,
    loading,
    paginationLoading,
    mappedData,
    pagination,
    unprocessed,
    fetchUnprocessedRecords,
    fetchUnProcessedRecordsPagination,
    unprocessedPagination
  } = ValidationFactService();

  const { confidenceLevel, id } = useParams();
  const navigate = useNavigate();
  const queryP = new URLSearchParams(useLocation().search);
  const fileName = queryP.get('FileName');
  const [factDetailRes, setFactDetailRes] = useState([]);
  const [dashboardData, setDashboardData] = useState<SmartMappingList>();
  const [searchFile, setSearchFile] = useState('');
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [rowData, setRowData] = useState();

  const [lowLoading, setLowLoading] = useState<boolean>(false);

  async function fetchFactDetail() {
    const url = ApiRoutes.BASE_URL;
    if (confidenceLevel !== 'low' && confidenceLevel!=='unprocessed') {
      const params = {
        limit,
        offset,
        confidenceLevel,
        Filename: fileName,
        search: searchFile
      };

      fetchMappedData(params);
      fetchMappedDataPagination(params);
    }
    else if (confidenceLevel == 'unprocessed') {
      fetchUnprocessedRecords({
        Filename: fileName,
        // search: searchFile,
        limit,
        offset
      });
      fetchUnProcessedRecordsPagination({
        Filename: fileName,
        // search: searchFile,
        limit,
        offset
      });
    } else {
      setLowLoading(true);
      const response = await fetch(`${url}${ApiRoutes.VALIDATION_FACT_DETAIL}/low?FileName=${fileName}&search=${searchFile}`, {
        method: 'GET',
        headers: await getToken()
      });
      const data = await response.json();
      setFactDetailRes(data.result);
      setLowLoading(false);
    }
  }

  const fetchDashboardDeatils = async (id: any) => {
    const url = ApiRoutes.BASE_URL;
    const response = await fetch(`${url}${ApiRoutes.FACT_FILE_DETAIL}${id}`, {
      method: 'GET',
      headers: await getToken()
    });
    const data = await response.json();
    const deserilizedData = deserialize(SmartMappingList, data.detail);
    setDashboardData(deserilizedData);
    return;
  };

  const getToken = async () => {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${await addToken()}`);
    return myHeaders;
  };

  const formattedDate = moment.utc(dashboardData?.createdAt).format(displayDateTimeFormat);

  useEffect(() => {
    fetchDashboardDeatils(id);
    setSearchFile('');
  }, [id]);

  useEffect(() => {
    fetchFactDetail();
  }, [limit, offset, confidenceLevel, fileName, searchFile]);

  useEffect(() => {
    setLimit(10);
    setOffset(0);
  }, [confidenceLevel]);

  const searchValue = (value: string) => {
    setSearchFile(value);
  };

  const LimitPerPage = (value: any) => {
    setLimit(value);
  };

  const offSetPerPage = (value: any) => {
    setOffset(value);
  };

  const calculateSuccessValue = () => {
    const mappedCount = dashboardData?.mappedRecordCount ?? 0;
    const total = dashboardData?.total ?? 0;
    return +((+mappedCount / +total) * 100).toFixed(2);
  };

  const setRow = (value: any) => {
    const sidebarRoot = document.querySelector('.sidebar-root') as HTMLElement;
    sidebarRoot.style.setProperty('left', '0px');
    setRowData(value);
  };

  const getRecordsCount = () => {
    const { HIGH, MEDIUM, LOW, UNPROCESSED } = ConfidenceLevels;
    switch (confidenceLevel) {
      case HIGH:
        return dashboardData?.confidenceLevelLessThan70;
      case MEDIUM:
        return dashboardData?.confidenceLevel5070;
      case LOW:
        return dashboardData?.confidenceLevelLessThan50;
      case UNPROCESSED:
        return dashboardData?.confidenceLevelUnprocessed;
    }
  };

  return (
    <DashboardContainer>
      <Container fluid className='page-container-details'>
        <Row className='breadcrumb-container'>
          <Col sm={12}>
            <p className='page-heading'>Fact Detail</p>
            <BreadcrumbComponent
              links={[
                { link: 'Checks', active: false, onClick: () => navigate(`/${AppRoutes.CHECKS}`, { replace: true }) },
                {
                  link: 'Intelligent Mapping',
                  active: false,
                  onClick: () => navigate(`/${AppRoutes.CHECKS}/${AppRoutes.VALIDATION}/${AppRoutes.VALIDATION_FACT}`, { replace: true })
                },
                { link: 'Fact Detail', active: true }
              ]}
            />
          </Col>
          {/* Filename heading */}
          <Row>
            <div className='file-name-row'>
              <div className='file-change-btn'>
                <img src={RedirectLeft} alt='left-arrow' />
              </div>
              <p>Filename:</p>
              <span className='hyper-link'>{dashboardData?.fileName}</span>
              <div className='file-change-btn'>
                <img src={RedirectRight} alt='right-arrow' />
              </div>
            </div>
          </Row>
        </Row>
        <Row className='databar-container'>
          {
            <Databar
              DataProvider={dashboardData?.dataProvider ?? ''}
              TotalRecords={dashboardData?.total ?? 0}
              successStatus={calculateSuccessValue()}
              loadedOn={formattedDate}
            />
          }
        </Row>
        <Row className='tabs-container'>
          <TabComponent
            active={confidenceLevel as string}
            components={[
              {
                key: 'high',
                title: 'High',
                component: (
                  <>
                    <ValidationFactDetailsHighMedium
                      activeTab={confidenceLevel}
                      Data={mappedData}
                      Pagination={pagination}
                      Search={searchValue}
                      TotalRecords={pagination?.totalCount}
                      limit={LimitPerPage}
                      offset={offSetPerPage}
                      rowData={setRow}
                      loading={loading}
                      paginationLoading={paginationLoading}
                      recordsCount={getRecordsCount()}
                    />
                    {confidenceLevel === 'high' && (
                      <>
                        <Sidebar
                          open={true}
                          pullRight={true}
                          styles={{
                            root: { position: 'absolute', left: '9999px' },
                            sidebar: { zIndex: '5' },
                            content: { position: 'relative' },
                            overlay: { zIndex: '0', position: 'relative' }
                          }}
                          sidebarClassName='sidebar'
                          rootClassName='sidebar-root'
                          sidebar={rowData ? <SidebarHighMedium row={rowData} /> : <></>}
                          transitions={true}
                        />
                      </>
                    )}
                  </>
                )
              },
              {
                key: 'medium',
                title: 'Medium',
                component: (
                  <>
                    <ValidationFactDetailsHighMedium
                      activeTab={confidenceLevel}
                      Data={mappedData}
                      Pagination={pagination}
                      Search={searchValue}
                      TotalRecords={pagination?.totalCount}
                      limit={LimitPerPage}
                      offset={offSetPerPage}
                      rowData={setRow}
                      loading={loading}
                      paginationLoading={paginationLoading}
                      recordsCount={getRecordsCount()}
                    />
                    {confidenceLevel === 'medium' && (
                      <>
                        <Sidebar
                          open={true}
                          pullRight={true}
                          styles={{
                            root: { position: 'absolute', left: '9999px' },
                            sidebar: { zIndex: '5' },
                            content: { position: 'relative' },
                            overlay: { zIndex: '0', position: 'relative' }
                          }}
                          sidebarClassName='sidebar'
                          rootClassName='sidebar-root'
                          sidebar={rowData ? <SidebarHighMedium row={rowData} /> : <></>}
                          transitions={true}
                        />
                      </>
                    )}
                  </>
                )
              },
              {
                key: 'low',
                title: 'Low',
                component: (
                  <ValidationFactDetailsLow
                    Data={factDetailRes}
                    Search={searchValue}
                    loading={lowLoading}
                    recordsCount={getRecordsCount()}
                  />
                )
              },
              {
                key: 'unprocessed',
                title: 'UnProcessed',
                component: (
                  <ValidationFactDetailsUnprocessed
                    activeTab={confidenceLevel}
                    Data={unprocessed}
                    Pagination={unprocessedPagination}
                    Search={searchValue}
                    TotalRecords={pagination?.totalCount}
                    limit={LimitPerPage}
                    offset={offSetPerPage}
                    rowData={setRow}
                    loading={loading}
                    paginationLoading={paginationLoading}
                    recordsCount={getRecordsCount()}
                  />
                )
              }
            ]}
          />
        </Row>
      </Container>
    </DashboardContainer>
  );
});

export default ValidationFactDetails;
