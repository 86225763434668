import React, { useEffect, useState } from 'react';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import Loader from 'shared/components/Loader';
import { searchUtil } from 'shared/utils/searchUtil';
import { Button, ButtonGroup, Container, Dropdown } from 'react-bootstrap';
import 'shared/components/Breadcrumb-component/Breadcrumb-component.scss';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import EDPagination from 'shared/components/Pagination';
import BreadcrumbComponent from 'shared/components/Breadcrumb-component/Breadcrumb-component';
import EDDatePicker from 'shared/components/EDDatePicker';
import { Row, Col } from 'react-bootstrap';
import columns from './columns';
import ValidationFactService from 'services/ValidationFactService/validationFact.service';
import './ValidationFactSummary.scss';
import { createSearchParams } from 'react-router-dom';
import NewEDDropdown from '../Component/Dropdown-component/Dropdown';
import Table from '../Component/Table-component/Table-component';
import { MetaData } from 'models/MetaData/metaData.model';
import MetaService from 'services/MetaService/meta.service';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import { Order } from 'enums/order';

export interface ValidationFactFilters {
  search: string;
  filter_by_provider: string;
  filter_by_category: string;
  filter_by_country: string;
  filter_by_status: string;
  start_date: string;
  end_date: string;
  orderValue?: string;
}

const ValidationFactSummary: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<ValidationFactFilters>({
    search: '',
    filter_by_provider: '',
    filter_by_category: '',
    filter_by_country: '',
    filter_by_status: '',
    start_date: '',
    end_date: '',
    orderValue: Order.DESC
  });

  const navigateDetailPage = (cell: any) => {
    if (cell.cell.column.id.includes('confidenceLevel')) {
      navigate({
        pathname: `${cell?.row?.original?.id}/${cell.cell.column.Header.toLowerCase()}`,
        search: createSearchParams({
          FileName: cell.row.values.fileName
        }).toString()
      });
    } else {
      navigate({
        pathname: `${cell?.row?.original?.id}/high`,
        search: createSearchParams({
          FileName: cell.row.values.fileName
        }).toString()
      });
    }
  };

  const { fetchFactRecords, loading, factRecords, pagination, fetchFactRecordsPagination, paginationLoading } = ValidationFactService();

  const { loading: metaLoading, fetchMeta } = MetaService();

  const [countryMeta, setCountryMeta] = useState<MetaData[]>([]);

  const [categoryMeta, setCategoryMeta] = useState<MetaData[]>([]);

  const [providerMeta, setProviderMeta] = useState<MetaData[]>([]);

  const [changedFilter, setChangedFilter] = useState<keyof ValidationFactFilters>();

  useEffect(() => {
    handleFetchMeta();
  }, []);

  useEffect(() => {
    if (filters) {
      fetchFactRecords(filters);
      fetchFactRecordsPagination(filters);
    }
  }, [filters]);

  const updateFilters = (filterName: string, value: string) => {
    setFilters({
      ...filters,
      [filterName]: value
    });

    switch (filterName) {
      case 'filter_by_category':
        setChangedFilter(filterName);
        fetchMeta(ApiRoutes.COUNTRY_FACT, setCountryMeta, MetaData, { category: String(value) });
        break;
      case 'filter_by_country':
        setChangedFilter(filterName);
        fetchMeta(ApiRoutes.CATEGORY_FACT, setCategoryMeta, MetaData, { country: String(value) });
        break;
    }
  };

  const updateDateFilter = (startDate: string, endDate: string) => {
    setFilters({
      ...filters,
      start_date: startDate,
      end_date: endDate
    });
  };

  const handlePagination = (pageNumber: number, recordsPerPage?: number) => {
    const currentPageSize = pagination?.pageSize ?? 0;
    const limit = recordsPerPage ?? currentPageSize;
    const offset = (pageNumber - 1) * limit;
    fetchFactRecords(filters, limit, offset);
    fetchFactRecordsPagination(filters, limit, offset);
  };

  const tableColumns = columns(navigateDetailPage);

  const clearFilter = () => {
    setFilters({
      search: '',
      filter_by_provider: '',
      filter_by_category: '',
      filter_by_country: '',
      filter_by_status: '',
      start_date: '',
      end_date: ''
    });
  };

  const handleFetchMeta = () => {
    fetchMeta(ApiRoutes.COUNTRY_FACT, setCountryMeta, MetaData);
    fetchMeta(ApiRoutes.CATEGORY_FACT, setCategoryMeta, MetaData);
    fetchMeta(ApiRoutes.PROVIDER_FACT, setProviderMeta, MetaData);
  };

  return (
    <DashboardContainer>
      <Container fluid className='validation-dashboard'>
        <Row className='top-row'>
          <Col sm={4}>
            <div className='breadcrumb-container'>
              <p className='page-heading'>Intelligent Mapping</p>
              <BreadcrumbComponent
                links={[
                  { link: 'Checks', active: false, onClick: () => navigate(`/${AppRoutes.CHECKS}`, { replace: true }) },
                  {
                    link: 'Intelligent Mapping',
                    active: true,
                    onClick: () => navigate(`/${AppRoutes.CHECKS}/${AppRoutes.VALIDATION_RECORDS}`, { replace: true })
                  }
                ]}
              />
            </div>
          </Col>
        </Row>
        <div className='tab-container'>
          <ButtonGroup aria-label='Basic example'>
            <Button
              variant='outline-primary'
              className='button-outline'
              onClick={() => {
                navigate({ pathname: `../../${AppRoutes.VALIDATION_RECORDS}` });
              }}
            >
              Product
            </Button>
            <Button variant='primary' className='button-primary active'>
              Fact
            </Button>
            <Button
              variant='outline-primary'
              className='button-outline'
              onClick={() => {
                navigate({ pathname: `../../${AppRoutes.VALIDATION}/${AppRoutes.VALIDATION_MARKET}` });
              }}
            >
              Market
            </Button>
            <Button
              variant='outline-primary'
              className='button-outline'
              onClick={() => {
                navigate({ pathname: `../../${AppRoutes.VALIDATION}/${AppRoutes.VALIDATION_PERIOD}` });
              }}
            >
              Period
            </Button>
          </ButtonGroup>
        </div>

        <Row className='mt-5'>
          <Col sm={12}>
            <div className='filters-container-second'>
              <div className='filter-container filter-container-same-line'>
                <NewEDDropdown
                  loading={!changedFilter && metaLoading}
                  optionItems={providerMeta}
                  value={filters.filter_by_provider}
                  currentItemIndex={2}
                  labelText='Data Provider'
                  defaultValue='All(22)'
                  updateValue={(value) => updateFilters('filter_by_provider', value)}
                  resetIcon={true ? filters.filter_by_provider !== '' : false}
                  resetFunc={() => updateFilters('filter_by_provider', '')}
                />
              </div>
              <div className='filter-container country-filter filter-container-same-line'>
                <NewEDDropdown
                  loading={changedFilter ? changedFilter === 'filter_by_category' && metaLoading : metaLoading}
                  optionItems={countryMeta}
                  value={filters.filter_by_country}
                  currentItemIndex={2}
                  labelText='Country'
                  defaultValue='All(22)'
                  updateValue={(value) => updateFilters('filter_by_country', value)}
                  resetIcon={true ? filters.filter_by_country !== '' : false}
                  resetFunc={() => updateFilters('filter_by_country', '')}
                />
              </div>
              <div className='filter-container filter-container-same-line'>
                <NewEDDropdown
                  loading={changedFilter ? changedFilter === 'filter_by_country' && metaLoading : metaLoading}
                  optionItems={categoryMeta}
                  value={filters.filter_by_category}
                  currentItemIndex={2}
                  labelText='Category'
                  defaultValue='All(22)'
                  resetIcon={true ? filters.filter_by_provider !== '' : false}
                  resetFunc={() => updateFilters('filter_by_category', '')}
                  updateValue={(value) => updateFilters('filter_by_category', value)}
                />
              </div>
              <div className='filter-container filter-container-same-line'>
                <EDDatePicker onDateSelected={(startDate, endDate) => updateDateFilter(startDate, endDate)} />
              </div>
              <div className='search-inner filter-container-same-line'>
                <SearchInputField
                  onChange={searchUtil((e: React.ChangeEvent<HTMLInputElement>) => updateFilters('search', e?.target?.value))}
                  placeholder='Search...'
                  width='auto'
                  height='44px'
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div className='table-container'>{loading ? <Loader /> : <Table columns={tableColumns} data={factRecords} />}</div>
            {paginationLoading ? (
              <SkeletonLoader />
            ) : (
              pagination && (
                <EDPagination
                  page={pagination?.page ?? 0}
                  pageSize={pagination?.pageSize ?? 0}
                  totalCount={pagination?.totalCount ?? 0}
                  onPageItemClick={handlePagination}
                />
              )
            )}
          </Col>
        </Row>
      </Container>
    </DashboardContainer>
  );
};

export default ValidationFactSummary;
