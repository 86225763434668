
import {serializable, alias, primitive} from 'serializr';

export class FactUnProcessedRecord { 

	@serializable(alias('Id', primitive()))
	Id?: number;

	@serializable(alias('Filename', primitive()))
	Filename?: string;

	@serializable(alias('Tag', primitive()))
	Tag?: string;

	@serializable(alias('Externaldesc', primitive()))
	Externaldesc?: string;

	@serializable(alias('Createdon', primitive()))
	Createdon?: string;

	@serializable(alias('Facttype', primitive()))
	FactType?: string;

}