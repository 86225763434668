import React from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';
import './Cards.scss';
import imgSrc from 'assets/icons/CardArrowVector.svg';
import SkeletonLoader from '../SkeletonLoader';
import { SkeletonLoaderSizeTypes } from 'enums/skeletonLoaderType';

interface Props {
  heading: string;
  value: number;
  footer1Heading: string;
  footer1Value: number;
  footer2Heading: string;
  footer2Value: number;
  footer3Heading?: string;
  footer3Value?: number;
  cardHeaderClickHandler?: () => void;
  image?: string;
  loading?: boolean;
}
const Cards: React.FC<Props> = ({
  heading,
  value,
  footer1Heading,
  footer1Value,
  footer2Heading,
  footer2Value,
  footer3Heading,
  footer3Value,
  cardHeaderClickHandler,
  image,
  loading
}: Props) => {
  return (
    <div>
      <Row>
        <Col>
          <Card className='card-template' onClick={cardHeaderClickHandler}>
            <Card.Header className='card-template-header-general cursor-pointer'>
              <p className='card-template-heading'>{heading}</p>
              <img alt='search icon' className='card-template-header-left-icon' src={imgSrc} />
            </Card.Header>
            <Card.Body className='card-template-body'>
              <Stack direction='horizontal' className='card-template-stack'>
                {loading ? (
                  <SkeletonLoader xs={4} className='total-value-loading' />
                ) : (
                  <Card.Title className='card-template-title'>
                    <p>{value}</p>
                  </Card.Title>
                )}
                {image && <img src={image} alt='card-icon' />}
              </Stack>
            </Card.Body>
            <Card.Footer className='card-template-footer'>
              <Row>
                <Col xs={4} className='footer-item'>
                  <p>{footer1Heading}</p>
                  {loading ? (
                    <SkeletonLoader xs={6} size={SkeletonLoaderSizeTypes.SMALL} className='footer-value-loading' />
                  ) : (
                    <p>{footer1Value}</p>
                  )}
                </Col>
                <Col xs={4} className='footer-item'>
                  <p>{footer2Heading}</p>
                  {loading ? (
                    <SkeletonLoader xs={6} size={SkeletonLoaderSizeTypes.SMALL} className='footer-value-loading' />
                  ) : (
                    <p>{footer2Value}</p>
                  )}
                </Col>
                {footer3Heading ? (
                  <Col className='footer-item'>
                    <p>{footer3Heading}</p>
                    {loading ? (
                      <SkeletonLoader xs={6} size={SkeletonLoaderSizeTypes.SMALL} className='footer-value-loading' />
                    ) : (
                      <p>{footer3Value}</p>
                    )}
                  </Col>
                ) : null}
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Cards;
