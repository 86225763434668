
import {serializable, alias, object, list, primitive} from 'serializr';

export class Profile { 

	@serializable(alias('Id', primitive()))
	Id?: number;

	@serializable(alias('Name', primitive()))
	Name?: string;

	@serializable(alias('Email', primitive()))
	Email?: string;

	@serializable(alias('Role', primitive()))
	Role?: object;

	@serializable(alias('Avatar', primitive()))
	Avatar?: object;

	@serializable(alias('IsFirstTimeLogin', primitive()))
	IsFirstTimeLogin?: boolean;

	@serializable(alias('Status', primitive()))
	Status?: object;

}