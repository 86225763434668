import React from 'react';
import { Routes, Route, BrowserRouter, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from '../shared/types/route.type';
import { AppRoutes } from './routeConstants/appRoutes';
import DesignLibrary from 'views/DesignLibrary';
import ValidationDashboard from 'views/ValidationFlow/ProductDimension/ValidationDashboard';
import VolatilityDashboard from 'views/FileVolatility/VolatilityDashboard';
import VolatilityHome from 'views/FileVolatility/VolatilityHome';
import VolatilityMapping from 'views/FileVolatility/VolatilityMapping';
import OperationGraph from 'views/HomeView';
import { useMsal } from '@azure/msal-react';
import { ProfileContent } from 'shared/components/SSOProfileContent';
import WorkUnderProgress from 'views/WorkUnderProgress';
import RoleSelectionFlow from 'views/RoleSelectionFlow';
import LeadershipDashboard from 'views/LeadershipDashboard';
import VolatilityMappingFact from 'views/FactFileVolatility/VolatilityMapping/VolatilityMappingFact';
import ValidationHome from 'views/ValidationFlow/ProductDimension/ValidationHome';
import ValidationRecordDetails from 'views/ValidationFlow/ProductDimension/ValidationRecordDetails';
import ValidationPeriodSummary from 'views/ValidationFlow/PeriodDimension/Summary';
import PeriodDimensionDetails from 'views/ValidationFlow/PeriodDimension/PeriodDimensionDetails';
import ValidationMarketSummary from 'views/ValidationFlow/MarketDimension/Summary';
import MarketDimensionDetails from 'views/ValidationFlow/MarketDimension/Details';
import VolatilityMappingPeriod from 'views/PeriodFileVolatility/VolatilityMapping/VolatilityMappingPeriod';
import ValidationFactSummary from 'views/Validations/ValidationFactSummary/ValidationFactSummary';
import ValidationFactDetails from 'views/Validations/ValidationFactDetails/ValidationFactDetails';
import DqCheckPage from 'views/DqCheckPage';

const AppRouter: React.FC = () => {
  const routes: RouterProps[] = [
    {
      path: '*',
      element: <WorkUnderProgress />,
      children: []
    },
    {
      path: AppRoutes.LEADERSHIP_GRAPH,
      element: <LeadershipDashboard />,
      children: []
    },
    {
      path: AppRoutes.OPERATION_GRAPH,
      element: <OperationGraph />,
      children: []
    },
    {
      path: AppRoutes.SSO_TEST,
      element: <ProfileContent />,
      children: []
    },
    {
      path: AppRoutes.ROLE_SELECTION,
      element: <RoleSelectionFlow />,
      children: []
    },
    {
      path: AppRoutes.CHECKS,
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <ValidationHome />
        },
        {
          path: AppRoutes.FILE_VOLATALITY_RECORDS,
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <VolatilityDashboard />
            },
            {
              path: AppRoutes.FILE_VOLATILITY_MAPPED,
              element: <VolatilityMapping />
            },
            {
              path: AppRoutes.FILE_VOLATILITY_MARKET_MAPPED,
              element: <VolatilityMapping entity='Market' />
            },
            {
              path: AppRoutes.FILE_VOLATILITY_FACT_MAPPED,
              element: <VolatilityMapping entity='Fact' />
            },
            {
              path: AppRoutes.FILE_VOLATILITY_PERIOD_MAPPED,
              element: <VolatilityMapping entity='Period' />
            }
          ]
        },
        {
          path: AppRoutes.VALIDATION_RECORDS,
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <ValidationDashboard />
            },
            {
              path: AppRoutes.VALIDATION_RECORD_DETAILS,
              element: <ValidationRecordDetails />
            }
          ]
        },
        {
          path: AppRoutes.VALIDATION,
          element: <Outlet />,
          children: [
            {
              path: AppRoutes.VALIDATION_FACT,
              element: <ValidationFactSummary />
            },
            {
              path: AppRoutes.VALIDATION_FACT_DETAILS,
              element: <ValidationFactDetails />
            },
            {
              path: AppRoutes.VALIDATION_PERIOD,
              element: <ValidationPeriodSummary />
            },
            {
              path: AppRoutes.VALIDATION_PERIOD_DETAILS,
              element: <PeriodDimensionDetails />
            },
            {
              path: AppRoutes.VALIDATION_MARKET,
              element: <ValidationMarketSummary />
            },
            {
              path: AppRoutes.VALIDATION_MARKET_DETAILS,
              element: <MarketDimensionDetails />
            }
          ]
        }
      ]
    },
    {
      path: AppRoutes.FILE_VOLATILITY_HOME,
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <VolatilityHome />
        },

        {
          path: AppRoutes.DQ_CHECK,
          element: <DqCheckPage />
        }
      ]
    },
    {
      path: '/',
      element: <Navigate to={'/checks'} />,
      children: []
    }
  ];

  if (process.env.REACT_APP_UNDER_DEVELOPMENT === 'true') {
    routes.push({
      path: AppRoutes.ROOT,
      element: <DesignLibrary />,
      children: []
    });
  }

  return (
    <BrowserRouter>
      <Routes>
        {routes?.map((route, index) => {
          return (
            <Route key={index} path={route.path} element={route.element}>
              {route.children &&
                route.children.map((childRoute) => (
                  <Route key={childRoute.path} path={childRoute.path} element={childRoute.element}>
                    {childRoute.children &&
                      childRoute.children.map((grandchildRoute) => (
                        <Route key={grandchildRoute.path} path={grandchildRoute.path} element={grandchildRoute.element} />
                      ))}
                  </Route>
                ))}
            </Route>
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
