import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import CustomButton from 'shared/components/Button-component';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import Loader from 'shared/components/Loader';
import EDPagination from 'shared/components/Pagination';
import Table from 'shared/components/Table-component/Table-component';
import ValidationPeriodDimensionService from 'services/ValidationPeriodDimensionService/validationPeriodDimension.service';
import fetchColumns from './column';
import ToastComponent from 'shared/components/Toast-component/Toast-component';
import './periodMappedDetails.scss';
import ArrowRight from 'assets/icons/arrow-right.svg';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import ValidationService from 'services/ValidationService/validation.service';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';

const PeriodMappedDetails = (props: any) => {
  const { id, confidenceLevel } = useParams();
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const columns = [
    {
      Header: 'External',
      minWidth: 400,
      columns: [
        {
          Header: 'External TAG',
          accessor: 'Tag',
          minWidth: 200,
          id: 'tag'
        },
        {
          Header: 'External Short Desc',
          accessor: 'Short',
          minWidth: 200,
          id: 'short_description'
        },
        {
          Header: 'External Long Description',
          accessor: 'Long',
          minWidth: 200,
          id: 'long_description'
        },
        {
          Header: 'Last Period',
          accessor: 'LastPeriodTagFormat',
          minWidth: 200,
          id: 'period'
        }
      ]
    },

    {
      Header: 'Internal',
      minWidth: 400,
      columns: [
        {
          Header: 'Periodicity',
          accessor: 'Periodicity',
          minWidth: 200,
          id: 'internal_periodicity'
        },
        {
          Header: 'Year',
          accessor: 'YearBr',
          minWidth: 200,
          id: 'internal_year'
        },
        {
          Header: 'Quarter',
          accessor: 'QuarterBr',
          minWidth: 200,
          id: 'quarter'
        },
        {
          Header: 'Month',
          accessor: 'MonthBr',
          minWidth: 200,
          id: 'month'
        },
        {
          Header: 'Week',
          accessor: 'WeekBr',
          minWidth: 200,
          id: 'week'
        },
        {
          Header: 'Start Date',
          accessor: 'PeriodStartDate',
          minWidth: 200,
          id: 'start_date'
        },
        {
          Header: 'End Date',
          accessor: 'PeriodEndDate',
          minWidth: 200,
          id: 'end_date'
        },
        {
          Header: 'Min Period Number',
          accessor: 'MinPeriodNumBr',
          minWidth: 200,
          id: 'MinPeriodNumBr'
        },
        {
          Header: 'Max Period Number',
          accessor: 'MaxPeriodNumBr',
          minWidth: 200,
          id: 'MaxPeriodNumBr'
        },
        {
          Header: 'Country Week Start Day',
          accessor: 'WeekStartDayCountry',
          minWidth: 200,
          id: 'WeekStartDayCountry'
        },
        {
          Header: 'Periodicity Identifier',
          accessor: 'PeriodicityIdentifer',
          minWidth: 200,
          id: 'PeriodicityIdentifer'
        },
        {
          Header: 'Convention',
          accessor: 'Convention',
          minWidth: 200,
          id: 'Convention'
        },
        {
          Header: 'Period Number',
          accessor: 'PeriodNumberBr',
          minWidth: 200,
          id: 'PeriodNumberBr'
        }
      ]
    },
    {
      Header: '',
      accessor: 'null',
      Cell: (row: any) => {
        return (
          <div
            onClick={() => {
              onRowClick(row);
            }}
            className='cursor-pointer'
            onKeyDown={() => {}}
            role='link'
            tabIndex={0}
          >
            <img src={ArrowRight} alt='img' width={20} height={20} />
          </div>
        );
      },
      minWidth: 30
    }
  ];
  const onRowClick = (row: any) => {
    props.rowData(row?.row.original);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (id) {
      fetchPeriodMappedData(id);
      fetchPeriodMappedDataPagination(id);
    }
  }, [id]);

  const {
    fetchPeriodMappedData,
    periodDimensionList,
    pagination,
    loading,
    toggleCheckStatus,
    toggleAllCheckbox,
    toggleAllStatus,
    resetCheckStatus,
    paginationLoading,
    fetchPeriodMappedDataPagination
  } = ValidationPeriodDimensionService();

  const { buttonLoading, downloadExcelFile } = ValidationService();

  //making null values in table to '-'
  periodDimensionList.map((item: any) => {
    for (let key in item)
      if (item[key] === null) {
        item[key] = '-';
      }
  });

  const handlePagination = (pageNumber: number, recordsPerPage?: number) => {
    const currentPageSize = pagination?.pageSize ?? 0;
    const limit = recordsPerPage ?? currentPageSize;
    const offset = (pageNumber - 1) * limit;
    if (id) {
      fetchPeriodMappedData(id, {
        limit,
        offset
      });
      fetchPeriodMappedDataPagination(id, {
        limit,
        offset
      });
    }
  };

  if (!id) return <Loader />;

  const tableColumns = fetchColumns(toggleCheckStatus, toggleAllCheckbox, toggleAllStatus);

  const isRaiseIssueButtonEnable = periodDimensionList.some((item) => item.isSelected === true);

  const raiseIssue = () => {
    setShowNotification(true);
    resetCheckStatus();
  };

  const handleDownload = () => {
    if (id && confidenceLevel && props.fileName)
      downloadExcelFile(ApiRoutes.DOWNLOAD_PERIOD_EXCEL, id, {
        confidenceLevel,
        fileName: props.fileName
      });
  };

  return (
    <div className='period-mapped-details'>
      <Row className='justify-content-between'>
        <ToastComponent showState={showNotification} toggleShow={() => setShowNotification(false)} message='Issue raised' />
        <Col sm={4}>
          <CustomButton disabled={!props.recordsCount} onClick={handleDownload}>
            {buttonLoading ? 'Downloading...' : 'Download File'}
          </CustomButton>
        </Col>
        <Col sm={4} className='search-container'>
          <SearchInputField placeholder='Search...' width='354px' height='48px' />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className='table-container-scroll table-container mt-5'>
            {loading ? <Loader /> : <Table columns={columns} data={periodDimensionList} tableFor='highMedium' />}
          </div>
          {paginationLoading ? (
            <SkeletonLoader />
          ) : (
            pagination && (
              <EDPagination
                page={pagination?.page ?? 0}
                pageSize={pagination?.pageSize ?? 0}
                totalCount={pagination?.totalCount ?? 0}
                onPageItemClick={handlePagination}
              />
            )
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PeriodMappedDetails;
