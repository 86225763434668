import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import CustomButton from 'shared/components/Button-component/Button';
import './ValidationFactDetailsHighMedium.scss';
import EDPagination from 'shared/components/Pagination';
import { useLocation, useParams } from 'react-router';
import ToastComponent from 'shared/components/Toast-component/Toast-component';
import Loader from 'shared/components/Loader';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import Table from '../Component/Table-component/Table-component';
import ValidationFactService from 'services/ValidationFactService/validationFact.service';
import Sidebar from 'react-sidebar';
import SidebarHighMedium from '../Component/SidebarHighMedium-component/SidebarHighMedium';
import ArrowRight from 'assets/icons/arrow-right.svg';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import ValidationService from 'services/ValidationService/validation.service';

const ValidationFactHighMedium = React.memo((props: any) => {
  var data = props.Data;
  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  const queryP = new URLSearchParams(useLocation().search);
  const fileName = queryP.get('FileName');
  const { id, confidenceLevel } = useParams();

  const { buttonLoading, downloadExcelFile } = ValidationService();

  let pagination = props.Pagination;
  const columns: any = [
    {
      Header: 'External',
      accessor: 'description',
      minWidth: 600,
      external: 'external',
      columns: [
        {
          Header: 'External TAG',
          accessor: 'Tag',
          minWidth: 180,
          id: 'externalTag'
        },
        {
          Header: 'External Long Description',
          accessor: 'Externaldesc',
          minWidth: 300,
          id: 'long'
        },
        {
          Header: 'External Short Description',
          accessor: 'Short',
          minWidth: 300,
          id: 'short'
        },
        {
          Header: 'Currency',
          accessor: 'Currency',
          minWidth: 180,
          id: 'externalCurrency'
        },
        {
          Header: 'Precision',
          accessor: 'Precision',
          minWidth: 180,
          id: 'externalPrecision'
        },
        {
          Header: 'Denominator',
          accessor: 'Denominator',
          minWidth: 180,
          id: 'externalDenominator'
        },
        {
          Header: 'Unique TAG',
          accessor: 'Uniqueidentifier',
          minWidth: 180,
          id: 'externalUniqueTAG'
        }
      ]
    },
    {
      Header: 'Internal',
      accessor: 'description_internal',
      minWidth: 300,
      id: 'internal',
      columns: [
        {
          Header: 'Internal Fact Description',
          accessor: 'Harmonizedname',
          minWidth: 300,
          id: 'internalFactDescription'
        },
        {
          Header: 'Internal Fact Type',
          accessor: 'Facttype',
          minWidth: 200,
          id: 'Internal_Fact_Type'
        }
      ]
    },
    {
      Header: '',
      accessor: 'rowExpand',
      minWidth: 30,
      columns: [
        {
          Header: '',
          accessor: 'rowExpand',
          minWidth: 30,
          Cell: (row: any) => {
            return (
              <div
                onClick={() => {
                  onRowClick(row);
                }}
                className='cursor-pointer'
                onKeyDown={() => {}}
                role='link'
                tabIndex={0}
              >
                <img src={ArrowRight} alt='img' width={20} height={20} />
              </div>
            );
          }
        }
      ]
    }
  ];

  const onRowClick = (row: any) => {
    props.rowData(row.row.original);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {}, [confidenceLevel]);

  if (!id) return null;

  const handlePagination = (pageNumber: number, recordsPerPage?: number) => {
    const currentPageSize = pagination?.pageSize ?? 0;
    const limit = recordsPerPage ?? currentPageSize;
    const offset = (pageNumber - 1) * limit;
    props.limit(limit);
    props.offset(offset);
    pagination['page'] = pageNumber;
    pagination['pageSize'] = limit;
  };

  useEffect(() => {
    pagination = props.pagination;
  }, [confidenceLevel]);

  const handleDownload = () => {
    if (id && confidenceLevel && fileName)
      downloadExcelFile(ApiRoutes.DOWNLOAD_FACT_EXCEL, id, {
        confidenceLevel,
        fileName
      });
  };

  return (
    <Container fluid className='container-mapped'>
      <Row className='justify-content-between'>
        <ToastComponent showState={showA} toggleShow={toggleShowA} message='Issue raised' />
        <Col sm={4}>
          <CustomButton disabled={!props.recordsCount} onClick={handleDownload}>
            {buttonLoading ? 'Downloading...' : 'Download File'}
          </CustomButton>
        </Col>
        <Col sm={3} className='search-container'>
          <SearchInputField placeholder='Search...' width='354px' height='48px' onChange={(e: any) => props.Search(e.target.value)} />
        </Col>
      </Row>
      <Row sm={12}>
        <div className='table-container table-container-high'>
          {props?.loading ? <Loader /> : <Table columns={columns} data={data} tableFor='highMedium' />}
        </div>
        {props?.paginationLoading ? (
          <SkeletonLoader />
        ) : (
          pagination && (
            <EDPagination
              page={pagination?.page ?? 0}
              pageSize={pagination?.pageSize ?? 0}
              totalCount={pagination?.totalCount ?? 0}
              onPageItemClick={handlePagination}
            />
          )
        )}
      </Row>
    </Container>
  );
});

export default ValidationFactHighMedium;
