import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ValidationFactService from 'services/ValidationFactService/validationFact.service';
import moment from 'moment';
import { displayDateTimeFormat } from 'constants/formats';
import { Col, Container, Row } from 'react-bootstrap';
import CustomButton from 'shared/components/Button-component';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import EDPagination from 'shared/components/Pagination';
import Loader from 'shared/components/Loader';
import Table from '../Component/Table-component/Table-component';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';

const ValidationFactDetailsUnprocessed = (props: any) => {
  const data = props.Data;
  const queryP = new URLSearchParams(useLocation().search);
  const fileName = queryP.get('FileName');
  const { id, confidenceLevel } = useParams();
  const { buttonLoading, downloadExcelFile } = ValidationFactService();
  let pagination = props.Pagination;
  const columns: any = [
    {
      Header: 'FileName',
      accessor: 'Filename',
      minWidth: 240
    },
    {
      Header: 'Tag',
      accessor: 'Tag',
      minWidth: 240
    },
    {
      Header: 'Externaldesc',
      accessor: 'Externaldesc',
      minWidth: 200
    },
    {
      Header: 'Created On',
      accessor: 'Createdon',
      minWidth: 180,
      Cell: ({ cell }: any) => (
        <div role='link' tabIndex={0}>
          {moment.utc(cell.value).format(displayDateTimeFormat)}
        </div>
      )
    },
    {
      Header: 'Fact Type',
      accessor: 'FactType',
      minWidth: 180
    }
  ];
  useEffect(() => {
    pagination = props.pagination;
  }, [confidenceLevel]);

  if (!id) return null;

  const handlePagination = (pageNumber: number, recordsPerPage?: number) => {
    const currentPageSize = pagination?.pageSize ?? 0;
    const limit = recordsPerPage ?? currentPageSize;
    const offset = (pageNumber - 1) * limit;
    props.limit(limit);
    props.offset(offset);
    pagination['page'] = pageNumber;
    pagination['pageSize'] = limit;
  };

  const handleDownload = () => {
    if (id && confidenceLevel && fileName)
      downloadExcelFile(ApiRoutes.DOWNLOAD_FACT_EXCEL, id, {
        confidenceLevel,
        fileName
      });
  };

  return (
    <Container fluid className='container-mapped'>
      <Row className='justify-content-between'>
        <Col sm={4}>
          <CustomButton disabled={!props.recordsCount} onClick={handleDownload}>
            {buttonLoading ? 'Downloading...' : 'Download File'}
          </CustomButton>
        </Col>
        <Col sm={3} className='search-container'>
          <SearchInputField placeholder='Search...' width='354px' height='48px' onChange={(e: any) => props.Search(e.target.value)} />
        </Col>
      </Row>
      <Row sm={12}>
        <div className='table-container table-container-high'>{props?.loading ? <Loader /> : <Table columns={columns} data={data} />}</div>
        {props?.paginationLoading ? (
          <SkeletonLoader />
        ) : (
          pagination && (
            <EDPagination
              page={pagination?.page ?? 0}
              pageSize={pagination?.pageSize ?? 0}
              totalCount={pagination?.totalCount ?? 0}
              onPageItemClick={handlePagination}
            />
          )
        )}
      </Row>
    </Container>
  );
};

export default ValidationFactDetailsUnprocessed;
