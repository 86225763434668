import React, { FC, useEffect, useState } from 'react'
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer'
import './VolatilityMapping.scss';
import VolatilityService from 'services/VolatilityService/volatility.service';
import { useParams } from 'react-router';
import ToastComponent from 'shared/components/Toast-component/Toast-component';
import ColumnMappingView from './ColumnMappingView';
import Loader from 'shared/components/Loader';

interface VolatilityMappingProps {
  entity?: string
}

const VolatilityMapping = (props: VolatilityMappingProps) => {

  const { entity } = props

  const { fetchColumnMappings, fileMappingData, loading } = VolatilityService();
  const [showToast, setShowToast] = useState<boolean>(false)

  const { id } = useParams();
  const currentEntity = entity ?? "Product"

  useEffect(() => {
    id && fetchColumnMappings(id, {
      entity: currentEntity
    });
  }, [entity]);

  if (!id) return <Loader />;

  const toggleToast = () => {
    setShowToast(!showToast)
  }

  if (fileMappingData === undefined) return <Loader />

  return (
    <DashboardContainer>
      <ToastComponent showState={showToast} toggleShow={toggleToast} message='Mappings updated' />
      <ColumnMappingView
        entity={currentEntity}
        data={fileMappingData}
        fetchColumnMappings={fetchColumnMappings}
        loading={loading}
      />
    </DashboardContainer>
  )
}

export default VolatilityMapping