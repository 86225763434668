
import { LowSuggestion } from 'models/LowSuggestion/lowSuggestion.model';
import { serializable, alias, object, list, primitive } from 'serializr';

export class SmartMappingMappedResult {

	@serializable(alias('Id', primitive()))
	Id?: number;

	@serializable(alias('Tag', primitive()))
	Tag?: string;

	@serializable(alias('Hiernum', primitive()))
	Hiernum?: string;

	@serializable(alias('Hiername', primitive()))
	Hiername?: string;

	@serializable(alias('Flag', primitive()))
	Flag?: string;

	@serializable(alias('Hierlevelnum', primitive()))
	Hierlevelnum?: string;

	@serializable(alias('Hierlevelname', primitive()))
	Hierlevelname?: string;

	@serializable(alias('Parenttag', primitive()))
	Parenttag?: string;

	@serializable(alias('Company', primitive()))
	Company?: string;

	@serializable(alias('Brand', primitive()))
	Brand?: string;

	@serializable(alias('Short', primitive()))
	Short?: string;

	@serializable(alias('Internaldesc', primitive()))
	Internaldesc?: string;

	@serializable(alias('Externaldesc', primitive()))
	Externaldesc?: string;

	@serializable(alias('Productname', primitive()))
	Productname?: string;

	@serializable(alias('Skucode', primitive()))
	Skucode?: string;

	@serializable(alias('Spfvname', primitive()))
	Spfvname?: string;

	@serializable(alias('Categoryname', primitive()))
	Categoryname?: string;

	@serializable(alias('Marketname', primitive()))
	Marketname?: string;

	@serializable(alias('Divisionname', primitive()))
	Divisionname?: string;

	@serializable(alias('Categorygroupname', primitive()))
	Categorygroupname?: string;

	@serializable(alias('Segmentname', primitive()))
	Segmentname?: string;

	@serializable(alias('Sectorname', primitive()))
	Sectorname?: string;

	@serializable(alias('Formname', primitive()))
	Formname?: string;

	@serializable(alias('Subformname', primitive()))
	Subformname?: string;

	@serializable(alias('Productformname', primitive()))
	Productformname?: string;

	@serializable(alias('Productpackformname', primitive()))
	Productpackformname?: string;

	@serializable(alias('Productpacksizename', primitive()))
	Productpacksizename?: string;

	@serializable(alias('Productvariantname', primitive()))
	Productvariantname?: string;

	@serializable(alias('Productcodename', primitive()))
	Productcodename?: string;

	@serializable(alias('Corporatebrandname', primitive()))
	Corporatebrandname?: string;

	@serializable(alias('Productformmediumname', primitive()))
	Productformmediumname?: string;

	@serializable(alias('Brandformname', primitive()))
	Brandformname?: string;

	@serializable(alias('Confidencelevel', primitive()))
	Confidencelevel?: boolean;

	@serializable(alias('createdAt', primitive()))
	createdAt?: string;

	@serializable(alias('updatedAt', primitive()))
	updatedAt?: string;

	@serializable(primitive())
	isSelected?: boolean = false;

	@serializable(primitive())
	isChecked?: boolean = false;

	@serializable(primitive())
	lowRecords?: LowSuggestion[] = [];

	@serializable(primitive())
	searchResult?: LowSuggestion[] = [];
}