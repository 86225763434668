import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import './Mapped.scss';
import Table from 'shared/components/Table-component/Table-component';
import EDPagination from 'shared/components/Pagination';
import { useParams } from 'react-router';
import ToastComponent from 'shared/components/Toast-component/Toast-component';
import Loader from 'shared/components/Loader';
import ArrowRight from 'assets/icons/arrow-right.svg';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import CustomButton from 'shared/components/Button-component/Button';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import ValidationService from 'services/ValidationService/validation.service';

interface MappedProps {
  confidenceLevel?: string;
  fileName?: string;
  rowData: (value: any) => void;
  recordsCount?: number;
}

const Mapped = (props: MappedProps) => {
  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(true);
  const CloseShowA = () => setShowA(false);
  const { confidenceLevel, fileName, rowData, recordsCount } = props;

  const columns: any = [
    {
      Header: 'External',
      columns: [
        {
          Header: 'External Product Description',
          accessor: 'Externaldesc',
          minWidth: 280
        },
        {
          Header: 'External Short Description',
          accessor: 'Short',
          minWidth: 280
        },
        {
          Header: 'External TAG',
          accessor: 'Tag',
          minWidth: 200
        },
        {
          Header: 'External Hierarchy Number',
          accessor: 'Hiernum',
          minWidth: 180
        },
        {
          Header: 'External Hierarchy Name',
          accessor: 'Hiername',
          minWidth: 240
        },
        {
          Header: 'External Hierarchy Level',
          accessor: 'Hierlevelnum',
          minWidth: 180
        },
        {
          Header: 'External Hierarchy Level Name',
          accessor: 'Hierlevelname',
          minWidth: 180
        },
        {
          Header: 'External Parent TAG',
          accessor: 'Parenttag',
          minWidth: 200
        },
        {
          Header: 'External Company',
          accessor: 'Company',
          minWidth: 240
        },
        {
          Header: 'External Brand',
          accessor: 'Brand',
          minWidth: 220
        },
        {
          Header: 'SEGMENT',
          accessor: 'Segmentname',
          minWidth: 300,
          id: 'externalSegment'
        },
        // {
        //   Header: 'MACHINE_VS_HAND',
        //   accessor: 'Hiername',
        //   minWidth: 200,
        //   id:'machineVsHand'
        // },
        // {
        //   Header: 'SUBSEGMENT',
        //   accessor: 'SubSegment',
        //   minWidth: 200
        // },
        // {
        //   Header: 'TYPE',
        //   accessor: 'Type',
        //   minWidth: 200
        // },
        // {
        //   Header: 'FORM',
        //   accessor: 'Form',
        //   minWidth: 200
        // },
        // {
        //   Header: 'CORE_BENEFIT',
        //   accessor: 'CoreBenefit',
        //   minWidth: 200
        // },
        // {
        //   Header: 'TARGET_AREA',
        //   accessor: 'TargetArea',
        //   minWidth: 200
        // },
        // {
        //   Header: 'TARGET_USER',
        //   accessor: 'TargetUser',
        //   minWidth: 200
        // },
        // {
        //   Header: 'PACK_TYPE',
        //   accessor: 'ExternalPackType',
        //   minWidth: 200
        // },
        // {
        //   Header: 'PLATFORM',
        //   accessor: 'PlateForm',
        //   minWidth: 200
        // },

        // {
        //   Header: 'SUBFORMAT',
        //   accessor: 'SubFormat',
        //   minWidth: 200
        // }
      ]
    },
    {
      Header: 'Internal',
      minWidth: 500,
      columns: [
        {
          Header: 'Internal Product Description',
          accessor: 'Internaldesc',
          minWidth: 310,
        },
        {
          Header: 'Category Name',
          accessor: 'Categoryname',
          minWidth: 240
        },
        {
          Header: 'Market Name',
          accessor: 'Marketname',
          minWidth: 150
        },
        {
          Header: 'Corporate brand name',
          accessor: 'Corporatebrandname',
          minWidth: 180
        },
        {
          Header: 'Product Form Name',
          accessor: 'Productformmediumname',
          minWidth: 200
        },
        {
          Header: 'Sub Product Form Varient Name',
          accessor: 'Spfvname',
          minWidth: 350
        },
        {
          Header: 'Division Name',
          accessor: 'Divisionname',
          minWidth: 200
        },
        {
          Header: 'Sector Name',
          accessor: 'Sectorname',
          minWidth: 280
        },
        {
          Header: 'Segment Name',
          accessor: 'Segmentname',
          minWidth: 300,
          id: 'internalSegment'
        },
        {
          Header: 'Form Name',
          accessor: 'Formname',
          minWidth: 200
        },
        {
          Header: 'Sub Form Name',
          accessor: 'Subformname',
          minWidth: 200
        },
        {
          Header: 'Product Pack Form Name',
          accessor: 'Productpackformname',
          minWidth: 280
        },
        {
          Header: 'Product Pack Size Name',
          accessor: 'Productpacksizename',
          minWidth: 240
        },
        {
          Header: 'Product Variant Name',
          accessor: 'Productvariantname',
          minWidth: 240
        },
        {
          Header: 'Product Code Name',
          accessor: 'Productcodename',
          minWidth: 200
        }
        // {
        //   Header: 'Lifestage',
        //   accessor: 'LifeStage',
        //   minWidth: 200
        // },
        // {
        //   Header: 'Gender',
        //   accessor: 'Gender',
        //   minWidth: 200
        // },
        // {
        //   Header: 'Laundry Variants',
        //   accessor: 'LaundryVariants',
        //   minWidth: 200
        // },
        // {
        //   Header: 'Pack Type',
        //   accessor: 'InternalPackType',
        //   minWidth: 200
        // },
        // {
        //   Header: 'Format',
        //   accessor: 'Format',
        //   minWidth: 200
        // },
        // {
        //   Header: 'Fat Content',
        //   accessor: 'FatContent',
        //   mninwidth: 200
        // },
        // {
        //   Header: 'Benefit Claim',
        //   accessor: 'BenefitClaim',
        //   minWidth: 200
        // },
        // {
        //   Header: 'Target Claim',
        //   accessor: 'TargetClaim',
        //   minWidth: 200
        // },
        // {
        //   Header: 'Formation',
        //   accessor: 'Formation',
        //   minWidth:200
        // },
        // {
        //   Header:'If Antiperspirant',
        //   accessor:'IfAntiperspirant',
        //   minWidth:200
        // },
        // {
        //   Header:'If Concentrate',
        //   accessor:'IfConcentrate',
        //   minWidth:200
        // },
        // {
        //   Header:'If Rinse Off',
        //   accessor:'IfRinseOff',
        //   minWidth:200
        // },
        // {
        //   Header:'If High Suds',
        //   accessor:'IfHighSuds',
        //   minWidth:200
        // }
      ]
    },
    {
      Header: '',
      accessor: 'null',
      Cell: (row: any) => {
        return (
          <div
            onClick={() => {
              onRowClick(row);
            }}
            className='cursor-pointer'
            onKeyDown={() => {}}
            role='link'
            tabIndex={0}
          >
            <img src={ArrowRight} alt='img' width={20} height={20} />
          </div>
        );
      },
      minWidth: 30
    }
  ];
  const onRowClick = (row: any) => {
    const sidebarRoot = document.querySelector('.sidebar-root') as HTMLElement;
    sidebarRoot.style.setProperty('left', '0px');
    rowData(row.row.original);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const {
    fetchMappedData,
    mappedData,
    loading,
    pagination,
    paginationLoading,
    fetchMappedDataPagination,
    downloadExcelFile,
    buttonLoading
  } = ValidationService();

  //making null values in table to '-'
  mappedData.map((item: any) => {
    for (let key in item)
      if (item[key] === null) {
        item[key] = '-';
      }
  });

  const { id } = useParams();

  useEffect(() => {
    if (id && confidenceLevel) {
      fetchMappedData(id, confidenceLevel);
      fetchMappedDataPagination(id, confidenceLevel);
    }
  }, [id, confidenceLevel]);

  if (!id) return null;

  const handlePagination = (pageNumber: number, recordsPerPage?: number) => {
    const currentPageSize = pagination?.pageSize ?? 0;
    const limit = recordsPerPage ?? currentPageSize;
    const offset = (pageNumber - 1) * limit;
    if (id && confidenceLevel) {
      fetchMappedData(id, confidenceLevel, {
        limit,
        offset
      });
      fetchMappedDataPagination(id, confidenceLevel, {
        limit,
        offset
      });
    }
  };

  const handleDownload = () => {
    if (id && confidenceLevel && fileName)
      downloadExcelFile(ApiRoutes.DOWNLOAD_PRODUCT_EXCEL, id, {
        confidenceLevel,
        fileName
      });
  };

  return (
    <Container fluid className='container-mapped'>
      <Row>
        <ToastComponent showState={showA} toggleShow={CloseShowA} message='Issue raised' />
        {/* disabled={!isRaiseIssueButtonEnable} */}
        <Col sm={4}>
          <CustomButton disabled={!recordsCount} onClick={handleDownload}>
            {buttonLoading ? 'Downloading...' : 'Download File'}
          </CustomButton>
        </Col>
      </Row>
      <Row sm={12}>
        <div className='table-container'>{loading ? <Loader /> : <Table columns={columns} data={mappedData} tableFor='highMedium' />}</div>
        {paginationLoading ? (
          <SkeletonLoader />
        ) : (
          pagination && (
            <EDPagination
              page={pagination?.page ?? 0}
              pageSize={pagination?.pageSize ?? 0}
              totalCount={pagination?.totalCount ?? 0}
              onPageItemClick={handlePagination}
            />
          )
        )}
      </Row>
    </Container>
  );
};

export default Mapped;
