export const SideBarDropdownPeriod=[
    {
        attributeName:'TAG',
        external:'Tag',
        internal:null,
        apiEndPoint:'',
        dependsOn:[],
        apiDependsOn:[],
        value:null
    },
    {
        attributeName:'Short Description',
        external:'Short',
        internal:null,
        apiEndPoint:'',
        dependsOn:[],
        apiDependsOn:[],
        value:null
    },
    {
        attributeName:'Long Description',
        external:'Long',
        internal:null,
        apiEndPoint:'',
        dependsOn:[],
        apiDependsOn:[],
        value:null
    },
    {
        attributeName:'Last Period',
        external:'LastPeriodTagFormat',
        internal:null,
        apiEndPoint:'',
        dependsOn:[],
        apiDependsOn:[],
        value:null
    },
    {
        attributeName:'Unique Tag',
        external:'UniqueTag',
        internal:null,
        apiEndPoint:'',
        dependsOn:[],
        apiDependsOn:[],
        value:null
    },
    {
        attributeName:'Periodicity',
        external:'NA',
        internal:'Periodicity',
        apiEndPoint:'periodicity',
        dependsOn:[],
        apiDependsOn:[],
        value:null
    },
    {
        attributeName:'Year',
        external:'NA',
        internal:'YearBr',
        apiEndPoint:'year',
        dependsOn:['Periodicity'],
        apiDependsOn:['Periodicity'],
        value:null
    },
    {
        attributeName:'Quarter',
        external:'NA',
        internal:'QuarterBr',
        apiEndPoint:'quarter',
        dependsOn:['YearBr','Periodicity'],
        apiDependsOn:['YearBr','Periodicity'],
        value:null
    },
    {
        attributeName:'Month',
        external:'NA',
        internal:'MonthBr',
        apiEndPoint:'month',
        dependsOn:['QuarterBr','YearBr','Periodicity'],
        apiDependsOn:['QuarterBr','YearBr','Periodicity'],
        value:null
    },
    {
        attributeName:'Week',
        external:'NA',
        internal:'WeekBr',
        apiEndPoint:'week',
        dependsOn:['MonthBr','QuarterBr','YearBr','Periodicity'],
        apiDependsOn:['MonthBr','QuarterBr','YearBr','Periodicity'],
        value:null
    },
    {
        attributeName:'Period Start Date',
        external:'NA',
        internal:'PeriodStartDate',
        apiEndPoint:'start-date',
        dependsOn:['WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        apiDependsOn:['WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        value:null
    },
    {
        attributeName:'Period End Date',
        external:'NA',
        internal:'PeriodEndDate',
        apiEndPoint:'end-date',
        dependsOn:['PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        apiDependsOn:['PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        value:null
    },
    {
        attributeName:'Min Period Number',
        external:'NA',
        internal:'MinPeriodNumBr',
        apiEndPoint:'min-period-number',
        dependsOn:['PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        apiDependsOn:['PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        value:null
    },
    {
        attributeName:'Max Period Number',
        external:'NA',
        internal:'MaxPeriodNumBr',
        apiEndPoint:'max-period-number',
        dependsOn:['MinPeriodNumBr','PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        apiDependsOn:['MinPeriodNumBr','PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        value:null
    },
    {
        attributeName:'Week Start Day Country',
        external:'NA',
        internal:'WeekStartDayCountry',
        apiEndPoint:'country-week-start-day',
        dependsOn:['MaxPeriodNumBr','MinPeriodNumBr','PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        apiDependsOn:['MaxPeriodNumBr','MinPeriodNumBr','PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        value:null
    },
    {
        attributeName:'Periodicity Identifier',
        external:'NA',
        internal:'PeriodicityIdentifer',
        apiEndPoint:'periodicity-identifer',
        dependsOn:['WeekStartDayCountry','MaxPeriodNumBr','MinPeriodNumBr','PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        apiDependsOn:['WeekStartDayCountry','MaxPeriodNumBr','MinPeriodNumBr','PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        value:null
    },
    {
        attributeName:'Convention',
        external:'NA',
        internal:'Convention',
        apiEndPoint:'convention',
        dependsOn:['PeriodicityIdentifer','WeekStartDayCountry','MaxPeriodNumBr','MinPeriodNumBr','PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        apiDependsOn:['PeriodicityIdentifer','WeekStartDayCountry','MaxPeriodNumBr','MinPeriodNumBr','PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        value:null
    },
    {
        attributeName:'Period Number',
        external:'NA',
        internal:'PeriodNumberBr',
        apiEndPoint:'period-number',
        dependsOn:['Convention','PeriodicityIdentifer','WeekStartDayCountry','MaxPeriodNumBr','MinPeriodNumBr','PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        apiDependsOn:['Convention','PeriodicityIdentifer','WeekStartDayCountry','MaxPeriodNumBr','MinPeriodNumBr','PeriodEndDate','PeriodStartDate','WeekBr','MonthBr','QuarterBr','YearBr','Periodicity'],
        value:null
    }

]