export default {
    "result": [
        {
            "id": 1,
            "TAG": "P000000000000055184200000000000278416019",
            "UNILEVER_DESC": "UNILEVER PEPSODENT ACTION 123 SIWAK MULTI BENEFIT 1X110G 8999999557447",
            "VENDOR_DESC": "PEPSODENT ACTION 123 SIWAK 72X110G",
            "SKUCODE": "68140578",
            "CATEGORY": "Oral Care",
            "DIVISION": "Beauty & Personal Care",
            "CATEGORY_GROUP": "Personal Care",
            "SEGMENT": "Essential Toothpaste",
            "PRODUCT_FROM_MEDIUM_NAME": "LV9_CH1817",
            "MAPPED_STATUS": true,
            "createdAt": "2023-02-23T09:59:43.000Z",
            "updatedAt": "2023-02-23T09:59:43.000Z",
            "smart_mapping_list_id": 1
        },
        {
            "id": 2,
            "TAG": "P000000000000055184200000000000278417641",
            "UNILEVER_DESC": "LION WINGS SYSTEMA MENTHOL BREEZE MENTHOL BREEZE FRESHNESS 1X75G",
            "VENDOR_DESC": "BREEZE LEMON FRESHNESS SOAP 4X100g",
            "SKUCODE": "XAZX800",
            "CATEGORY": "Skin Cleansing",
            "DIVISION": "Beauty & Personal Care",
            "CATEGORY_GROUP": "Personal Care",
            "SEGMENT": "Skin Cleansing Bar",
            "PRODUCT_FROM_MEDIUM_NAME": "CH1997",
            "MAPPED_STATUS": true,
            "createdAt": "2023-02-23T09:59:43.000Z",
            "updatedAt": "2023-02-23T09:59:43.000Z",
            "smart_mapping_list_id": 1
        },
        {
            "id": 3,
            "TAG": "P000000000000055184200000000000278417399",
            "UNILEVER_DESC": "LION WINGS SYSTEMA MENTHOL BREEZE MENTHOL BREEZE FRESHNESS 12X75G",
            "VENDOR_DESC": "BREEZE LEMON FRESHNESS SOAP 4X100g",
            "SKUCODE": "XAZX800",
            "CATEGORY": "Skin Cleansing",
            "DIVISION": "Beauty & Personal Care",
            "CATEGORY_GROUP": "Personal Care",
            "SEGMENT": "Skin Cleansing Bar",
            "PRODUCT_FROM_MEDIUM_NAME": "CH1997",
            "MAPPED_STATUS": true,
            "createdAt": "2023-02-23T09:59:43.000Z",
            "updatedAt": "2023-02-23T09:59:43.000Z",
            "smart_mapping_list_id": 1
        }
    ],
    "page": 1,
    "page_size": 10,
    "total_count": 3
}