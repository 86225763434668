import React, { FC } from 'react';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import BreadcrumbComponent from 'shared/components/Breadcrumb-component/Breadcrumb-component';
import { useNavigate } from 'react-router';

export interface ValidationButtonDetails {
  active: boolean;
  onClick: () => void;
  title: string;
}

interface ValidationSummaryHeaderProps {
  buttonDetails: ValidationButtonDetails[];
}

const ValidationSummaryHeader: FC<ValidationSummaryHeaderProps> = (props) => {
  const navigate = useNavigate();
  const { buttonDetails } = props;

  return (
    <div className='validation-summary-header'>
      <Row className='page-header-row'>
        <Col sm={6} className='pl-0'>
          <div className='breadcrumb-container'>
            <p className='page-title'>Intelligent Mapping</p>
            <BreadcrumbComponent
              links={[
                { link: 'Checks', active: false, onClick: () => navigate(`/${AppRoutes.CHECKS}`, { replace: true }) },
                {
                  link: 'Intelligent Mapping',
                  active: true,
                  onClick: () => navigate(`/${AppRoutes.CHECKS}/${AppRoutes.VALIDATION_RECORDS}`, { replace: true })
                }
              ]}
            />
          </div>
        </Col>
      </Row>

      <div className='tab-container'>
        <ButtonGroup className='tab-button'>
          {buttonDetails.map((item) => (
            <Button
              key={item.title}
              variant='outline-primary'
              className={'button-outline' + (item.active ? ' active ' : '')}
              onClick={item.onClick}
            >
              {item.title}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default ValidationSummaryHeader;
