import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Tabs.scss'

interface content {
  key: string
  title: string | JSX.Element
  component: React.ReactNode
  disabled?: boolean
}

interface Props {
  components: content[]
  active: string
  onTabChange?: (key: string | null) => void
  mountOnEnter?: boolean
  unmountOnExit?: boolean
}

const TabComponent: React.FC<Props> = (props) => {

  const { components, active, onTabChange, mountOnEnter, unmountOnExit } = props

  return (
    <div>
      <Tabs
        defaultActiveKey={active}
        id='uncontrolled-tab-example'
        className='mb-3 tab-component'
        onSelect={onTabChange ? onTabChange : () => { }}
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
      >
        {
          components.map((component, index) =>
            <Tab
              disabled={component.disabled}
              key={index}
              className={index === components.length - 1 ? 'nav-link last-child' : 'nav-link'}
              eventKey={component.key}
              title={component.title}>
              {component.component}
            </Tab>
          )
        }
      </Tabs >
    </div >
  )
}

export default TabComponent;
