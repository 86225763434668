import React from 'react';
import moment from 'moment';
import { Column } from 'react-table';
import { displayDateTimeFormat } from 'constants/formats';

const columns: Column[] = [
  {
    Header: 'FileName',
    accessor: 'Filename'
  },
  {
    Header: 'Tag',
    accessor: 'Tag'
  },
  {
    Header: 'Externaldesc',
    accessor: 'Externaldesc',
    minWidth: 200
  },
  {
    Header: 'Created On',
    accessor: 'Createdon',
    Cell: ({ cell }: any) => (
      <div role='link' tabIndex={0}>
        {moment.utc(cell.value).format(displayDateTimeFormat)}
      </div>
    )
  },
  {
    Header: 'Remark',
    accessor: 'Remark',
    minWidth: 300
  }
];

export default columns;
