import React, { FC } from 'react';
import './validationFilters.scss';
import { Row, Col } from 'react-bootstrap';
import NewEDDropdown from 'shared/components/NewEDDropdown';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import { searchUtil } from 'shared/utils/searchUtil';
import { ProviderMeta } from 'models/ProviderMeta/providerMeta.model';
import { CountryMeta } from 'models/CountryMeta/countryMeta.model';
import EDDatePicker from 'shared/components/EDDatePicker';
import { ValidationFactFilters } from 'views/ValidationFlow/MarketDimension/Summary';

interface ValidationFiltersProps {
  providerMeta: ProviderMeta[];
  filterByProviderValue: string;
  filterByCategoryValue: string;
  updateFilters: (name: string, value: string) => void;
  categoryMeta: ProviderMeta[];
  loading?: boolean;
  filterByCountryValue: string;
  countryList: CountryMeta[];
  updateDateFilter: (startDate: string, endDate: string) => void;
  changedFilter: keyof ValidationFactFilters | undefined;
}

const ValidationFilters: FC<ValidationFiltersProps> = (props) => {
  const {
    providerMeta,
    filterByProviderValue,
    updateFilters,
    categoryMeta,
    filterByCategoryValue,
    loading,
    countryList,
    filterByCountryValue,
    updateDateFilter,
    changedFilter
  } = props;

  return (
    <Row className='mt-5 validation-filters'>
      <Col sm={12}>
        <div className='filters-container'>
          <div className='filter-container filter-container-same-line'>
            <NewEDDropdown
              loading={!changedFilter && loading}
              optionItems={providerMeta}
              value={filterByProviderValue}
              currentItemIndex={2}
              labelText='Data Provider'
              defaultValue='All(22)'
              updateValue={(value) => updateFilters('filter_by_provider', value)}
              resetIcon={true ? filterByProviderValue !== '' : false}
              resetFunc={() => updateFilters('filter_by_provider', '')}
            />
          </div>
          <div className='filter-container country-filter filter-container-same-line'>
            <NewEDDropdown
              loading={changedFilter ? changedFilter === 'filter_by_category' && loading : loading}
              optionItems={countryList ?? []}
              value={filterByCountryValue}
              currentItemIndex={2}
              labelText='Country'
              defaultValue='All(22)'
              updateValue={(value) => updateFilters('filter_by_country', value)}
              resetIcon={true ? filterByCountryValue !== '' : false}
              resetFunc={() => updateFilters('filter_by_country', '')}
            />
          </div>
          <div className='filter-container filter-container-same-line'>
            <NewEDDropdown
              loading={changedFilter ? changedFilter === 'filter_by_country' && loading : loading}
              optionItems={categoryMeta ?? []}
              value={filterByCategoryValue}
              currentItemIndex={2}
              labelText='Category'
              defaultValue='All(22)'
              resetIcon={true ? filterByCategoryValue !== '' : false}
              resetFunc={() => updateFilters('filter_by_category', '')}
              updateValue={(value) => updateFilters('filter_by_category', value)}
            />
          </div>
          <div className='filter-container filter-container-same-line'>
            <EDDatePicker onDateSelected={(startDate, endDate) => updateDateFilter(startDate, endDate)} />
          </div>
          <div className='search-inner filter-container-same-line'>
            <SearchInputField
              onChange={searchUtil((e: React.ChangeEvent<HTMLInputElement>) => updateFilters('search', e?.target?.value))}
              placeholder='Search...'
              width='auto'
              height='44px'
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ValidationFilters;
