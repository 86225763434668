import React from 'react'
import { Container, Col, Row} from 'react-bootstrap';
import './DataLogs.scss';
import StaticContent from 'assets/images/staticContent.svg';

const DataLogs = () => {
  return (
    <Container fluid className='data-logs-container'>
      <Row>
        <Col sm={10} className='content-col'>
        <div className='content-div'>
          <h2>About</h2>
          <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque 
          </p>
          
          </div>
          <div className='content-div'> 
          <h2>Features</h2>
          <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque 
          </p>
          </div>
        </Col>
        {/* <Col sm={6} className='image-content'>
          <img src={StaticContent} alt='static content' />
        </Col> */}
      </Row>
    </Container>
  )
}

export default DataLogs;