import React, { FC, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Arrow from 'assets/icons/CardArrowVector.svg';
import './fileHarmonizationStatus.scss';
import DashboardServiceService from 'services/DashboardServiceService/dashboardService.service';
import HarmonizedPieChart from './HarmonizedPieChart';
import Loader from 'shared/components/Loader';

interface FileHarmonizationStatusProps {
  startDate?: string;
  endDate?: string;
  country?: string;
}

const FileHarmonizationStatus: FC<FileHarmonizationStatusProps> = (props) => {
  const { fetchFileHarmonisationStatus, harmonisedStatus, loading } = DashboardServiceService();
  const { startDate, endDate, country } = props;

  useEffect(() => {
    fetchFileHarmonisationStatus();
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchFileHarmonisationStatus({
        start_date: startDate,
        end_date: endDate
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (country) {
      fetchFileHarmonisationStatus({
        filter_by_country: country
      });
    }
  }, [country]);

  return (
    <Container fluid className='file-harmonization-status card-container'>
      <Row>
        <div className='card-title-container d-flex justify-content-between'>
          <div className='heading-container'>
            <h3>Files Harmonization Status </h3>
            <p>Total Data providers: {harmonisedStatus.length}</p>
          </div>
          <img src={Arrow} alt='arrow-icon' />
        </div>
      </Row>

      {loading ? (
        <Loader />
      ) : (
        <Row className='pie-row'>
          {harmonisedStatus.map((item, index) => (
            <Col key={index} sm={4}>
              <HarmonizedPieChart providerName={item.providerName} fail={item.fail} success={item.success} process={item.inProgress} />
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default FileHarmonizationStatus;
