import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './FullScreenModal.scss';
import { Col, Row, Container } from 'react-bootstrap';
import TabComponent from '../Tab-component/Tabs';
import AboutSection from '../AboutSection';
import ToolsSection from '../ToolsSection';

function FullScreenModal() {
  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(true);
  }

  return (
    <>
      
        <Button  className="me-2 mb-2" onClick={() => handleShow()}>
          Full screen
        </Button>
      
      <Modal show={show} fullscreen={true}  onHide={() => setShow(false)} className='modal-container'>
        <Modal.Header closeButton className='modal-header'>
          {/* <Modal.Title>Modal</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <Row>
          <h2>Welcome To</h2>
          <h1>External Data Intelligent Transformation & Harmonization !!</h1>
          </Row>
          <Row>
          <TabComponent active='about' components={[{key:'about', title:'About',component:<AboutSection/>},{key:'tools', title:'Tools',component:<ToolsSection/>}]} />
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default FullScreenModal;