import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './UpdateTargetModal.scss';
import CustomButton from '../Button-component/Button';
import { Container, Row, Col } from 'react-bootstrap';
import DisabledGreenCheck from 'assets/icons/disabled-green-check.svg';
import EDTextInputs from '../EDTextInputs';
import { Mappings } from 'models/Mappings/mappings.model';
import deepClone from 'shared/utils/deepClone';
import VolatilityService from 'services/VolatilityService/volatility.service';
import { useParams } from 'react-router';
import ToastComponent from '../Toast-component/Toast-component';
import TargetColumnMappings from './TargetColumnMappings';


interface UpdateModalProps {
  show: boolean
  onSuccess: () => void
  optionItems: Mappings[]
  className?: string,
  mappingId: string,
  onHide: () => void
}

const UpdateTargetModal = ({ show, onSuccess, optionItems, mappingId, className, onHide, ...props }: UpdateModalProps) => {
  const [inputValue, setInputValue] = React.useState('');
  const [targetColumns, setTargetColumnss] = useState<Mappings[]>(optionItems);
  const [isColumnValid, setIsColumnValid] = useState<boolean>(false)

  const { updateTargetColumn } = VolatilityService()
  const { id }: any = useParams();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [submitButtonEnable, setSubmitButtonEnable] = useState<boolean>(false);

  useEffect(() => {
    // Add the logic for validation call  
    inputValue && validateNewColumn(inputValue)
    if (inputValue) {
      const validationStatus = validateNewColumn(inputValue)
      setIsColumnValid(validationStatus)
    }
  }, [inputValue])

  const addTargetColumn = () => {
    targetColumns.unshift({
      target: inputValue,
      source: ""
    });
    setTargetColumnss(deepClone(targetColumns));
    setIsColumnValid(false)
    setInputValue("");
  }

  const validateNewColumn = (value: string) => {
    const validLengthCheck = value.length > 1
    const existCheck = targetColumns.some((item) => item.target?.trim().toLowerCase() === value.trim().toLowerCase());
    return validLengthCheck && !existCheck
  }

  const updateTargetFields = (value: string, index: number) => {
    targetColumns[index].target = value
    setTargetColumnss(deepClone(targetColumns))
  }

  const handleModalSubmit = async () => {
    await updateTargetColumn(mappingId, targetColumns)
    onSuccess()
  }

  useEffect(() => {
    const status = targetColumns.some((item) => {
      const isEmpty = isFieldBlank(item.target || "")
      const isDuplicate = checkAlreadyExist(item.target)

      return isEmpty || isDuplicate
    })
    if (status) {
      setSubmitButtonEnable(false)
    } else {
      setSubmitButtonEnable(true)
    }
  }, [targetColumns])

  const isFieldBlank = (value: string) => {
    return value.length < 1
  }

  const checkAlreadyExist = (value: string | undefined) => {
    if (!value) return false
    const existCheck = targetColumns.filter((item) => item.target?.trim().toLowerCase() === value.trim().toLowerCase());
    return existCheck.length > 1
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      className='update-target-modal'
    >
      <Modal.Header closeButton onHide={onHide} className='header-container'>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className='modal-heading'>
            <p>Update Target</p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <ToastComponent status='Error' showState={errorMessage.length > 0} toggleShow={() => setErrorMessage("")} message={errorMessage} />
      <Modal.Body className="show-grid">
        <Container fluid >
          <div className='body-container'>
            <Row>
              <Col xs={6} md={5} className='left-container'>
                <div className='left-content'>
                  <h2>Add New</h2>
                  <p>To add a new item type in the box and click on tick mark.</p>
                </div>
                <div className='textinput-container'>
                  <Col sm={10}>
                    <EDTextInputs
                      placeholder='Type Here'
                      type='text'
                      value={inputValue}
                      onChange={(e: any) => setInputValue(e.target.value)}
                    />
                  </Col>
                  {isColumnValid
                    ? <div role='button'
                      onClick={addTargetColumn}
                      onKeyDown={addTargetColumn}
                      tabIndex={0}>
                      <img src={DisabledGreenCheck} alt='green check' className='enabled' />
                    </div>
                    : <div>
                      <img src={DisabledGreenCheck} alt='disabled green check' className='disabled' />
                    </div>
                  }
                </div>
              </Col>
              <Col xs={6} md={5} className='right-container'>
                <div className='right-content'>
                  <div>
                    <h2>Existing ({targetColumns.length})</h2>
                    <p className=''>To remove existing data, click on cross next to the data itself.</p>
                  </div>
                  <div className='list-item-container'>
                    {
                      targetColumns.map((item: Mappings, index: number) =>
                        <TargetColumnMappings
                          updateTargetFields={updateTargetFields}
                          mappingItem={item}
                          itemIndex={index}
                          isFieldBlank={isFieldBlank}
                          checkAlreadyExist={checkAlreadyExist}
                        />
                      )
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer className='modal-footer'>
        <CustomButton secondary onClick={onHide}>Cancel</CustomButton>
        <CustomButton
          primary={submitButtonEnable}
          disabled={!submitButtonEnable}
          onClick={handleModalSubmit}
        >Submit</CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateTargetModal