import { serializable, alias, object, list, primitive } from 'serializr';

export class SmartMappingList {
  @serializable(alias('Id', primitive()))
  id?: number;

  @serializable(alias('ExternalDataProvider', primitive()))
  dataProvider?: string;

  @serializable(alias('Category', primitive()))
  category?: string;

  @serializable(alias('Filename', primitive()))
  fileName?: string;

  @serializable(alias('Country', primitive()))
  country?: string;

  @serializable(alias('TotalRecords', primitive()))
  total?: number;

  @serializable(alias('TotalRecordsMappedBySME', primitive()))
  recordsmappedBySME?: number;

  @serializable(alias('TotalMappedRecords', primitive()))
  mappedRecordCount?: number;

  @serializable(alias('TotalUnprocessedRecords', primitive()))
  unmappedRecordCount?: number;

  @serializable(alias('High', primitive()))
  confidenceLevelLessThan70?: number;

  @serializable(alias('Medium', primitive()))
  confidenceLevel5070?: number;

  @serializable(alias('Low', primitive()))
  confidenceLevelLessThan50?: number;

  @serializable(alias('TotalUnprocessedRecords', primitive()))
  confidenceLevelUnprocessed?: number;

  @serializable(alias('CreatedOn', primitive()))
  createdAt?: string;
}
