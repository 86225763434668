export enum SkeletonLoaderAnimationTypes {
  WAVE = 'wave',
  GLOW = 'glow'
}

export enum SkeletonLoaderSizeTypes {
  EXTRA_SMALL = 'xs',
  SMALL = 'sm',
  LARGE = 'lg'
}
