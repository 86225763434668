export const SideBarDropDownMarket = [
  {
    attributeName: 'Country',
    external: 'Country',
    internal: null,
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: 'country',
    value: null
  },
  {
    attributeName: 'Category',
    external: 'Category',
    internal: null,
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: 'category',
    value: null
  },
  {
    attributeName: ' Market Description',
    external: 'Long',
    internal: null,
    apiEndPoint: '',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: ' Market Short Description',
    external: 'Short',
    internal: null,
    apiEndPoint: '',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: 'Hierarchy Name',
    external: 'HierName',
    internal: null,
    apiEndPoint: '',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: 'Hierarchy Level Name',
    external: 'HierLevelName',
    internal: null,
    apiEndPoint: '',
    dependsOn: [],
    apiDependsOn: [],
    value: null
  },
  {
    attributeName: 'Channel',
    external: 'NA',
    internal: 'Channel',
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: 'channel',
    value: null
  },
  {
    attributeName: 'Total Market',
    external: 'NA',
    internal: 'TotalMarket',
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: 'total-market',
    value: null
  }
];
