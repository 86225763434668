import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import './PieChartCard.scss';
import Arrow from 'assets/icons/CardArrowVector.svg';
import { Card, Stack } from 'react-bootstrap';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
const COLORS = ['#D9D9D9', '#09a75e', '#FF544F'];

interface Props {
  cardTitle: string
  data?: any
  footer1Heading?: string
  footer1Value?: number
  footer2Heading?: string
  footer2Value?: number
  footer3Heading?: string
  footer3Value?: number
  totalValue: number
  totalHeader?: string
}

const PieChartCard = ({ cardTitle, data, totalValue, footer1Heading, footer1Value, footer2Heading, footer2Value, footer3Heading, footer3Value, totalHeader }: Props) => {
  return (
    <div>
      <Row>
        <Col>
          <Card className='card-template'>
            <Card.Header className='card-template-header cursor-pointer'>
              <p className='card-template-heading'>{cardTitle}</p>
              <img alt='search icon' className='card-template-header-left-icon' src={Arrow} />
            </Card.Header>
            <Card.Body className='card-template-body'>
              <Stack direction='horizontal' className='card-template-stack'>
                <Card.Title className='card-template-title'>
                  <Stack direction='vertical'>
                    <div className='total-container'>
                      <div><h2>{totalHeader ?? 'Total'}</h2></div>
                      <div><p>{totalValue}</p></div>
                    </div>
                  </Stack>
                </Card.Title>
                <div className='chart-container'>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={50}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {data.map((entry: any, index: any) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </Stack>
            </Card.Body>
            <Card.Footer className='card-template-footer'>
              <Row>
                <Col xs={4} className='footer-item'>
                  <p>{footer1Heading}</p>
                  <p className={footer1Heading===('Rejected')?'red-flag':'' || 
                  footer1Heading===('Due')?'red-flag':'' || 
                  footer1Heading===('Completed')?'green-flag':''}>{footer1Value}</p>
                </Col>
                <Col xs={4} className='footer-item'>
                  <p>{footer2Heading}</p>
                  <p className={footer2Heading===('Approved')?'green-flag':'' ||
                footer2Heading===('Available')?'green-flag':''}>{footer2Value}</p>
                </Col>
                {
                  footer3Heading ? (
                    <Col className='footer-item'>
                      <p>{footer3Heading}</p>
                      <p>{footer3Value}</p>
                    </Col>
                  )
                    : null
                }

              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default PieChartCard

