import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import Cards from 'shared/components/Card-component/Cards';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import FileVolatility2 from 'assets/icons/file-volatility-card2.svg';
import FileVolatility3 from 'assets/icons/file-volatility-card3.svg';
import FileVolatility4 from 'assets/icons/file-volatility-card4.svg';
import './VolatilityHome.scss';

const VolatilityHome = () => {
  const navigate = useNavigate();

  const redirectToDQPage = () => {
    navigate(AppRoutes.DQ_CHECK);
  };

  return (
    <DashboardContainer>
      <div className='reports-page-content'>
        <Row>
          <Col xs={11} className='page-title-text'>
            <h1 className='page-title'>Reports</h1>
          </Col>
        </Row>

        <Row className='cards-container'>
          <Col xs={4}>
            <Cards
              heading='Data Quality'
              value={62}
              footer1Heading='Recent'
              footer1Value={10}
              footer2Heading='Old'
              footer2Value={60}
              image={FileVolatility2}
              cardHeaderClickHandler={redirectToDQPage}
            />
          </Col>
          <Col xs={4}>
            <Cards
              heading='Harmonised Data'
              value={1162}
              footer1Heading='Files'
              footer1Value={10}
              footer2Heading='Videos'
              footer2Value={60}
              image={FileVolatility3}
              // cardHeaderClickHandler={redirectToWIP}
            />
          </Col>
          <Col xs={4}>
            <Cards
              heading='Logs'
              value={119}
              footer1Heading='Excel'
              footer1Value={10}
              footer2Heading='Csv'
              footer2Value={60}
              footer3Heading='Word'
              footer3Value={2}
              image={FileVolatility4}
              // cardHeaderClickHandler={redirectToWIP}
            />
          </Col>
        </Row>
      </div>
    </DashboardContainer>
  );
};

export default VolatilityHome;
