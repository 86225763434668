import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from '../../routes/routeConstants/apiRoutes';
import { VolatilityDashboard } from 'models/VolatilityDashboard/VolatilityDashboard.model';
import { IndividualFileVolatility } from 'models/IndividualFileVolatility/IndividualFileVolatility.model';
import { Pagination } from 'models/Pagination/pagination.model';
import { generatePath } from 'react-router';
import VolatilityListDummyData from 'dummy/VolatilitList';
import deepClone from 'shared/utils/deepClone';
import { Mappings } from 'models/Mappings/mappings.model';
import { VolatilityFileMappings } from 'models/VolatilityFileMappings/volatilityFileMappings.model';
import { VolatilityFilters } from 'views/FileVolatility/VolatilityDashboard/LoadStatistics/LoadStatistics';

const VolatilityService = () => {
  // loading
  const [loading, setLoading] = useState<boolean>(false);

  //Pagination loading
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);

  // button loading
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  // Cards screen
  const [volatilityDashboardDetails, setVolatilityDashboardDetails] = useState<VolatilityDashboard>();

  // Volatility List
  const [VolatilityListData, setVolatilityListData] = useState(<IndividualFileVolatility[] | undefined>[]);

  // page size
  const [pageSize, setPageSize] = useState<number>(10);

  // Individual Volatility
  const [individualFileVolatilityData, setIndividualFileVolatilityData] = useState<IndividualFileVolatility>();

  // Column Mappings
  const [columnMappings, setColumnMappings] = useState<Mappings[]>();

  // Pagination
  const [pagination, setPagination] = useState<Pagination>();

  // Filtered data: search
  const [filteredData, setFilteredData] = useState<IndividualFileVolatility[]>([]);

  // Toggle status
  const [toggleAllStatus, setToggleAllStatus] = useState<boolean>(false);

  const [fileMappingData, setFileMappingData] = useState<VolatilityFileMappings>();

  const fetchVolatilityDashboard = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.VOLATILITY_DASHBOARD);
      const data = deserialize(VolatilityDashboard, response.data['data']) as VolatilityDashboard;
      setVolatilityDashboardDetails(data);
    } catch (error) {
      setVolatilityDashboardDetails(undefined);
    } finally {
      setLoading(false);
    }
  };
  // Get File Volatility List data
  const fetchVolatilityList = async (params?: VolatilityFilters, limit: number = 10, offset: number = 0) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.VOLATILITY_LIST, {
        params: {
          ...params,
          limit,
          offset
        }
      });
      const data = deserialize(IndividualFileVolatility, response.data['result']) as IndividualFileVolatility[];
      setVolatilityListData(data);
    } catch (error) {
      const response = VolatilityListDummyData;
      const data = deserialize(IndividualFileVolatility, response['result']) as IndividualFileVolatility[];

      setVolatilityListData(data);
      console.log('Error is ', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchVolatilityListPagination = async (params?: VolatilityFilters, limit: number = 10, offset: number = 0) => {
    try {
      setPaginationLoading(true);
      const response = await axiosInstance.get(ApiRoutes.VOLATILITY_LIST_COUNT, {
        params: {
          ...params,
          limit,
          offset
        }
      });

      const pagination = deserialize(Pagination, response.data) as Pagination;
      setPagination(pagination);
    } catch (error) {
      const response = VolatilityListDummyData;
      const pagination = deserialize(Pagination, response) as Pagination;
      setPagination(pagination);
      console.log('Error is ', error);
    } finally {
      setPaginationLoading(false);
    }
  };

  //Volatility Mapping detailed table data
  const fetchVolatilityDetailedTable = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.VOLATILITY_MAPPING_DETAILED);
      const data = deserialize(IndividualFileVolatility, response.data['result']) as IndividualFileVolatility[];
      setVolatilityListData(data);

      setVolatilityListData(data);
      const pagination = deserialize(Pagination, response.data) as Pagination;
      setPagination(pagination);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  };

  // Get individual file volatility data (LoadStatistics/mapping)
  const fetchIndividualFileVolatility = async (id: string) => {
    try {
      setLoading(true);
      const url = generatePath(ApiRoutes.INIDVIDUAL_FILE_VOLATILITY, { id });
      const response = await axiosInstance.get(url);
      const data = deserialize(IndividualFileVolatility, response.data) as IndividualFileVolatility;
      // const data = deserialize(VolatilityList, response.data) as VolatilityList
      setIndividualFileVolatilityData(data);
    } catch (error) {
      console.log('Error is ', error);
    } finally {
      setLoading(false);
    }
  };

  // Get mapping data (LoadStatistics/mapping)
  const fetchColumnMappings = async (
    id: string,
    params?: {
      limit?: number;
      offset?: number;
      search?: string;
      entity?: string;
    }
  ) => {
    try {
      setLoading(true);
      const url = generatePath(ApiRoutes.VOLATILITY_MAPPING, { id });
      const response = await axiosInstance.get(url, { params });
      setFileMappingData(deepClone(response.data) as VolatilityFileMappings);
    } catch (error) {
      console.log('Error is ', error);

      setColumnMappings(undefined);
    } finally {
      setLoading(false);
    }
  };

  // Toggle Checkbox
  const toggleMappedCheckStatus = (id: string | undefined) => {
    const updatedData = VolatilityListData?.map((item: any) => {
      if (item.id === id) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });
    setToggleAllStatus(false);
    setVolatilityListData(updatedData);
  };

  const toggleAllCheckbox = () => {
    const updatedData = VolatilityListData?.map((item: any) => {
      item.isSelected = !toggleAllStatus;
      return item;
    });
    setToggleAllStatus(!toggleAllStatus);
    setVolatilityListData(updatedData);
  };

  const resetAllCheckbox = () => {
    const updatedData = VolatilityListData?.map((item: any) => {
      item.isSelected = false;
      return item;
    });
    setToggleAllStatus(false);
    setVolatilityListData(updatedData);
  };

  const updateMappingInState = (name: string, index: number) => {
    const _tempMappings = columnMappings;
    if (!_tempMappings || !_tempMappings[index]) return null;
    _tempMappings[index].source = name;

    setColumnMappings(deepClone(_tempMappings));
  };

  const updateMappingValues = async (sourceColumn: string, criticalAttributes: string, fileId: number, id: string) => {
    try {
      setButtonLoading(true);

      const url = generatePath(ApiRoutes.VOLATILITY_MAPPING, { id });

      await axiosInstance.put(url, {
        SourceColumn: sourceColumn,
        criticalAttributes,
        Id: fileId
      });
    } catch (error) {
      console.log('Error is ', error);
    } finally {
      setButtonLoading(false);
    }
  };

  const updateTargetColumn = async (id: string, data: Mappings[]) => {
    try {
      setButtonLoading(true);

      data.sort((a, b) => {
        if (a.source === '' && b.source !== '') {
          return 1;
        } else if (a.source !== '' && b.source === '') {
          return -1;
        } else {
          return 0;
        }
      });

      const target = data.map((item) => item.target).join('|');
      const source = data.map((item) => item.source).join('|');
      const url = generatePath(ApiRoutes.UPDATE_TARGET_COLUMN, { id });
      await axiosInstance.post(url, {
        source,
        target
      });
    } catch (error) {
      console.log('Error is ', error);
    } finally {
      setButtonLoading(false);
    }
  };

  return {
    loading,
    buttonLoading,
    VolatilityListData,
    fetchVolatilityList,
    setVolatilityListData,
    fetchVolatilityDashboard,
    volatilityDashboardDetails,
    setVolatilityDashboardDetails,
    fetchIndividualFileVolatility,
    individualFileVolatilityData,
    setIndividualFileVolatilityData,
    fetchColumnMappings,
    columnMappings,
    pagination,
    pageSize,
    setPageSize,
    toggleMappedCheckStatus,
    toggleAllCheckbox,
    toggleAllStatus,
    filteredData,
    fileMappingData,
    updateMappingInState,
    resetAllCheckbox,
    updateMappingValues,
    updateTargetColumn,
    fetchVolatilityListPagination,
    paginationLoading
  };
};

export default VolatilityService;
