import moment from 'moment';
import React from 'react';
import Arrow from 'assets/icons/CardArrowVector.svg';
import { Cell, CellValue, Column, IdType, Row, RowPropGetter, TableRowProps, UseTableRowProps } from 'react-table';
import { SmartMappingList } from 'models/SmartMappingList/smartMappingList.model';
import { displayDateTimeFormat } from 'constants/formats';

export interface SmartMappingOriginalData extends UseTableRowProps<SmartMappingList> {
  cells: Array<Cell<SmartMappingList>>;
  allCells: Array<Cell<SmartMappingList>>;
  values: Record<IdType<SmartMappingList>, CellValue>;
  getRowProps: (propGetter?: RowPropGetter<SmartMappingList>) => TableRowProps;
  index: number;
  original: SmartMappingList;
  id: string;
  subRows: Array<Row<SmartMappingList>>;
}

export default (handleMapped: (cell: Cell, state?: string) => void, handleUnmapped: (cell: Cell) => void) => {
  const columns: Column[] = [
    {
      Header: 'Data Provider',
      accessor: 'dataProvider',
      minWidth: 160,
      columns: [
        {
          Header: '',
          accessor: 'dataProvider',
          minWidth: 160,
          Cell: (cell: Cell) => (
            <div
              onClick={() => handleMapped(cell)}
              onKeyDown={() => handleMapped(cell)}
              role='link'
              tabIndex={0}
              className='cursor-pointer'
            >
              {cell.value}
            </div>
          )
        }
      ]
    },
    {
      Header: 'Country',
      accessor: 'country',
      minWidth: 180,
      columns: [
        {
          Header: '',
          accessor: 'country',
          minWidth: 180,
          Cell: (cell: Cell) => (
            <div
              onClick={() => handleMapped(cell)}
              onKeyDown={() => handleMapped(cell)}
              role='link'
              tabIndex={0}
              className='cursor-pointer'
            >
              {cell.value}
            </div>
          )
        }
      ]
    },
    {
      Header: 'External Category',
      accessor: 'category',
      minWidth: 180,
      columns: [
        {
          Header: '',
          accessor: 'category',
          minWidth: 180,
          Cell: (cell: Cell) => (
            <div
              onClick={() => handleMapped(cell)}
              onKeyDown={() => handleMapped(cell)}
              role='link'
              tabIndex={0}
              className='cursor-pointer'
            >
              {cell.value}
            </div>
          )
        }
      ]
    },
    {
      Header: 'Cell Database',
      minWidth: 320,
      columns: [
        {
          Header: '',
          accessor: 'data',
          minWidth: 320,
          Cell: (cell: any) => {
            return (
              <div
                onClick={() => handleMapped(cell)}
                onKeyDown={() => handleMapped(cell)}
                role='link'
                tabIndex={0}
                className='cursor-pointer'
              >
                {cell.row.original?.fileName} {cell.row.original?.category}
              </div>
            );
          }
        }
      ]
    },
    {
      Header: 'Filename',
      accessor: 'fileName',
      minWidth: 200,
      columns: [
        {
          Header: '',
          accessor: 'fileName',
          minWidth: 380,
          // Cell: (cell: Cell) => <div role="link" tabIndex={0} className='cell-hyperlink'>{cell.value}</div>
          Cell: (cell: any) => {
            return (
              <div
                onClick={() => handleMapped(cell)}
                onKeyDown={() => handleMapped(cell)}
                role='link'
                tabIndex={0}
                className='cell-hyperlink'
              >
                {cell.value}
              </div>
            );
          }
        }
      ]
    },
    {
      Header: 'Loaded On',
      accessor: 'createdAt',
      minWidth: 180,
      columns: [
        {
          Header: '',
          accessor: 'createdAt',
          minWidth: 160,
          Cell: ({ value }) => moment.utc(value).format(displayDateTimeFormat)
        }
      ]
    },
    {
      Header: 'Records',
      minWidth: 600,
      columns: [
        {
          Header: 'Mapped',
          accessor: 'mappedRecordCount',
          minWidth: 100,
          Cell: (cell: Cell) => (
            <div onClick={() => handleMapped(cell)} onKeyDown={() => handleMapped(cell)} role='link' tabIndex={0}>
              {cell.value}
            </div>
          )
        },
        {
          Header: 'Unprocessed',
          accessor: 'unmappedRecordCount',
          minWidth: 100,
          Cell: (cell: Cell) => (
            <div
              onClick={() => handleUnmapped(cell)}
              onKeyDown={() => handleUnmapped(cell)}
              role='link'
              tabIndex={0}
              className='cell-hyperlink'
            >
              {cell.value ?? 0}
            </div>
          )
        },
        {
          Header: 'Mapped by SME',
          accessor: 'recordsmappedBySME',
          minWidth: 200,
          Cell: (cell: Cell) => (
            <div role='link' tabIndex={0}>
              {cell.value}
            </div>
          )
        },
        {
          Header: 'Total',
          accessor: 'total',
          minWidth: 100,
          Cell: (cell: Cell) => (
            <div role='link' tabIndex={0}>
              {cell.value}
            </div>
          )
        },
        {
          Header: 'Success Status(%)',
          minWidth: 200,
          accessor: 'successStatus',
          Cell: (cell: Cell) => {
            const row: SmartMappingOriginalData = cell.row;
            const original = row.original;
            const mappedCount = original.mappedRecordCount ?? 0;
            const total = original.total ?? 0;
            const successStatus = ((mappedCount / total) * 100).toFixed(0) + '%';
            return (
              <div
                onClick={() => handleMapped(cell)}
                onKeyDown={() => handleMapped(cell)}
                role='link'
                tabIndex={0}
                className='cursor-pointer'
              >
                {successStatus}
              </div>
            );
          }
        }
      ]
    },
    {
      Header: 'Confidence Level of Mapped Records',
      minWidth: 300,
      columns: [
        {
          Header: 'High',
          accessor: 'confidenceLevelLessThan70',
          minWidth: 80,
          Cell: (cell: Cell) => (
            <div
              onClick={() => handleMapped(cell)}
              onKeyDown={() => handleMapped(cell)}
              role='link'
              className='cursor-pointer cell-hyperlink'
              tabIndex={0}
            >
              {cell.value ?? 0}
            </div>
          )
        },
        {
          Header: 'Medium',
          accessor: 'confidenceLevel5070',
          minWidth: 100,
          Cell: (cell: Cell) => (
            <div
              onClick={() => handleMapped(cell, 'medium')}
              onKeyDown={() => handleMapped(cell, 'medium')}
              role='link'
              className='cursor-pointer cell-hyperlink'
              tabIndex={0}
            >
              {cell.value ?? 0}
            </div>
          )
        },
        {
          Header: 'Low',
          accessor: 'confidenceLevelLessThan50',
          minWidth: 80,
          Cell: (cell: Cell) => (
            <div
              onClick={() => handleMapped(cell, 'low')}
              onKeyDown={() => handleMapped(cell, 'low')}
              role='link'
              className='cursor-pointer cell-hyperlink'
              tabIndex={0}
            >
              {cell.value ?? 0}
            </div>
          )
        },
        {
          Header: '',
          id: 'icon',
          Cell: (cell: Cell) => (
            <div
              onClick={() => handleMapped(cell)}
              onKeyDown={() => handleMapped(cell)}
              role='link'
              tabIndex={0}
              className='cell-hyperlink'
            >
              <img src={Arrow} alt='alt' />
            </div>
          )
        }
      ]
    }
  ];

  return columns;
};
