
import { serializable, alias, object, list, primitive } from 'serializr';

export class MappingPeriodDimension {

	@serializable(alias('Id', primitive()))
	id?: number;

	@serializable(alias('FileName', primitive()))
	FileName?: string;

	@serializable(alias('LastPeriodTagFormat', primitive()))
	LastPeriodTagFormat?: string;

	@serializable(alias('Short', primitive()))
	Short?: string;

	@serializable(alias('Tag', primitive()))
	Tag?: string;

	@serializable(alias('Country', primitive()))
	Country?: string;

	@serializable(alias('Category', primitive()))
	Category?: string;

	@serializable(alias('Long', primitive()))
	Long?: string;

	@serializable(alias('Periodicity', primitive()))
	Periodicity?: string;

	@serializable(alias('PeriodicityIdentifer', primitive()))
	PeriodicityIdentifer?: string;

	@serializable(alias('Convention', primitive()))
	Convention?: string;

	@serializable(alias('YearBr', primitive()))
	YearBr?: string;

	@serializable(alias('WeekBr', primitive()))
	WeekBr?: string;

	@serializable(alias('MonthBr', primitive()))
	MonthBr?: string;

	@serializable(alias('QuarterBr', primitive()))
	QuarterBr?: string;

	@serializable(alias('MinPeriodNumBr', primitive()))
	MinPeriodNumBr?: string;

	@serializable(alias('MaxPeriodNumBr', primitive()))
	MaxPeriodNumBr?: string;

	@serializable(alias('PeriodNumberBr', primitive()))
	PeriodNumberBr?: string;

	@serializable(alias('PeriodStartDate', primitive()))
	PeriodStartDate?: string;

	@serializable(alias('PeriodEndDate', primitive()))
	PeriodEndDate?: string;

	@serializable(alias('WeekStartDayCountry', primitive()))
	WeekStartDayCountry?: string;

	@serializable(alias('UniqueTag', primitive()))
	UniqueTag?: string;

	@serializable(primitive())
	isSelected?: boolean;

}