export const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,

  //MS Graph API
  MS_GRAPH_BASE_URL: 'https://graph.microsoft.com/v1.0/',
  MS_GRAPH_USER_DETAILS: 'me',
  MS_GRAPH_USER_PHOTO: 'me/photo/$value',

  LIBRARY: 'library',
  VALIDATION_DASHBOARD: 'smart-mapping/dashboard/',
  SMART_MAPPING_LIST: 'smart-mapping',
  SMART_MAPPING_LIST_COUNT: 'smart-mapping/count',
  INDIVIDUAL_SMART_MAPPING: 'smart-mapping/:id',
  SMART_MAPPING_MAPPED: 'smart-mapping/:id/:key',
  SMART_MAPPING_MAPPED_COUNT: 'smart-mapping/:id/:key/count',
  SMART_MAPPING_UN_MAPPED: 'smart-mapping/:id/low',
  SMART_MAPPING_UN_PROCESSED_RECORDS: 'smart-mapping/:id/product/unprocessed',
  SMART_MAPPING_UN_PROCESSED_FACT: 'smart-mapping/fact/details/unprocessed',
  SMART_MAPPING_UN_PROCESSED_FACT_COUNT: 'smart-mapping/fact/details/unprocessed/count',
  SMART_MAPPING_UN_PROCESSED_MARKET: 'smart-mapping/:id/market/unprocessed',
  SMART_MAPPING_UN_PROCESSED_RECORDS_COUNT: 'smart-mapping/:id/product/unprocessed/count',
  SMART_MAPPING_UN_PROCESSED_MARKET_COUNT: 'smart-mapping/:id/market/unprocessed/count',
  SMART_MAPPING_UN_PROCESSED_RECORDS_DOWNLOAD: 'smart-mapping/:id/unprocessed/download',
  SMART_MAPPING_LOW_RECORD_SUGGESTIONS: 'smart-mapping/:id/suggestion',
  COUNTRY_META: 'meta/country',
  PROVIDER_META: 'meta/provider',
  CATEGORY_META: 'meta/category',
  VOLATILITY_LIST: 'file-volatility',
  VOLATILITY_LIST_COUNT: 'file-volatility/count',
  VOLATILITY_DASHBOARD: 'file-volatility/dashboard/',
  INIDVIDUAL_FILE_VOLATILITY: 'file-volatility/:id',
  VOLATILITY_MAPPING: 'file-volatility/:id/mappings',
  VOLATILITY_MAPPING_DETAILED: 'file-volatility/:id/details',
  VOLATILITY_COUNTRY_META: 'smart-mapping/meta/country',
  VOLATILITY_PROVIDER_META: 'smart-mapping/meta/provider',
  VOLATILITY_CATEGORY_META: 'smart-mapping/meta/category',
  PROFILES: 'auth/profile',
  DASHBOARD_VOLATILITY_METRICS: 'dashboard/volatility-file-metrics',
  DASHBOARD_FILE_HARMONISATION: 'dashboard/file-harmonization-status',
  DASHBOARD_CONFIDENCE_MAPPING: 'dashboard/confidence-level',
  UPDATE_TARGET_COLUMN: 'file-volatility/:id/target-column',
  HOME_PROVIDER_META: 'dashboard/meta/provider',

  //fact routes
  VALIDATION_FACT: 'smart-mapping/fact/summary',
  VALIDATION_FACT_COUNT: 'smart-mapping/fact/summary/count',
  CATEGORY_FACT: 'smart-mapping/fact/meta/category',
  COUNTRY_FACT: 'smart-mapping/fact/meta/country',
  PROVIDER_FACT: 'smart-mapping/fact/meta/provider',
  VALIDATION_FACT_DETAIL: 'smart-mapping/fact/details',
  VALIDATION_FACT_DETAIL_COUNT: 'smart-mapping/fact/details/count',
  FILE_VOLATILITY_MAPPING_FACT: 'file-volatility/fact',
  FILE_VOLATILITY_MAPPING_PERIOD: 'file-volatility/period',
  FACT_FILE_DETAIL: 'smart-mapping/fact/',
  FILE_VOLATILITY_MAPPING_FACT_LOW: 'smart-mapping/fact/details/low-map', //external description fetch,
  VALIDATION_FACT_DETAIL_UPDATE: 'smart-mapping/fact/details/low',
  UPDATE_FV_VALUES: 'file-volatility',

  // Period
  VALIDATION_PERIOD_MAPPED_DETAILS: 'smart-mapping/:id/period/mapped',
  VALIDATION_PERIOD_MAPPED_DETAILS_COUNT: 'smart-mapping/:id/period/mapped/count',
  VALIDATION_MARKET_MAPPED_DETAILS: 'smart-mapping/:id/market/:confidenceLevel',
  VALIDATION_MARKET_MAPPED_DETAILS_COUNT: 'smart-mapping/:id/market/:confidenceLevel/count',

  // sideBar remapping
  FACT_DROPDOWN: 'remapping/fact/',
  PRODUCT_DROPDOWN: 'remapping/product/',
  Market_DROPDOWN: 'remapping/market/',
  PERIOD_DROPDOWN: 'remapping/period/',

  // Dq checks
  DQ_CHECKS: 'dq-checks',
  DQ_CHECKS_COUNT: 'dq-checks/count',
  DQ_CHECKS_DELIVERY_PERIOD_META: 'dq-checks/meta/delivery-period',
  DQ_CHECKS_DELIVERY_COUNTRY_META: 'dq-checks/meta/country',
  DQ_CHECKS_DELIVERY_PROVIDER_META: 'dq-checks/meta/data-provider',
  DQ_CHECKS_DELIVERY_CATEGORY_META: 'dq-checks/meta/category',
  DQ_CHECKS_TOTAL_CELL: 'dq-checks/card/total-cells',
  DQ_CHECKS_TOTAL_FILES: 'dq-checks/card/total-files',
  DQ_CHECKS_FV_SUCCESS_RATE: 'dq-checks/card/fv-success-rate',
  DQ_CHECKS_AVERAGE_FV_ERROR: 'dq-checks/card/average-fv-error',
  DQ_CHECKS_DQ_SCORE: 'dq-checks/card/dq-score',
  DQ_CHECKS_TOTAL_DQ_SCORE: 'dq-checks/card/total-dq-score',
  DQ_CHECKS_REPORT: 'dq-checks/report',

  //Download
  DOWNLOAD_PRODUCT_EXCEL: 'smart-mapping/:id/download/product',
  DOWNLOAD_FACT_EXCEL: 'smart-mapping/:id/download/fact',
  DOWNLOAD_MARKET_EXCEL: 'smart-mapping/:id/download/market',
  DOWNLOAD_PERIOD_EXCEL: 'smart-mapping/:id/download/period'
};
