import React, { FC, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import BreadcrumbComponent from 'shared/components/Breadcrumb-component/Breadcrumb-component';
import DashboardContainer from 'shared/components/DashboardContainer-component';
import Databar from 'shared/components/Databar-component';
import TabComponent from 'shared/components/Tab-component/Tabs';
import { useNavigate, useParams } from 'react-router';
import ValidationService from 'services/ValidationService/validation.service';
import moment from 'moment';
import './periodDimensionDetails.scss';
import WorkInProgress from 'shared/components/WorkInProgress';
import PeriodMappedDetails from './PeriodMappedDetails';
import Loader from 'shared/components/Loader';
import Sidebar from 'react-sidebar';
import SidebarHighMedium from '../SidebarHighMedium-component/SidebarHighMedium';
import RedirectLeft from 'assets/icons/redirect-left.svg';
import RedirectRight from 'assets/icons/redirect-right.svg';
import { displayDateTimeFormat } from 'constants/formats';

interface PeriodDimensionDetailsProps {}

const PeriodDimensionDetails: FC<PeriodDimensionDetailsProps> = () => {
  const [rowData, setRowData] = useState();
  const navigate = useNavigate();
  const { confidenceLevel, id } = useParams();

  const { fetchIndividualSmartMapping, smartMapping } = ValidationService();

  useEffect(() => {
    id && fetchIndividualSmartMapping(id);
  }, []);

  const onTabSelect = (key: string | null) => {
    if (!key) return;
    navigate(
      {
        pathname: `../period/${id}/${key}`
      },
      { replace: true }
    );
  };
  const setRow = (value: any) => {
    const sidebarRoot = document.querySelector('.sidebar-root') as HTMLElement;
    sidebarRoot.style.setProperty('left', '0px');
    setRowData(value);
  };
  return (
    <DashboardContainer className='period-dimension-details'>
      {!smartMapping ? (
        <Loader />
      ) : (
        <Container fluid className='page-container-details'>
          <Row className='breadcrumb-container'>
            <Col sm={12}>
              <p className='page-heading'>Intelligent Mapping - Period Detail</p>
              <BreadcrumbComponent
                links={[
                  {
                    link: 'Period',
                    active: false,
                    onClick: () =>
                      navigate(`/${AppRoutes.CHECKS}/${AppRoutes.VALIDATION}/${AppRoutes.VALIDATION_PERIOD}`, { replace: true })
                  },
                  { link: 'Detail', active: true }
                ]}
              />
            </Col>
            {/* Filename heading */}
            <Row>
              <div className='file-name-row'>
                <div className='file-change-btn'>
                  <img src={RedirectLeft} alt='left-arrow' />
                </div>
                <p>Filename:</p>
                <span className='hyper-link'>{smartMapping.fileName}</span>
                <div className='file-change-btn'>
                  <img src={RedirectRight} alt='right-arrow' />
                </div>
              </div>
            </Row>
          </Row>
          <Row className='databar-container'>
            <Databar
              DataProvider={smartMapping.dataProvider ?? ''}
              TotalRecords={smartMapping.total ?? 0}
              successStatus={((smartMapping.mappedRecordCount ?? 0) / (smartMapping.total ?? 0)) * 100}
              loadedOn={moment.utc(smartMapping.createdAt).format(displayDateTimeFormat)}
            />
          </Row>
          <Row className='tabs-container'>
            <TabComponent
              mountOnEnter
              unmountOnExit
              active={confidenceLevel as string}
              onTabChange={onTabSelect}
              components={[
                {
                  key: 'mapped',
                  title: 'Mapped',
                  component: (
                    <>
                      <PeriodMappedDetails
                        rowData={setRow}
                        fileName={smartMapping.fileName}
                        recordsCount={smartMapping.mappedRecordCount}
                      />
                      {confidenceLevel === 'mapped' && (
                        <Sidebar
                          open={true}
                          pullRight={true}
                          styles={{
                            root: { position: 'absolute', left: '9999px' },
                            sidebar: { zIndex: '5' },
                            content: { position: 'relative' },
                            overlay: { zIndex: '0', position: 'relative' }
                          }}
                          sidebarClassName='sidebar'
                          rootClassName='sidebar-root'
                          sidebar={rowData ? <SidebarHighMedium row={rowData} /> : <></>}
                          transitions={true}
                        />
                      )}
                    </>
                  )
                },
                {
                  key: 'unprocessed',
                  title: 'Unprocessed',
                  component: (
                    <>
                      <WorkInProgress />
                      {confidenceLevel === 'medium' && (
                        <Sidebar
                          open={true}
                          pullRight={true}
                          styles={{
                            root: { position: 'absolute', left: '9999px' },
                            sidebar: { zIndex: '5' },
                            content: { position: 'relative' },
                            overlay: { zIndex: '0', position: 'relative' }
                          }}
                          sidebarClassName='sidebar'
                          rootClassName='sidebar-root'
                          sidebar={rowData ? <SidebarHighMedium row={rowData} /> : <></>}
                          transitions={true}
                        />
                      )}
                    </>
                  )
                }
              ]}
            />
          </Row>
        </Container>
      )}
    </DashboardContainer>
  );
};

export default PeriodDimensionDetails;
