import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Profile } from '../../models/Profile/profile.model';
import { useCookies } from 'react-cookie';
import { useMsal } from '@azure/msal-react';
import msalClientInstance from 'interceptor/msalClientInstance';
import { ResponseTypes } from 'enums/responseTypes';

const ProfileService = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [profile, setProfile] = useState<Profile | null>();

  const [userPhoto, setUserPhoto] = useState<string>();

  const [cookies] = useCookies();

  const fetchProfiles = async (params?: { search?: string; page?: number }) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.PROFILES, { params });
      const data = deserialize(Profile, response.data['profiles']) as Profile;
      setProfile(data);
    } catch (error) {
      setProfile(undefined);
    } finally {
      setLoading(false);
    }
  };

  const createProfile = async (payload: { avatar: string | undefined; role: string | undefined }) => {
    try {
      if (!payload.role) return;
      if (!payload.avatar) return;
      setButtonLoading(true);
      await axiosInstance.post(ApiRoutes.PROFILES, payload, {
        headers: {
          Cookie: 'authToken=' + cookies.authToken,
          Authorization: `Bearer ${cookies.authToken}`
        }
      });
    } catch (error) {
      setProfile(null);
    } finally {
      setButtonLoading(false);
    }
  };

  const getUserName = () => {
    const { accounts } = useMsal();
    if (accounts?.length) return accounts[0].name;
    else return '';
  };

  const getUserPhoto = async () => {
    try {
      setLoading(true);
      const response = await msalClientInstance.get(ApiRoutes.MS_GRAPH_USER_PHOTO, { responseType: ResponseTypes.BLOB });
      const imageUrl = URL.createObjectURL(response.data);
      setUserPhoto(imageUrl);
    } catch (error) {
      setUserPhoto(undefined);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    profile,
    buttonLoading,
    createProfile,
    fetchProfiles,
    getUserName,
    getUserPhoto,
    userPhoto
  };
};

export default ProfileService;
