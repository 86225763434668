import React, { useEffect, useState } from 'react';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import Loader from 'shared/components/Loader';
import { Container } from 'react-bootstrap';
import 'shared/components/Breadcrumb-component/Breadcrumb-component.scss';
import { useNavigate } from 'react-router';
import EDPagination from 'shared/components/Pagination';
import { Row, Col } from 'react-bootstrap';
import columns from './columns';
import Table from 'shared/components/Table-component/Table-component';
import ValidationService from 'services/ValidationService/validation.service';
import ValidationFilters from 'views/ValidationFlow/PeriodDimension/Summary/ValidationFilters';
import ValidationSummaryHeader from 'views/ValidationFlow/PeriodDimension/Summary/ValidationSummaryHeader';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import '../../PeriodDimension/Summary/ValidationSummary.scss';
import './ValidationMarketSummary.scss';
import MetaService from 'services/MetaService/meta.service';
import { MetaData } from 'models/MetaData/metaData.model';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import { Order } from 'enums/order';

export interface ValidationFactFilters {
  search: string;
  filter_by_provider: string;
  filter_by_category: string;
  filter_by_country: string;
  filter_by_status: string;
  start_date: string;
  end_date: string;
  filter_by_dimension: string;
  orderValue?: string;
}

const ValidationMarketSummary: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<ValidationFactFilters>({
    search: '',
    filter_by_provider: '',
    filter_by_category: '',
    filter_by_country: '',
    filter_by_status: '',
    start_date: '',
    end_date: '',
    filter_by_dimension: 'Market',
    orderValue: Order.DESC
  });

  const navigateDetailPage = (cell: any, status?: string) => {
    if (!status) status = 'mapped';

    navigate({
      pathname: `${cell?.row?.original?.id}/${status}`
    });
  };

  const { fetchSmartMappingList, loading, smartMappingList, pagination, fetchSmartMappingListPagination, paginationLoading } =
    ValidationService();

  const { loading: metaLoading, fetchMeta } = MetaService();

  const [countryMeta, setCountryMeta] = useState<MetaData[]>([]);

  const [categoryMeta, setCategoryMeta] = useState<MetaData[]>([]);

  const [providerMeta, setProviderMeta] = useState<MetaData[]>([]);

  const [changedFilter, setChangedFilter] = useState<keyof ValidationFactFilters>();

  useEffect(() => {
    handleFetchMeta();
  }, []);

  useEffect(() => {
    if (filters) {
      fetchSmartMappingList(filters);
      fetchSmartMappingListPagination(filters);
    }
  }, [filters]);

  const updateFilters = (filterName: string, value: string) => {
    setFilters({
      ...filters,
      [filterName]: value
    });

    switch (filterName) {
      case 'filter_by_category':
        setChangedFilter(filterName);
        fetchMeta(ApiRoutes.VOLATILITY_COUNTRY_META, setCountryMeta, MetaData, { category: String(value) });
        break;
      case 'filter_by_country':
        setChangedFilter(filterName);
        fetchMeta(ApiRoutes.VOLATILITY_CATEGORY_META, setCategoryMeta, MetaData, { country: String(value) });
        break;
    }
  };

  const updateDateFilter = (startDate: string, endDate: string) => {
    setFilters({
      ...filters,
      start_date: startDate,
      end_date: endDate
    });
  };

  const handlePagination = (pageNumber: number, recordsPerPage?: number) => {
    const currentPageSize = pagination?.pageSize ?? 0;
    const limit = recordsPerPage ?? currentPageSize;
    const offset = (pageNumber - 1) * limit;
    fetchSmartMappingList(filters, limit, offset);
    fetchSmartMappingListPagination(filters, limit, offset);
  };

  const handleFetchMeta = () => {
    const params = {
      filter_by_dimension: 'Market'
    };

    fetchMeta(ApiRoutes.VOLATILITY_COUNTRY_META, setCountryMeta, MetaData, params);
    fetchMeta(ApiRoutes.VOLATILITY_CATEGORY_META, setCategoryMeta, MetaData, params);
    fetchMeta(ApiRoutes.VOLATILITY_PROVIDER_META, setProviderMeta, MetaData, params);
  };

  const tableColumns = columns(navigateDetailPage);

  const buttonDetails = [
    {
      active: false,
      onClick: () => {
        navigate({ pathname: `../../${AppRoutes.VALIDATION_RECORDS}` });
      },
      title: 'Product'
    },
    {
      active: false,
      onClick: () => {
        navigate({ pathname: `../../${AppRoutes.VALIDATION}/fact` });
      },
      title: 'Fact'
    },
    {
      active: true,
      onClick: () => {
        navigate({ pathname: `../../${AppRoutes.VALIDATION}/market` });
      },
      title: 'Market'
    },
    {
      active: false,
      onClick: () => {
        navigate({ pathname: `../../${AppRoutes.VALIDATION}/period` });
      },
      title: 'Period'
    }
  ];

  return (
    <DashboardContainer>
      <Container fluid className='validation-summary validation-period-summary p-0'>
        <ValidationSummaryHeader buttonDetails={buttonDetails} />

        <ValidationFilters
          providerMeta={providerMeta}
          filterByProviderValue={filters.filter_by_provider}
          filterByCategoryValue={filters.filter_by_category}
          categoryMeta={categoryMeta}
          updateFilters={updateFilters}
          filterByCountryValue={filters.filter_by_country}
          countryList={countryMeta}
          updateDateFilter={updateDateFilter}
          loading={metaLoading}
          changedFilter={changedFilter}
        />

        <Row>
          <Col xs={12}>
            <div className='table-container table-container-scroll mt-5'>
              {loading ? <Loader /> : <Table columns={tableColumns} data={smartMappingList} />}
            </div>
            {paginationLoading ? (
              <SkeletonLoader />
            ) : (
              pagination && (
                <EDPagination
                  page={pagination?.page ?? 0}
                  pageSize={pagination?.pageSize ?? 0}
                  totalCount={pagination?.totalCount ?? 0}
                  onPageItemClick={handlePagination}
                />
              )
            )}
          </Col>
        </Row>
      </Container>
    </DashboardContainer>
  );
};

export default ValidationMarketSummary;
