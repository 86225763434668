import React, { FC } from 'react'
import { Mappings } from 'models/Mappings/mappings.model'
import EDTextInputs from 'shared/components/EDTextInputs'
import "./targetColumnMappings.scss"

interface TargetColumnMappingsProps {
  updateTargetFields: (value: string, index: number) => void,
  mappingItem: Mappings,
  itemIndex: number,
  isFieldBlank: (value: string) => boolean,
  checkAlreadyExist: (value: string | undefined) => boolean
}

const TargetColumnMappings: FC<TargetColumnMappingsProps> = (props) => {

  const { updateTargetFields, mappingItem, itemIndex, isFieldBlank, checkAlreadyExist } = props

  const isEmpty = isFieldBlank(mappingItem.target || "")
  const isDuplicate = checkAlreadyExist(mappingItem.target)
  const errorExist = isEmpty || isDuplicate

  return (
    <>
      <div className='list-item-parent target-column-mappings'>
        <div key={mappingItem.key} className={`list-item  ${!mappingItem.id ? 'new-target' : ''} ${errorExist ? ' error ' : ''} `}>
          <EDTextInputs
            borderless type='text'
            value={mappingItem.target}
            placeholder=''
            onChange={(e: any) => updateTargetFields(e.target.value, itemIndex)}
          />
        </div>
      </div>
      {
        isEmpty
          ? <span className='error-message'>Field is blank</span>
          : null
      }
      {
        isDuplicate
          ? <span className='error-message'>Duplicate value</span>
          : null
      }
    </>
  )
}

export default TargetColumnMappings