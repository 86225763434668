import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './App.css';
import { msalInstance } from './authConfig';
import { MsalProvider } from '@azure/msal-react';

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </MsalProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
