import React, { useEffect, useState } from 'react';
import CustomButton from 'shared/components/Button-component/Button';
import { Container, Row, Col, ButtonGroup, Button, Form } from 'react-bootstrap';
import BreadcrumbComponent from 'shared/components/Breadcrumb-component/Breadcrumb-component';
import { useLocation, useNavigate } from 'react-router';
import RedirectLeft from 'assets/icons/redirect-left.svg';
import RedirectRight from 'assets/icons/redirect-right.svg';
import Download from 'assets/icons/download-icon.svg';
import Upload from 'assets/icons/upload.svg';
import UpdateIcon from 'assets/icons/update-icon.svg';
import LeftArrow from 'assets/icons/left-arrow.svg';
import Error from 'assets/icons/error.svg';
import VolatilityService from 'services/VolatilityService/volatility.service';
import { Mappings } from 'models/Mappings/mappings.model';
import Loader from 'shared/components/Loader';
import SearchDropdown from 'shared/components/SearchDropdown';
import moment from 'moment';
import './ColumnMappingView.scss';
import { useParams } from 'react-router';
import ToastComponent from 'shared/components/Toast-component/Toast-component';
import RemarksModal from 'shared/components/remarks-modal/RemarksModal';
import UpdateTargetModal from 'shared/components/update-target-modal/UpdateTargetModal';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import { VolatilityFileMappings } from 'models/VolatilityFileMappings/volatilityFileMappings.model';
import deepClone from 'shared/utils/deepClone';
import { createSearchParams } from 'react-router-dom';
import { displayDateTimeFormat } from 'constants/formats';
import { ChangeEvent } from 'react';
import MarkedIcon from './../../../../assets/icons/marked.svg';
import UnMarkedIcon from './../../../../assets/icons/unmarked.svg';

interface optionItemsInterface {
  key: string;
  value: string;
}

interface ColumnMappingViewProps {
  data: VolatilityFileMappings;
  fetchColumnMappings: (
    id: string,
    params?: {
      entity?: string;
    }
  ) => void;
  entity: string;
  loading: boolean;
}

const ColumnMappingView = (props: ColumnMappingViewProps) => {
  const queryP = new URLSearchParams(useLocation().search);
  const fileName = queryP.get('FileName');
  const { fetchIndividualFileVolatility, individualFileVolatilityData, updateMappingValues, buttonLoading } = VolatilityService();
  const [modalShow, setModalShow] = React.useState(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [targetToast, setTargetToast] = useState<boolean>(false);
  const [UpdateModalShow, setUpdateModalShow] = React.useState(false);
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const navigate = useNavigate();
  const { id }: any = useParams();

  const [result, setResult] = useState<Mappings[]>([]);
  const [dropdownValue, setDropdownValue] = useState<any>();
  const [selectedSource, setSelectedSource] = useState<(string | undefined)[]>([]);
  const [selectedCriticals, setSelectedCriticals] = useState<(string | undefined)[]>([]);

  useEffect(() => {
    id && fetchIndividualFileVolatility(id);
  }, []);

  const { data, fetchColumnMappings, entity, loading } = props;

  useEffect(() => {
    const Source = data['SourceColumn'] ? data.SourceColumn.split('|') : [];
    const Target = data['TargetColumn'] ? data.TargetColumn.split('|') : [];
    const criticality = data['CriticalAttributes_Flag'] ? data.CriticalAttributes_Flag.split('|') : [];

    const result = Source.map((item: string, index: number) => ({
      source: item,
      target: Target[index],
      id: index + 1,
      criticality: criticality[index] === '1' ? '1' : '0'
    }));
    const dropdownValue = result.map((item: any) => item.source);

    setResult(result);
    setDropdownValue(dropdownValue);
  }, [data]);

  const handleResultChange = () => {
    const source = result.map((data) => data.source);
    const criticals = result.map((data) => data.criticality);
    setSelectedSource(source);
    setSelectedCriticals(criticals);
    setEnableSubmitButton(true);
  };

  useEffect(() => {
    //for each change in result object update both source and critical attributes
    handleResultChange();
  }, [result]);

  function handleModalShow() {
    setModalShow(true);
  }
  function handleUpdateModalShow() {
    setUpdateModalShow(true);
    console.log('modal state: ', UpdateModalShow);
  }

  if (!result) return <Loader />;
  if (!dropdownValue) return <Loader />;

  const menuItems1 = [
    {
      key: 'Data Provider',
      value: individualFileVolatilityData?.SOURCE
    },
    {
      key: 'Country',
      value: individualFileVolatilityData?.COUNTRY
    },
    {
      key: 'Loaded On',
      value: moment.utc(individualFileVolatilityData?.LOADSTARTTIME).format(displayDateTimeFormat)
    }
  ];

  const menuItems2 = [
    {
      key: 'Status',
      value: individualFileVolatilityData?.PIPELINESTATUS
    },
    {
      key: '',
      value: ''
    },
    {
      key: 'Category',
      value: individualFileVolatilityData?.CATEGORY
    }
  ];

  const totalCOlumns = result?.length;
  const errorColumns: Mappings[] | undefined = result?.filter((item: any) => item.source === '');
  const errorColumnLength = errorColumns ? errorColumns?.length : 0;

  const optionItems: optionItemsInterface[] = result?.map((item: any, index: number) => {
    return {
      key: index.toString(),
      value: item.target ?? ''
    };
  });

  const sourceColumnList = data['SourceColumnList'] ? data.SourceColumnList.split('|') : [];

  const uniqueArray = sourceColumnList.map((item) => {
    return {
      key: item,
      value: item
    };
  });

  const targetColumns: Mappings[] = result;

  const updateTarget = (value: any, index: any) => {
    result[index]['source'] = value;
    setResult(result);
    // getting dropdown values and pushing it to an array
    const arr: any = [];
    result.map((item: any) => {
      arr.push(item.source);
    });
    setSelectedSource(arr);
    const newDropdownValues = [...dropdownValue];
    newDropdownValues[index] = value;
    setDropdownValue(value); // update the state variable with the new value
  };

  const updateColumnValues = async () => {
    const updateValues = {
      ZipFileName: data.ZipFileName,
      FileName: data.FileName,
      Entity: data.Entity,
      SourceColumn: selectedSource
    };
    const sourceColumn: string = selectedSource.join('|');
    const criticalAttributes: string = selectedCriticals.join('|');

    if (data.Id === undefined) return;
    updateMappingValues(sourceColumn, criticalAttributes, data.Id ?? '', id).then(() => {
      setEnableSubmitButton(false);
      toggleToast();
    });
  };

  const toggleToast = () => {
    setShowToast(!showToast);
  };

  let statusClassName = '';

  switch (individualFileVolatilityData?.PIPELINESTATUS) {
    case 'In progress':
      statusClassName = 'progress';
      break;

    case 'Success':
      statusClassName = 'success';
      break;

    case 'Failure':
      statusClassName = 'failed';
      break;
  }

  const handleCrtiticalAttrChange = (index: number, value: string) => () => {
    const oldData = [...result];
    oldData[index]['criticality'] = value === '1' ? '0' : '1';
    setResult(oldData);
  };

  return (
    <Container fluid className='volatility-mapping'>
      <RemarksModal show={modalShow} onHide={() => setModalShow(false)} className='remarks-modal' />
      {UpdateModalShow && (
        <UpdateTargetModal
          show={UpdateModalShow}
          onSuccess={() => {
            fetchColumnMappings(id, { entity });
            setTargetToast(true);
            setUpdateModalShow(false);
          }}
          onHide={() => {
            setUpdateModalShow(false);
          }}
          className='remarks-modal'
          optionItems={deepClone(targetColumns)}
          mappingId={data['Id']?.toString() ?? ''}
        />
      )}
      <ToastComponent showState={showToast} toggleShow={toggleToast} message='Source Data Updated' />
      <ToastComponent showState={targetToast} toggleShow={() => setTargetToast(false)} message='Target Values Updated' />
      <Row className='top-row'>
        <Col sm={12} className='breadcrumb-container'>
          <div className='page-header'>
            <p className='page-heading'>Mapping</p>
            <BreadcrumbComponent
              links={[
                { link: 'Load Statistics', active: false, onClick: () => navigate('/checks/file-volatility') },
                { link: 'Mapping', active: true }
              ]}
              section
            />
          </div>
        </Col>

        {/* Filename heading */}
        <Row>
          <div className='file-name-row'>
            <div className='file-change-btn'>
              <img src={RedirectLeft} alt='left-arrow' />
            </div>
            <p>Filename:</p>
            <span className='hyper-link'>{data.FileName}</span>
            <div className='file-change-btn'>
              <img src={RedirectRight} alt='right-arrow' />
            </div>
          </div>
        </Row>
        {/* Menu */}
        <Row className='menu-row'>
          <Col sm={4} className='left-container'>
            {menuItems1.map((item, index) => {
              return (
                <div key={index} className='item'>
                  <h2>{item.key}: </h2>
                  <p>{item.value}</p>
                </div>
              );
            })}
          </Col>
          <Col sm={4} className='left-container'>
            {menuItems2.map((item, index) => {
              return (
                <div key={index} className='item'>
                  <h2>
                    {item.key} {item.key ? ':' : ''}{' '}
                  </h2>
                  {item.value === 'Failed' ? (
                    <p className='failed-tag small-text'>{item.value}</p>
                  ) : item.value === 'Successful' ? (
                    <p className='success-tag'>{item.value}</p>
                  ) : (
                    <p>{item.value}</p>
                  )}
                </div>
              );
            })}
          </Col>
          {/* Button: Excel and Remarks */}
          <Col sm={4} className='right-container'>
            <div className='excel-button'>
              <p>Excel:</p>
              <div className='arrow-button'>
                <img src={Download} alt='download' />
              </div>
              <div className='arrow-button'>
                <img src={Upload} alt='upload' />
              </div>
            </div>
            <div
              className='remarks-button'
              role='button'
              onClick={() => handleModalShow()}
              onKeyDown={() => handleModalShow()}
              tabIndex={0}
            >
              <p>
                <img src={LeftArrow} alt='left-arrow' />
              </p>
              <div className='remarks-text'>Remarks</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <ButtonGroup aria-label='Product'>
              <Button
                variant='outline-primary'
                className={'' + (entity === 'Product' ? 'button-primary' : 'button-outline')}
                onClick={() => {
                  if (entity === 'Product') return;
                  navigate({
                    pathname: `../mapping/${id}`,
                    search: createSearchParams({
                      FileName: fileName as string
                    }).toString()
                  });
                }}
              >
                Product
              </Button>
              <Button
                variant='outline-primary'
                className={'' + (entity === 'Fact' ? 'button-primary' : 'button-outline')}
                onClick={() => {
                  if (entity === 'Fact') return;
                  navigate({
                    pathname: `../mapping/${id}/${AppRoutes.VALIDATION_FACT}`,
                    search: createSearchParams({
                      FileName: fileName as string
                    }).toString()
                  });
                }}
              >
                Fact
              </Button>
              <Button
                variant='outline-primary'
                className={'' + (entity === 'Market' ? 'button-primary' : 'button-outline')}
                onClick={() => {
                  if (entity === 'Market') return;
                  navigate({
                    pathname: `../mapping/${id}/${AppRoutes.VALIDATION_MARKET}`,
                    search: createSearchParams({
                      FileName: fileName as string
                    }).toString()
                  });
                }}
              >
                Market
              </Button>
              <Button
                variant='outline-primary'
                className={'' + (entity === 'Period' ? 'button-primary' : 'button-outline')}
                onClick={() => {
                  if (entity === 'Period') return;
                  navigate({
                    pathname: `../mapping/${id}/${AppRoutes.VALIDATION_PERIOD}`,
                    search: createSearchParams({
                      FileName: fileName as string
                    }).toString()
                  });
                }}
              >
                Period
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row className='individual-file-info mt-5'>
          <Col sm={4}>
            <p>
              File Name: <span className='fileName'>{data.FileName}</span>
            </p>
          </Col>
          <Col sm={2}>
            <div className='status'>
              <p>Status</p>
              <p className={'status-tags ml-2 ' + statusClassName}>{individualFileVolatilityData?.PIPELINESTATUS}</p>
            </div>
          </Col>
        </Row>

        {loading ? (
          <Loader />
        ) : (
          <Row className='table-container'>
            <Col sm={6} className='left-table-container'>
              <Row className='header'>
                <div>
                  <Col className='source-header'>
                    <div>Source ({individualFileVolatilityData?.SOURCE})</div>
                  </Col>
                  <Col className='second' sm={8}>
                    <div className='col-number'>{totalCOlumns} columns</div>
                    <div className='error'>
                      <img src={Error} alt='error' />
                      {errorColumnLength} Errors
                    </div>
                  </Col>
                </div>
              </Row>
            </Col>
            <Col sm={6} className='right-table-container'>
              <div>
                <p className='target-header'>Target (CPG database)</p>
                {result.length > 0 ? (
                  <div
                    className='update-button'
                    role='button'
                    onClick={() => handleUpdateModalShow()}
                    onKeyDown={() => handleUpdateModalShow()}
                    tabIndex={0}
                  >
                    <img src={UpdateIcon} alt='update' />
                    <div className='update-action'>Update</div>
                  </div>
                ) : null}
                <div className='critical'>
                  <span className='mark-as-critical'>|</span>
                  <img src={MarkedIcon} alt='marked as critical' />
                  <p className='mark-as-critical-title'>Mark as critical</p>
                </div>
              </div>
            </Col>

            <Row className='table-content'>
              <Col sm={12} className='left-col'>
                {result?.map((item: any, index: any) => (
                  <Row className='row-item' key={index}>
                    <Col sm={6} className='p-0'>
                      <div className={'item ' + (item.source ? '' : 'error-tag')}>
                        <SearchDropdown
                          optionItems={uniqueArray ?? []}
                          value={item.source ?? '-'}
                          currentItemIndex={index}
                          updateTargetValue={(name, index) => {
                            updateTarget(name, index);
                            setEnableSubmitButton(true);
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm={6} className='right-col'>
                      <div className='item justify-content-between '>
                        <div>
                          <p className='target-item'>{item.target}</p>
                        </div>
                        <button className='critical-icon' onClick={handleCrtiticalAttrChange(index, item.criticality)}>
                          {item.criticality === '1' ? (
                            <img src={MarkedIcon} alt='marked as critical' />
                          ) : (
                            <img src={UnMarkedIcon} alt='marked as not critical' />
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                ))}
                {result.length === 0 ? <h3 className='text-center m-4'>No Mappings are found</h3> : null}
              </Col>

              <div className='last-row-btns'>
                <div className='right-btns'>
                  <div>
                    <CustomButton disabled={!enableSubmitButton} onClick={updateColumnValues}>
                      {buttonLoading ? 'Submitting...' : 'Submit'}
                    </CustomButton>
                  </div>
                  <div>
                    <CustomButton
                      secondary
                      onClick={() => {
                        navigate('/checks/file-volatility');
                      }}
                    >
                      Cancel
                    </CustomButton>
                  </div>
                </div>
              </div>
            </Row>
          </Row>
        )}
      </Row>
    </Container>
  );
};

export default ColumnMappingView;
