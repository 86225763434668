import React, { FC } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Arrow from 'assets/icons/CardArrowVector.svg';

import "./taskUpdates.scss"

interface TaskUpdatesProps { }

const TaskUpdates: FC<TaskUpdatesProps> = (props) => {

    const { } = props

    const tasksDue = [
        {
            title: 'Total files received ',
            count: 200
        },
        {
            title: 'Files pending for approval ',
            count: 120,
            status: 'Approaching'

        },
        {
            title: 'Total manual update ',
            count: 200
        }
    ]

    return (
        <Container fluid className='task-updates graphs-card'>
            <Row className='tasks-header'>
                <div className='heading-container d-flex justify-content-between'>
                    <h3>Updates</h3>
                    <img src={Arrow} alt='arrow-icon' />
                </div>
            </Row>
            <Row className='task-list-container'>
                <Col sm={12}>
                    {tasksDue.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between task-container'>
                            <span className='task-name'>
                                {item.title}
                            </span>
                            <span className='task-count'>
                                {item.count}
                            </span>
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}

export default TaskUpdates