import React, { Fragment } from 'react';
import './Sidebar.scss';
import UniliverLogo from 'assets/icons/uniliver-logo.svg';
import EdithLogo from 'assets/icons/edith-logo.svg';
import HomeWhite from 'assets/icons/home-white.svg';
import Home from 'assets/icons/home.svg';
import TasksWhite from 'assets/icons/tasks-white.svg';
import ReportsWhite from 'assets/icons/reports-white.svg';
import Reports from 'assets/icons/reports.svg';
import imgSrc from 'assets/icons/menu-stack.svg';
import RecordsWhite from 'assets/icons/records-white.svg';
import ChecksWhite from 'assets/icons/checks-white.svg';
import Checks from 'assets/icons/checks.svg';
import NotificationsWhite from 'assets/icons/notification-white.svg';
import ClockImg from 'assets/icons/clock.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { displayDateTimeFormat } from 'constants/formats';
// import clockImg from 'assets/icons/clock.svg';
const Sidebar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (url: string) => {
    navigate(`/${url}`);
  };
  const menuItems = [
    {
      name: 'Summary',
      icon: HomeWhite,
      active: false,
      iconActive: Home,
      url: 'home',
      id:0
    },
    // {
    //   name: 'Leadership Home',
    //   icon: HomeWhite,
    //   active: false,
    //   iconActive: Home,
    //   url: 'leadership',
    //   id:1
    // },
    /* Commented for future use */
    // {
    //   name: 'Tasks',
    //   icon: TasksWhite,
    //   active: false,
    //   iconActive: 'assets/icons/tasks.svg',
    //   url: 'tasks'
    // },
    {
      name: 'Reports',
      icon: ReportsWhite,
      active: false,
      iconActive: Reports,
      url: 'reports',
      id:3
    },
    {
      name: 'Reviews',
      icon: ChecksWhite,
      active: true,
      iconActive: Checks,
      url: 'checks'
    }
    /* Commented for future use */
    // {
    //   name: 'Records',
    //   icon: RecordsWhite,
    //   active: false,
    //   iconActive: 'assets/icons/records.svg',
    //   url: 'records'
    // },
    // {
    //   name: 'Notifications',
    //   icon: NotificationsWhite,
    //   active: false,
    //   iconActive: 'assets/icons/notifications.svg',
    //   url: 'notifications'
    // }
  ];
  return (
    // sidebar container
    <div className='sidebar-container'>
      {/* header */}
      <div className='sidebar-header'>
        {/* comment for future use */}
        {/* <img src={imgSrc} alt='menu' /> */}
        {/* <img src={UniliverLogo} alt='menu' /> */}
        {/* <img src={EdithLogo} alt='menu' className='sidebar-header-edith-icon' /> */}
        <h3 style={{color: 'white', marginTop: '1rem'}}>Dataverse</h3>
      </div>
      {/* menu items */}
      <div className='sidebar-menu-container'>
        {/* Each item - menu */}
        {menuItems.map((item,index) => {
          if (location.pathname.split('/').includes(item.url)) {
            return (
              <Fragment key={index}>
                <div key={item.id} className='active-container active'>
                  <div className='sidebar-menu-item active'>
                    <div className='icon'>
                      <img src={item.iconActive} alt='icon' />
                    </div>
                    <div className='text'>
                      <p>{item.name}</p>
                    </div>
                    {/* <div className='marker'/>  */}
                  </div>
                </div>

              </Fragment>
            )
          } else {
            return (
              <Fragment key={index}>
                <div key={item.id} className='active-container' role='button' onClick={() => handleNavigation(item.url)} onKeyDown={() => handleNavigation(item.url)} tabIndex={0} >
                <div className='sidebar-menu-item'>
                  <div className='icon'>
                    <img src={item.icon} alt='icon' />
                  </div>
                  <div className='text'>
                    <p className='url-link'>{item.name}</p>
                  </div>
                </div>
              </div>
              </Fragment>
            );
          }
        })}
      </div>
      <div className='currentlogin-container'>
        <div className='rowDiv'>
          <img src={ClockImg} alt='clock' />

          <div className='text-container'>
            <p>Current Login</p>
            <h2>{moment().format(displayDateTimeFormat)}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
