import React, { useEffect, useState } from 'react';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import Table from 'shared/components/Table-component/Table-component';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import ValidationService from 'services/ValidationService/validation.service';
import Loader from 'shared/components/Loader';
import { searchUtil } from 'shared/utils/searchUtil';
import { Button, ButtonGroup, Container } from 'react-bootstrap';
import 'shared/components/Breadcrumb-component/Breadcrumb-component.scss';
import './ValidationDashboard.scss';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import EDPagination from 'shared/components/Pagination';
import BreadcrumbComponent from 'shared/components/Breadcrumb-component/Breadcrumb-component';
import EDDatePicker from 'shared/components/EDDatePicker';
import NewEDDropdown from 'shared/components/NewEDDropdown';
import { Row, Col } from 'react-bootstrap';
import columns from './columns';
import MetaService from 'services/MetaService/meta.service';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { MetaData } from 'models/MetaData/metaData.model';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import { Order } from 'enums/order';

export interface ValidationFilters {
  search: string;
  filter_by_provider: string;
  filter_by_category: string;
  filter_by_country: string;
  filter_by_status: string;
  start_date: string;
  end_date: string;
  orderValue?: string;
}

const ValidationDashboard: React.FC = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState<ValidationFilters>({
    search: '',
    filter_by_provider: '',
    filter_by_category: '',
    filter_by_country: '',
    filter_by_status: '',
    start_date: '',
    end_date: '',
    orderValue: Order.DESC
  });

  const redirectToHighTab = (cell: any, state?: string) => {
    if (!state) state = 'high';
    navigate(`validation-record-details/${cell?.row?.original?.id}/${state}`);
  };
  const redirectToUnprocessedTab = (cell: any) => {
    navigate(`validation-record-details/${cell?.row?.original?.id}/unprocessed`);
  };

  const {
    fetchSmartMappingList,
    loading,
    smartMappingList,
    pageSize,
    setPageSize,
    pagination,
    fetchSmartMappingListPagination,
    paginationLoading
  } = ValidationService();

  const { loading: metaLoading, fetchMeta } = MetaService();

  const [countryMeta, setCountryMeta] = useState<MetaData[]>([]);

  const [categoryMeta, setCategoryMeta] = useState<MetaData[]>([]);

  const [providerMeta, setProviderMeta] = useState<MetaData[]>([]);

  const [changedFilter, setChangedFilter] = useState<keyof ValidationFilters>();

  useEffect(() => {
    handleFetchMeta();
  }, []);

  useEffect(() => {
    if (filters) {
      fetchSmartMappingList(filters);
      fetchSmartMappingListPagination(filters);
    }
  }, [filters]);

  const updateFilters = (filterName: keyof ValidationFilters, value: string) => {
    setFilters({
      ...filters,
      [filterName]: value
    });

    switch (filterName) {
      case 'filter_by_category':
        setChangedFilter(filterName);
        fetchMeta(ApiRoutes.VOLATILITY_COUNTRY_META, setCountryMeta, MetaData, { category: String(value) });
        break;
      case 'filter_by_country':
        setChangedFilter(filterName);
        fetchMeta(ApiRoutes.VOLATILITY_CATEGORY_META, setCategoryMeta, MetaData, { country: String(value) });
        break;
    }
  };

  const updateDateFilter = (startDate: string, endDate: string) => {
    setFilters({
      ...filters,
      start_date: startDate,
      end_date: endDate
    });
  };

  const handlePagination = (pageNumber: number, recordsPerPage?: number) => {
    const currentPageSize = pagination?.pageSize ?? 0;
    const limit = recordsPerPage ?? currentPageSize;
    const offset = (pageNumber - 1) * limit;
    fetchSmartMappingList(filters, limit, offset);
    fetchSmartMappingListPagination(filters, limit, offset);
  };

  const tableColumns = columns(redirectToHighTab, redirectToUnprocessedTab);

  const handleFetchMeta = () => {
    fetchMeta(ApiRoutes.VOLATILITY_COUNTRY_META, setCountryMeta, MetaData);
    fetchMeta(ApiRoutes.VOLATILITY_CATEGORY_META, setCategoryMeta, MetaData);
    fetchMeta(ApiRoutes.VOLATILITY_PROVIDER_META, setProviderMeta, MetaData);
  };

  return (
    <DashboardContainer>
      <Container fluid className='validation-dashboard'>
        <Row className='top-row'>
          <Col sm={6} className='pl-0'>
            <div className='breadcrumb-container'>
              <p className='page-heading'>Intelligent Mapping</p>
              <BreadcrumbComponent
                links={[
                  { link: 'Checks', active: false, onClick: () => navigate(`/${AppRoutes.CHECKS}`, { replace: true }) },
                  {
                    link: 'Intelligent Mapping',
                    active: true,
                    onClick: () => navigate(`/${AppRoutes.CHECKS}/${AppRoutes.VALIDATION_RECORDS}`, { replace: true })
                  }
                ]}
              />
            </div>
          </Col>
        </Row>

        <div className='tab-container'>
          <ButtonGroup aria-label='Basic example'>
            <Button variant='primary' className='button-primary active'>
              Product
            </Button>
            <Button
              variant='outline-primary'
              className='button-outline'
              onClick={() => {
                navigate({ pathname: `../${AppRoutes.VALIDATION}/fact` });
              }}
            >
              Fact
            </Button>
            <Button
              variant='outline-primary'
              className='button-outline'
              onClick={() => {
                navigate({ pathname: `../${AppRoutes.VALIDATION}/${AppRoutes.VALIDATION_MARKET}` });
              }}
            >
              Market
            </Button>
            <Button
              variant='outline-primary'
              className='button-outline'
              onClick={() => {
                navigate({ pathname: `../${AppRoutes.VALIDATION}/${AppRoutes.VALIDATION_PERIOD}` });
              }}
            >
              Period
            </Button>
          </ButtonGroup>
        </div>

        <Row className='mt-5'>
          <Col sm={12}>
            <div className='filters-container-second'>
              <div className='filter-container filter-container-same-line'>
                <NewEDDropdown
                  optionItems={providerMeta}
                  loading={!changedFilter && metaLoading}
                  value={filters.filter_by_provider}
                  currentItemIndex={2}
                  resetIcon={true ? filters.filter_by_provider !== '' : false}
                  resetFunc={() => updateFilters('filter_by_provider', '')}
                  labelText='Data Provider'
                  defaultValue='All(22)'
                  updateValue={(value) => updateFilters('filter_by_provider', value)}
                />
              </div>
              <div className='filter-container filter-container-same-line'>
                <NewEDDropdown
                  optionItems={countryMeta}
                  loading={changedFilter ? changedFilter === 'filter_by_category' && metaLoading : metaLoading}
                  value={filters.filter_by_country}
                  resetIcon={true ? filters.filter_by_country !== '' : false}
                  resetFunc={() => updateFilters('filter_by_country', '')}
                  currentItemIndex={2}
                  labelText='Country'
                  defaultValue='All(22)'
                  updateValue={(value) => updateFilters('filter_by_country', value)}
                />
              </div>
              <div className='filter-container filter-container-same-line'>
                <NewEDDropdown
                  optionItems={categoryMeta}
                  loading={changedFilter ? changedFilter === 'filter_by_country' && metaLoading : metaLoading}
                  value={filters.filter_by_category}
                  currentItemIndex={2}
                  resetIcon={true ? filters.filter_by_provider !== '' : false}
                  resetFunc={() => updateFilters('filter_by_category', '')}
                  labelText='Category'
                  defaultValue='All(22)'
                  updateValue={(value) => updateFilters('filter_by_category', value)}
                />
              </div>
              <div className='filter-container filter-container-same-line'>
                <EDDatePicker onDateSelected={(startDate, endDate) => updateDateFilter(startDate, endDate)} />
              </div>
              <div className='search-inner filter-container-same-line'>
                <SearchInputField
                  onChange={searchUtil((e: React.ChangeEvent<HTMLInputElement>) => updateFilters('search', e?.target?.value))}
                  placeholder='Search...'
                  width='auto'
                  height='44px'
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div className='table-container'>{loading ? <Loader /> : <Table columns={tableColumns} data={smartMappingList} />}</div>
            {paginationLoading ? (
              <SkeletonLoader />
            ) : (
              pagination && (
                <EDPagination
                  page={pagination?.page ?? 0}
                  pageSize={pagination?.pageSize ?? 0}
                  totalCount={pagination?.totalCount ?? 0}
                  onPageItemClick={handlePagination}
                />
              )
            )}
          </Col>
        </Row>
      </Container>
    </DashboardContainer>
  );
};

export default ValidationDashboard;
