import React from 'react';
import { Button } from 'react-bootstrap';
import './Buttons.scss';
// import type Props from 'shared/types/Button.type';

interface Props {
  buttonNav?:boolean;
  buttonAction?:boolean;
  children: string | JSX.Element;
  active?: boolean;
  disabled?: boolean;
  primary?: boolean;
  status?:boolean;
  statusActive?:boolean;
  secondary?:boolean;
  height?:string;
  onClick?:any;
  SecondaryPressed?:boolean;
}


const CustomButton: React.FC<Props> = ({ children, buttonNav, buttonAction, active, disabled, primary, secondary, SecondaryPressed, status, statusActive, height, onClick }: Props) => {
  let classNameNav = 'nav-font-buttons btn-styles';
  const classNameAction = 'action-button btn-styles';
 
  return (
    primary ? (<Button variant="primary" className='btn-container primary' onClick={onClick}>{children}</Button>) : 
    disabled ? (<Button  className='btn-container disabled' onClick={onClick}>{children}</Button>) :
    buttonNav ? <Button className={classNameNav} onClick={onClick}>{children}</Button> :
    active ? <Button className='nav-font-buttons btn-styles btn-active' onClick={onClick}>{children}</Button>:
    secondary ? <Button className='btn-container secondary' onClick={onClick}>{children}</Button>:
    SecondaryPressed ? <Button className='btn-container secondary pressed' onClick={onClick}>{children}</Button>:
    status ? <Button className='btn-container status' onClick={onClick}>{children}</Button>:
    statusActive ? <Button className='btn-container status pressed' onClick={onClick}>{children}</Button>:
    <Button variant="primary" className='btn-container primary' onClick={onClick}>{children}</Button>

  )
  }

export default CustomButton;
