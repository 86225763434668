
import { serializable, alias, object, list, primitive } from 'serializr';

export class ValidationMarketDimension {

	@serializable(alias('FileName', primitive()))
	FileName?: string;

	@serializable(alias('Cell', primitive()))
	Cell?: string;

	@serializable(alias('Id', primitive()))
	id?: string;

	@serializable(alias('Long', primitive()))
	Long?: string;

	@serializable(alias('Short', primitive()))
	Short?: string;

	@serializable(alias('HierName', primitive()))
	HierName?: string;

	@serializable(alias('HierNum', primitive()))
	HierNum?: string;

	@serializable(alias('ParentTag', primitive()))
	ParentTag?: string;

	@serializable(alias('HierLevelNum', primitive()))
	HierLevelNum?: string;

	@serializable(alias('HierLevelName', primitive()))
	HierLevelName?: string;

	@serializable(alias('Tag', primitive()))
	Tag?: string;

	@serializable(alias('Country', primitive()))
	Country?: string;

	@serializable(alias('Category', primitive()))
	Category?: string;

	@serializable(alias('Channel', primitive()))
	Channel?: string;

	@serializable(alias('UniqueTag', primitive()))
	UniqueTag?: string;

	@serializable(alias('TotalMarket', primitive()))
	TotalMarket?: string;

	@serializable(alias('CreatedOn', primitive()))
	CreatedOn?: string;

	@serializable(alias('ChannelConfidence', primitive()))
	ChannelConfidence?: string;

	@serializable(alias('TotalConfidence', primitive()))
	TotalConfidence?: string;

	@serializable(primitive())
	isSelected?: boolean;

}