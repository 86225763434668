import React, { FC, useEffect, useState } from 'react';
import './highMediumRecord.scss';
import { Row, Col } from 'react-bootstrap';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import ToastComponent from 'shared/components/Toast-component/Toast-component';
import { useParams } from 'react-router';
import ValidationMarketDimensionService from 'services/ValidationMarketDimensionService/validationMarketDimension.service';
import Loader from 'shared/components/Loader';
import Table from 'views/Validations/Component/Table-component/Table-component';
import EDPagination from 'shared/components/Pagination';
import { searchUtil } from 'shared/utils/searchUtil';
import ArrowRight from 'assets/icons/arrow-right.svg';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import CustomButton from 'shared/components/Button-component/Button';
import ValidationService from 'services/ValidationService/validation.service';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';

interface HighMediumRecordProps {
  rowData: any;
  fileName?: string;
  recordsCount?: number;
}

const HighMediumRecord: FC<HighMediumRecordProps> = (props) => {
  const { fileName, recordsCount } = props;
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const { buttonLoading, downloadExcelFile } = ValidationService();

  const column = [
    {
      Header: 'External',
      minWidth: 100,
      columns: [
        {
          Header: 'External Product Description',
          accessor: 'Long',
          minWidth: 300
        },
        {
          Header: 'External Short Description',
          accessor: 'Short',
          minWidth: 300,
          id: 'external_description'
        },
        {
          Header: 'External Tag',
          accessor: 'Tag',
          minWidth: 220
        },
        {
          Header: 'External Hierarchy Number',
          accessor: 'HierNum',
          minWidth: 180,
          id: 'external_hireNumber'
        },
        {
          Header: 'External Hierarchy Name',
          accessor: 'HierName',
          minWidth: 240,
          id: 'external_hireName'
        },
        {
          Header: 'External Hierarchy Level',
          accessor: 'HierLevelNum',
          minWidth: 180,
          id: 'external_hireLevel'
        },
        {
          Header: 'External Hierarchy Level Name',
          accessor: 'HierLevelName',
          minWidth: 240,
          id: 'external_hireLevelName'
        },
        {
          Header: 'External Parent TAG',
          accessor: 'ParentTag',
          minWidth: 220,
          id: 'external_totalMarket'
        },
        {
          Header: 'Unique TAG',
          accessor: 'UniqueTag',
          minWidth: 340,
          id: 'external_UniqueTag'
        }
      ]
    },
    {
      Header: 'Internal',
      minWidth: 200,
      columns: [
        {
          Header: 'Cell',
          accessor: 'Cell',
          minWidth: 200,
          id: 'internal_cell'
        },
        {
          Header: 'Country',
          accessor: 'Country',
          minWidth: 180,
          id: 'internal_country'
        },
        {
          Header: 'Category',
          accessor: 'Category',
          minWidth: 180,
          id: 'internal_category'
        },
        {
          Header: 'Channel',
          accessor: 'Channel',
          minWidth: 180,
          id: 'internal_channel'
        },
        {
          Header: 'Total Market',
          accessor: 'TotalMarket',
          minWidth: 180,
          id: 'unique_tag'
        },
        {
          Header: 'Channel Confidence',
          accessor: 'ChannelConfidence',
          minWidth: 180
        },
        {
          Header: 'Total Confidence',
          accessor: 'TotalConfidence',
          minWidth: 180
        }
      ]
    },
    {
      Header: '',
      accessor: 'null',
      Cell: (row: any) => {
        return (
          <div
            onClick={() => {
              onRowClick(row);
            }}
            className='cursor-pointer'
            onKeyDown={() => {}}
            role='link'
            tabIndex={0}
          >
            <img src={ArrowRight} alt='img' width={20} height={20} />
          </div>
        );
      },
      minWidth: 60
    }
  ];
  const onRowClick = (row: any) => {
    const sidebarRoot = document.querySelector('.sidebar-root') as HTMLElement;
    sidebarRoot.style.setProperty('left', '0px');
    props.rowData(row?.row.original);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const {
    fetchMappedRecords,
    marketMappedRecords,
    toggleCheckStatus,
    toggleAllCheckbox,
    toggleAllStatus,
    validationMarketDimensionsPagination: pagination,
    loading,
    paginationLoading,
    fetchMappedRecordsPagination,
    resetCheckStatus
  } = ValidationMarketDimensionService();

  marketMappedRecords.map((item: any) => {
    for (let key in item)
      if (item[key] === null) {
        item[key] = '-';
      }
  });

  const { confidenceLevel, id } = useParams();

  useEffect(() => {
    if (id && confidenceLevel) {
      fetchMappedRecords(id, confidenceLevel);
      fetchMappedRecordsPagination(id, confidenceLevel);
    }
  }, [id]);

  const handlePagination = (pageNumber: number, recordsPerPage?: number) => {
    const currentPageSize = pagination?.pageSize ?? 0;
    const limit = recordsPerPage ?? currentPageSize;
    const offset = (pageNumber - 1) * limit;
    if (id && confidenceLevel) {
      fetchMappedRecords(id, confidenceLevel, {
        limit,
        offset
      });
      fetchMappedRecordsPagination(id, confidenceLevel, {
        limit,
        offset
      });
    }
  };

  const searchRecords = (value: string) => {
    if (id && confidenceLevel) {
      fetchMappedRecords(id, confidenceLevel, {
        search: value
      });
    }
  };

  const isRaiseIssueButtonEnable = marketMappedRecords.some((item) => item.isSelected === true);

  // const columns = fetchColumns(toggleCheckStatus, toggleAllCheckbox, toggleAllStatus)

  const handleDownload = () => {
    if (id && confidenceLevel && fileName)
      downloadExcelFile(ApiRoutes.DOWNLOAD_MARKET_EXCEL, id, {
        confidenceLevel,
        fileName
      });
  };
  return (
    <div className='high-medium-record'>
      <Row className='justify-content-between'>
        <ToastComponent showState={showNotification} toggleShow={() => setShowNotification(false)} message='Issue raised' />
        <Col sm={4}>
          <CustomButton disabled={!recordsCount} onClick={handleDownload}>
            {buttonLoading ? 'Downloading...' : 'Download File'}
          </CustomButton>
        </Col>
        <Col sm={4} className='search-container'>
          <SearchInputField
            onChange={searchUtil((e: React.ChangeEvent<HTMLInputElement>) => searchRecords(e?.target?.value))}
            placeholder='Search...'
            width='354px'
            height='48px'
          />
        </Col>
      </Row>
      <Row sm={12}>
        <div className='table-container'>
          {loading ? <Loader /> : <Table columns={column} data={marketMappedRecords} tableFor='highMedium' />}
        </div>
        {paginationLoading ? (
          <SkeletonLoader />
        ) : (
          pagination && (
            <EDPagination
              page={pagination?.page ?? 0}
              pageSize={pagination?.pageSize ?? 0}
              totalCount={pagination?.totalCount ?? 0}
              onPageItemClick={handlePagination}
            />
          )
        )}
      </Row>
    </div>
  );
};

export default HighMediumRecord;
