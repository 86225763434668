// Table.js

import React, { Fragment } from 'react';
import { useExpanded, useTable } from 'react-table';
import './Table-component.scss';

interface Props {
  columns: any;
  data: any;
  tableFor?: string;
  renderRowSubComponent?:any;
}

const Table: React.FC<Props> = ({ columns, data, tableFor,renderRowSubComponent }: Props) => {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow,
    visibleColumns // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable(
    {
      columns,
      data
    },
    useExpanded
  ); /*
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <table
      style={{ width: '100%' }}
      className={tableFor === 'highMedium' ? 'table-component-highMedium' : 'table-component'}
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className='table-header'>
            {headerGroup.headers.map((column) => {
              const data = column.render('Header');
              return (
                <th
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width }
                  })}
                  className={typeof data === 'string' ? 'data-exist' : 'empty'}
                >
                  {data}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any,index:any) => {
          prepareRow(row);
          return (
            <Fragment key={index}>
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td
                    
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                          wordBreak:'break-all'
                        }
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
              {row.isExpanded ? (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    {/*
                      Inside it, call our renderRowSubComponent function. In reality,
                      you could pass whatever you want as props to
                      a component like this, including the entire
                      table instance. But for this example, we'll just
                      pass the row
                    */}
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              ) : null}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
export default Table;
