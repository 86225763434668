import React, { FC } from 'react';
import './skeletonLoader.scss';
import { Placeholder } from 'react-bootstrap';
import { SkeletonLoaderAnimationTypes, SkeletonLoaderSizeTypes } from 'enums/skeletonLoaderType';
import clsx from 'clsx';

interface SkeletonLoaderProps {
  xs?: number;
  animation?: SkeletonLoaderAnimationTypes;
  size?: SkeletonLoaderSizeTypes;
  className?: string;
}

const SkeletonLoader: FC<SkeletonLoaderProps> = (props) => {
  const { xs = 12, animation = SkeletonLoaderAnimationTypes.WAVE, size = SkeletonLoaderSizeTypes.LARGE, className } = props;

  return (
    <div className={clsx('skeleton-loader', className)}>
      <Placeholder xs={xs} animation={animation} size={size}>
        <Placeholder xs={xs} />
      </Placeholder>
    </div>
  );
};

export default SkeletonLoader;
