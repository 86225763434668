import { useState } from 'react';
import { deserialize } from 'serializr';
import { generatePath } from 'react-router-dom';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Pagination } from './../../models/Pagination/pagination.model';
import { MappingPeriodDimension } from 'models/MappingPeriodDimension/mappingPeriodDimension.model';

const ValidationPeriodDimensionService = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [pagination, setPagination] = useState<Pagination | null>();

  const [periodDimensionList, setPeriodDimensionList] = useState<MappingPeriodDimension[]>([]);

  const [toggleAllStatus, setToggleAllStatus] = useState<boolean>(false);

  const fetchPeriodMappedData = async (
    id: string,
    params?: {
      limit: number;
      offset: number;
    }
  ) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const url = generatePath(ApiRoutes.VALIDATION_PERIOD_MAPPED_DETAILS, { id });
      const response = await axiosInstance.get(url, { params });
      const data = deserialize(MappingPeriodDimension, response.data['result']) as MappingPeriodDimension[];
      setPeriodDimensionList(data);
    } catch (error) {
      console.log('Error is ', error);
      setPeriodDimensionList([]);
    } finally {
      setLoading(false);
    }
  };
  const fetchPeriodMappedDataPagination = async (
    id: string,
    params?: {
      limit: number;
      offset: number;
    }
  ) => {
    try {
      setPaginationLoading(true);
      const url = generatePath(ApiRoutes.VALIDATION_PERIOD_MAPPED_DETAILS_COUNT, { id });
      const response = await axiosInstance.get(url, { params });
      const pagination = deserialize(Pagination, response.data) as Pagination;
      setPagination(pagination);
    } catch (error) {
      console.log('Error is ', error);
      setPagination(undefined);
    } finally {
      setPaginationLoading(false);
    }
  };

  const toggleCheckStatus = (id: number | undefined) => {
    if (!id) return;

    const selectedItem = periodDimensionList[id];
    selectedItem.isSelected = !selectedItem.isSelected;

    const updatedData = [...periodDimensionList, (periodDimensionList[id] = selectedItem)];
    setToggleAllStatus(false);
    setPeriodDimensionList(updatedData);
  };

  const toggleAllCheckbox = () => {
    const updatedData = periodDimensionList.map((item) => {
      item.isSelected = !toggleAllStatus;
      return item;
    });
    setToggleAllStatus(!toggleAllStatus);
    setPeriodDimensionList(updatedData);
  };

  const resetCheckStatus = () => {
    const updatedData = periodDimensionList.map((item) => {
      item.isSelected = false;
      return item;
    });
    setToggleAllStatus(false);
    setPeriodDimensionList(updatedData);
  };

  return {
    loading,
    buttonLoading,
    pagination,
    periodDimensionList,
    toggleAllStatus,
    fetchPeriodMappedData,
    toggleCheckStatus,
    toggleAllCheckbox,
    resetCheckStatus,
    paginationLoading,
    fetchPeriodMappedDataPagination
  };
};

export default ValidationPeriodDimensionService;
