import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import { generatePath } from 'react-router-dom';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Pagination } from './../../models/Pagination/pagination.model';
import { ValidationMarketDimension } from 'models/ValidationMarketDimension/validationMarketDimension.model';
import deepClone from 'shared/utils/deepClone';
import { MarketUnProcessedRecord } from 'models/MarketUnProcessedRecord/marketUnProcessedRecord.model';

const ValidationMarketDimensionService = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [marketMappedRecords, setMarketMappedRecords] = useState<ValidationMarketDimension[]>([]);

  const [unprocessedRecords, setUnprocessedRecords] = useState<MarketUnProcessedRecord[]>([]);

  const [validationMarketDimensionsPagination, setValidationMarketDimensionsPagination] = useState<Pagination | null>();

  const [toggleAllStatus, setToggleAllStatus] = useState<boolean>(false);

  const fetchMappedRecords = async (
    id: string,
    confidenceLevel: string,
    params?: {
      search?: string;
      limit?: number;
      offset?: number;
    }
  ) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const url = generatePath(ApiRoutes.VALIDATION_MARKET_MAPPED_DETAILS, { id, confidenceLevel });
      const response = await axiosInstance.get(url, { params });
      const data = deserialize(ValidationMarketDimension, response.data['result']) as ValidationMarketDimension[];
      setMarketMappedRecords(data);
    } catch (error) {
      setMarketMappedRecords([]);
    } finally {
      setLoading(false);
    }
  };
  const fetchMappedRecordsPagination = async (
    id: string,
    confidenceLevel: string,
    params?: {
      search?: string;
      limit?: number;
      offset?: number;
    }
  ) => {
    try {
      setPaginationLoading(true);
      const url = generatePath(ApiRoutes.VALIDATION_MARKET_MAPPED_DETAILS_COUNT, { id, confidenceLevel });
      const response = await axiosInstance.get(url, { params });
      const pagination = deserialize(Pagination, response.data);
      setValidationMarketDimensionsPagination(pagination);
    } catch (error) {
      setValidationMarketDimensionsPagination(undefined);
    } finally {
      setPaginationLoading(false);
    }
  };

  const toggleCheckStatus = (id: number | undefined) => {
    if (!id) return;

    const selectedItem = marketMappedRecords[id];

    marketMappedRecords[id].isSelected = !selectedItem.isSelected;

    setToggleAllStatus(false);
    setMarketMappedRecords(deepClone(marketMappedRecords));
  };

  const toggleAllCheckbox = () => {
    const updatedData = marketMappedRecords.map((item) => {
      item.isSelected = !toggleAllStatus;
      return item;
    });
    setToggleAllStatus(!toggleAllStatus);
    setMarketMappedRecords(updatedData);
  };

  const resetCheckStatus = () => {
    const updatedData = marketMappedRecords.map((item) => {
      item.isSelected = false;
      return item;
    });
    setToggleAllStatus(false);
    setMarketMappedRecords(updatedData);
  };

  const fetchUnProcessedRecords = async (
    id: string,
    params?: {
      Filename?:string;
      search?: string;
      limit?: number;
      offset?: number;
    }
  ) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      setLoading(true);
      const url = generatePath(ApiRoutes.SMART_MAPPING_UN_PROCESSED_MARKET,{id});
      const response = await axiosInstance.get(url, { params });
      const data = deserialize(MarketUnProcessedRecord, response.data['result']) as MarketUnProcessedRecord[];
      setUnprocessedRecords(data);
    } catch (error) {
      setUnprocessedRecords([]);
    } finally {
      setLoading(false);
    }
  };
  const fetchUnProcessedRecordsPagination = async (
    id: string,
    params?: {
      Filename?:string;
      search?: string;
      limit?: number;
      offset?: number;
    }
  ) => {
    try {
      setPaginationLoading(true);
      const url = generatePath(ApiRoutes.SMART_MAPPING_UN_PROCESSED_MARKET_COUNT, { id });
      const response = await axiosInstance.get(url, { params });
      const pagination = deserialize(Pagination, response.data);
      setValidationMarketDimensionsPagination(pagination);
    } catch (error) {
      setValidationMarketDimensionsPagination(undefined);
    } finally {
      setPaginationLoading(false);
    }
  };

  return {
    loading,
    marketMappedRecords,
    buttonLoading,
    validationMarketDimensionsPagination,
    toggleAllStatus,
    unprocessedRecords,
    fetchMappedRecords,
    toggleCheckStatus,
    toggleAllCheckbox,
    resetCheckStatus,
    fetchUnProcessedRecords,
    paginationLoading,
    fetchUnProcessedRecordsPagination,
    fetchMappedRecordsPagination
  };
};

export default ValidationMarketDimensionService;
