import React, { useEffect, useState } from 'react';
import '../../../../shared/components/SidebarHighMedium/SidebarHighMedium.scss';
import closeButton from 'assets/icons/close-button.svg';
import { Button, Col, Row } from 'react-bootstrap';
import NewEDDropdown from '../SiderbarDropdown-component/SidebarDropdown-component';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { addToken } from 'interceptor/axiosInstance';
import ToastComponent from 'shared/components/Toast-component/Toast-component';
import { SideBarDropDownMarket } from '../SideBarDropDownMarket';

const SidebarHighMedium = ({ row }: any) => {
  const [submitResponse, setSubmitResponse] = useState<any>();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [metaData, setMetaData] = useState<any>([]);

  const setInitalRowValues = () => {
    const sidebarValues = JSON.parse(JSON.stringify(SideBarDropDownMarket));
    sidebarValues.forEach((item: any) => {
      item.value = item.internal ? row[item.internal] : null;
    });

    setMetaData(sidebarValues);
  };

  const chechSubmitButtonStatus = () => {
    setIsSubmitDisable(false);
    metaData.forEach((obj: any) => {
      if (obj.internal) {
        obj.value ? '' : setIsSubmitDisable(true);
      }
    });
  };

  useEffect(() => {
    chechSubmitButtonStatus();
  }, [metaData]);

  useEffect(() => {
    setInitalRowValues();
    chechSubmitButtonStatus();
  }, [row.id]);

  const updateValue = (key: string, value: any) => {
    metaData.forEach((obj: any) => {
      if (obj.internal === key) obj.value = value;
      else {
        if (obj.dependsOn.includes(key)) {
          obj.value = '';
        }
      }
    });

    setMetaData([...metaData]);
  };

  const generateApi = (item: any): string => {
    const api: any = [];
    item.apiDependsOn.forEach((attribute: string) => {
      metaData.map((obj: any) => {
        if (obj.internal == attribute) {
          const encodedValue = encodeURIComponent(obj.value);
          api.push(attribute + '=' + encodedValue);
        }
      });
    });

    if (api.length) return item.apiEndPoint + '?' + api.join('&');
    else return item.apiEndPoint;
  };

  const checkDisabledStatus = (item: any) => {
    var status = false;
    item.dependsOn.forEach((attribute: string) => {
      metaData.forEach((obj: any) => {
        if (obj.internal === attribute) {
          if (!obj.value || obj.value === '') status = true;
        }
      });
    });
    return status;
  };

  const closeSidebar = () => {
    setInitalRowValues();
  };

  const handelSubmit = async () => {
    var attributes: any = {};
    metaData.forEach((item: any) => {
      if(item.internal)
      attributes[item.internal] = item.value;
    });

    const url = `${ApiRoutes.BASE_URL}${ApiRoutes.Market_DROPDOWN}${row.id}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: await addTokenOnHeaders(),
      body: JSON.stringify(attributes)
    });
    const data = await response.json();
    setSubmitResponse(data.message);
    toggleToast();
  };

  const addTokenOnHeaders = async () => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${await addToken()}`);
    headers.append('Content-Type', 'application/json');
    return headers;
  };

  const toggleToast = () => {
    setShowToast(!showToast);
  };

  return (
    <>
      {row ? (
        <div className='row-description'>
          <ToastComponent showState={showToast} toggleShow={toggleToast} message={submitResponse} />
          <div className='header'>
            <p>Mapping Details</p>
            <button
              onClick={() => {
                closeSidebar();
                const sidebarRoot = document.querySelector('.sidebar-root') as HTMLElement;
                sidebarRoot.style.setProperty('left', '9999px');
              }}
            >
              <img src={closeButton} alt='img' width={20} height={20} />
            </button>
          </div>

          <div className='content'>
            <Row className='data-header'>
              <Col sm={3} className='header'>
                Attributes (Column Name)
              </Col>
              <Col sm={3} className='header'>
                External
              </Col>
              <Col sm={4} className='header'>
                Internal
              </Col>
            </Row>
            <hr />
            {metaData.map((item: any) => (
              <Row className='data'>
                <Col sm={3}>{item.attributeName}</Col>
                <Col sm={3} style={{wordBreak:'break-all'}}>{row[item.external] ? row[item.external] : 'NA'}</Col>
                <Col sm={4}>
                  {item.value === null ? (
                    'NA'
                  ) : (
                    <NewEDDropdown
                      value={item.value}
                      currentItemIndex={2}
                      defaultValue={() => generateApi(item)}
                      updateValue={(value: any) => {
                        updateValue(item.internal, value);
                      }}
                      resetIcon={true}
                      resetFunc={() => {}}
                      isDisabled={() => checkDisabledStatus(item)}
                    />
                  )}
                </Col>
              </Row>
            ))}
          </div>

          <div className='footer'>
            <Button variant='primary' className='cancle-button' onClick={setInitalRowValues}>
              Reset
            </Button>
            <Button variant='primary' className='submit-button' onClick={handelSubmit} disabled={isSubmitDisable}>
              Submit
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SidebarHighMedium;
