import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import './ToolsSection.scss'
import VerticalTabs from '../VerticalTabs';
const ToolsSection = () => {
  return (
    <Container fluid className='tools-container'>
      <Row>
        <Col sm={12} className='left-content'>
          <VerticalTabs />
        </Col>
      </Row>
    </Container>
  )
}

export default ToolsSection