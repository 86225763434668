import React, { useEffect, useState } from 'react';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import BreadcrumbComponent from 'shared/components/Breadcrumb-component/Breadcrumb-component';
import Databar from 'shared/components/Databar-component/Databar';
import TabComponent from 'shared/components/Tab-component/Tabs';
import Mapped from './Mapped';
import Unmapped from './Unmapped';
import { Container, Row, Col } from 'react-bootstrap';
import './ValidationRecordDetails.scss';
import ValidationService from 'services/ValidationService/validation.service';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import Loader from 'shared/components/Loader';
import UnprocessRecords from './UnprocessRecords';
import Sidebar from 'react-sidebar';
import SidebarHighMedium from '../SidebarHighMedium-component/SidebarHighMedium';
import RedirectLeft from 'assets/icons/redirect-left.svg';
import RedirectRight from 'assets/icons/redirect-right.svg';
import { displayDateTimeFormat } from 'constants/formats';

const ValidationRecordDetails = () => {
  const { fetchIndividualSmartMapping, smartMapping } = ValidationService();

  const { id, confidenceLevel } = useParams();

  const navigate = useNavigate();

  const [rowData, setRowData] = useState();

  useEffect(() => {
    id && fetchIndividualSmartMapping(id);
  }, []);

  if (!id) return <Loader />;

  if (!smartMapping) return <Loader />;

  const formattedDate = moment.utc(smartMapping.createdAt).format(displayDateTimeFormat);

  const successStatus = ((smartMapping.mappedRecordCount ?? 0) / (smartMapping.total ?? 0)) * 100;

  const setRow = (value: any) => {
    const sidebarRoot = document.querySelector('.sidebar-root') as HTMLElement;
    sidebarRoot.style.setProperty('left', '0px');
    setRowData(value);
  };

  const onTabSelect = (key: string | null) => {
    if (!key) return;
    navigate(
      {
        pathname: `../validation-record-details/${id}/${key}`
      },
      { replace: true }
    );
  };

  return (
    <DashboardContainer>
      <Container fluid className='page-container-details'>
        <Row className='breadcrumb-container'>
          <Col sm={12}>
            <p className='page-heading'>Record Detail</p>
            <BreadcrumbComponent
              links={[
                { link: 'Checks', active: false, onClick: () => navigate(`/${AppRoutes.CHECKS}`, { replace: true }) },
                {
                  link: 'Intelligent Mapping',
                  active: false,
                  onClick: () => navigate(`/${AppRoutes.CHECKS}/${AppRoutes.VALIDATION_RECORDS}`, { replace: true })
                },
                { link: 'Record Detail', active: true }
              ]}
            />
          </Col>
          {/* Filename heading */}
          <Row>
            <div className='file-name-row'>
              <div className='file-change-btn'>
                <img src={RedirectLeft} alt='left-arrow' />
              </div>
              <p>Filename:</p>
              <span className='hyper-link'>{smartMapping.fileName}</span>
              <div className='file-change-btn'>
                <img src={RedirectRight} alt='right-arrow' />
              </div>
            </div>
          </Row>
        </Row>
        <Row className='databar-container'>
          <Databar
            DataProvider={smartMapping?.dataProvider ?? ''}
            TotalRecords={smartMapping.total ?? 0}
            successStatus={successStatus ?? 0}
            loadedOn={formattedDate}
          />
        </Row>
        <Row className='tabs-container'>
          <TabComponent
            mountOnEnter
            unmountOnExit
            active={confidenceLevel ?? 'high'}
            onTabChange={onTabSelect}
            components={[
              {
                key: 'high',
                title: 'High',
                component: (
                  <>
                    <Mapped
                      confidenceLevel='high'
                      rowData={setRow}
                      fileName={smartMapping?.fileName}
                      recordsCount={smartMapping.confidenceLevelLessThan70}
                    />
                    {confidenceLevel === 'high' && (
                      <Sidebar
                        open={true}
                        pullRight={true}
                        styles={{
                          root: { position: 'absolute', left: '9999px' },
                          sidebar: { zIndex: '5' },
                          content: { position: 'relative' },
                          overlay: { zIndex: '0', position: 'relative' }
                        }}
                        sidebarClassName='sidebar'
                        rootClassName='sidebar-root'
                        sidebar={rowData ? <SidebarHighMedium row={rowData} /> : <></>}
                        transitions={true}
                      />
                    )}
                  </>
                )
              },
              {
                key: 'medium',
                title: 'Medium',
                component: (
                  <>
                    <Mapped
                      confidenceLevel='medium'
                      rowData={setRow}
                      fileName={smartMapping.fileName}
                      recordsCount={smartMapping.confidenceLevel5070}
                    />
                    {confidenceLevel === 'medium' && (
                      <Sidebar
                        open={true}
                        pullRight={true}
                        styles={{
                          root: { position: 'absolute', left: '9999px' },
                          sidebar: { zIndex: '5' },
                          content: { position: 'relative' },
                          overlay: { zIndex: '0', position: 'relative' }
                        }}
                        sidebarClassName='sidebar'
                        rootClassName='sidebar-root'
                        sidebar={rowData ? <SidebarHighMedium row={rowData} /> : <></>}
                        transitions={true}
                      />
                    )}
                  </>
                )
              },
              {
                key: 'low',
                title: 'Low',
                component: (
                  <Unmapped fileName={smartMapping.fileName} confidenceLevel='low' recordsCount={smartMapping.confidenceLevelLessThan50} />
                )
              },
              {
                key: 'unprocessed',
                title: 'Unprocessed',
                component: <UnprocessRecords fileName={smartMapping.fileName} recordsCount={smartMapping.unmappedRecordCount} />
              }
            ]}
          />
        </Row>
      </Container>
    </DashboardContainer>
  );
};

export default ValidationRecordDetails;
