import React from 'react';
import moment from 'moment';
import { Column } from 'react-table';
import { displayDateTimeFormat } from 'constants/formats';

const columns: Column[] = [
  {
    Header: 'FileName',
    accessor: 'FileName',
    minWidth:300,
  },
  {
    Header: 'Tag',
    accessor: 'Tag',
    minWidth:300,
  },
  {
    Header: 'Long',
    accessor: 'Long',
    minWidth:300,
  },
  {
    Header: 'HierName',
    accessor: 'HierName',
    minWidth:300,
  },
  {
    Header: 'HierLevelName',
    accessor: 'HierLevelName',
    minWidth:300,
  },
  {
    Header: 'Country',
    accessor: 'Country',
    minWidth:200,
  },
  {
    Header: 'Category',
    accessor: 'Category',
    minWidth:200,
  },
  {
    Header: 'Cell',
    accessor: 'Cell',
    minWidth:280,
  },
  {
    Header: 'Created On',
    accessor: 'Createdon',
    minWidth:240,
    Cell: ({ cell }: any) => (
      <div role='link' tabIndex={0}>
        {moment.utc(cell.value).format(displayDateTimeFormat)}
      </div>
    )
  }
];

export default columns;
