
import { serializable, alias, object, list, primitive } from 'serializr';

export class DqCheck {

	@serializable(alias('Country', primitive()))
	Country?: string;

	@serializable(alias('Category', primitive()))
	Category?: string;

	@serializable(alias('CellDatabase', primitive()))
	CellDatabase?: string;

	@serializable(alias('Dimension', primitive()))
	Dimension?: string;

	@serializable(alias('zipFile', primitive()))
	FileName?: string;

	@serializable(alias('Checks_Failed', primitive()))
	Checks_Failed?: string;

	@serializable(alias('Checks_Passed', primitive()))
	Checks_Passed?: string;

	@serializable(alias('DeliveryPeriod', primitive()))
	DeliveryPeriod?: string;

	@serializable(alias('Overall_Status', primitive()))
	OverallStatus?: string;

	@serializable(alias('Number_of_files_that_passed_Check', primitive()))
	ChecksPassed?: string;

	@serializable(alias('Number_of_files_that_failed_Check', primitive()))
	ChecksFailed?: string;

	@serializable(alias('Remarks', primitive()))
	Remarks?: string;

}