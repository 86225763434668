import React from 'react'
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer'
import WorkInProgress from 'shared/components/WorkInProgress'

const WorkUnderProgress = () => {
  return (
    <DashboardContainer>
      <WorkInProgress />
    </DashboardContainer>
  )
}

export default WorkUnderProgress