import React from 'react';
import './Databar.scss';
interface Props {
  DataProvider: string
  TotalRecords: number
  successStatus: number
  loadedOn: string
}
const Databar: React.FC<Props> = ({ DataProvider, TotalRecords, successStatus, loadedOn }: Props) => {
  return (
    <div className='container'>
      <div className='item-container'>
        <div className='item'>
          <h1>Data Provider: </h1>
        </div>
        <div className='item'>
          <p>{DataProvider}</p>
        </div>
      </div>
      <div className='item-container'>
        <div className='item'>
          <h1>Total Records: </h1>
        </div>
        <div className=' item'>
          <p>{TotalRecords}</p>
        </div>
      </div>
      <div className='item-container'>
        <div className='item'>
        <h1>Success Status: </h1>
        </div>
        <div className=' item'>
        <p className='success'>{successStatus.toFixed(2)}%</p>
        </div>
      </div>
      <div className='item-container'>
        <div className='item'>
        <h1>Loaded On: </h1>
        </div>
        <div className='item'>
        <p>{loadedOn}</p>
        </div>
      </div>
    </div>
  );
};

export default Databar;
