import React, { useState, forwardRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './SearchDropdown.scss';
import SearchInputField from '../Search-input-field/search-input-field';
import DropdownIcon from 'assets/icons/dropdown-icon.svg';
import updateTargetModal from '../update-target-modal';

const CustomToggle = forwardRef<
  HTMLAnchorElement,
  { children: React.ReactNode; onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void }
>(({ children, onClick }, ref) => (
  /*eslint-disable */
  <a
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className='toggle-button'
  >
    {children}
    <img src={DropdownIcon} alt='dropdown icon' />
  </a>
  /*eslint-enable */
));

const CustomMenu = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode; style: React.CSSProperties; className: string; 'aria-labelledby': string }
>(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <SearchInputField onChange={(e: any) => setValue(e.target.value)} placeholder='Search' width='354px' height='48px' />
      <ul className='list-unstyled'>
        {React.Children.toArray(children).filter(
          (child) => !value || (child as React.ReactElement<{ children: string }>).props.children?.toLowerCase()?.includes(value)
        )}
      </ul>
    </div>
  );
});

interface SearchDropdownProps {
  optionItems: {
    key: string;
    value: string;
  }[];
  value: string;
  className?: string;
  currentItemIndex: number;
  updateTargetValue: (name: string, index: number) => void;
}

const SearchDropdown = (props: SearchDropdownProps) => {
  const { optionItems, value, updateTargetValue, currentItemIndex, className } = props;

  return (
    <Dropdown className={`dropdown-container ${className}`}>
      <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' className='toggle-container'>
        {value}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} className='toggle-menu'>
        {optionItems.map((item) => {
          return (
            <Dropdown.Item onClick={(e) => updateTargetValue(item.value, currentItemIndex)} eventKey={item.key} key={item.key}>
              {item.value}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SearchDropdown;
