import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CustomButton from 'shared/components/Button-component/Button';
import UnmappedCup from 'assets/icons/unmapped-cup.svg';
import ArrowImg from 'assets/icons/CardArrowVector.svg';
import Table from 'shared/components/Table-component/Table-component';
import { useParams } from 'react-router';
import ToastComponent from 'shared/components/Toast-component/Toast-component';
import './Unmapped.scss';
import SearchInputField from 'shared/components/Search-input-field/search-input-field';
import Loader from 'shared/components/Loader';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import ValidationService from 'services/ValidationService/validation.service';

interface UnmappedProps {
  fileName?: string;
  confidenceLevel?: string;
  recordsCount?: number;
}

const Unmapped = (props: UnmappedProps) => {
  const [showTable, setshowTable] = React.useState<number | undefined>();
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const toggleShowToast = () => setShowToast(!showToast);
  const [searchString, setSearchString] = useState<string>('');
  const { fileName, recordsCount, confidenceLevel } = props;

  const {
    fetchUnMappedData,
    mappedData,
    toggleMappedCheckStatus,
    toggleUnMappedCheckStatus,
    submitUnMappedResult,
    fetchLowRecordSuggestion,
    nearestMatchCount,
    searchSuggestions,
    resetSearchSuggestion,
    loading,
    buttonLoading,
    downloadExcelFile
  } = ValidationService();

  const { id } = useParams();

  const handleShowTable = async (index: string | undefined, id: string | undefined) => {
    if (!index) return;
    if (!id) return;
    let selectedIndex = parseInt(index);
    resetSearchSuggestion(showTable);
    toggleMappedCheckStatus(id);
    setshowTable(selectedIndex);
    fetchLowRecordSuggestion(id);
    setSearchString('');
  };

  const columns: any = [
    {
      id: 'checkboxes',
      accessor: 'isChecked',
      Cell: ({ value, row }: any) => {
        return (
          <input
            type='radio'
            className='radio m-auto'
            checked={value}
            onChange={() => toggleUnMappedCheckStatus(row?.original?.id, showTable)}
          />
        );
      }
    },
    {
      Header: 'Internal(Unilever)',
      accessor: 'Internaldesc',
      minWidth: 300
    },
    {
      Header: 'Division',
      accessor: 'Divisionname',
      minWidth: 200
    },
    {
      Header: 'Category',
      accessor: 'Categoryname'
    },
    {
      Header: 'Market',
      accessor: 'Marketname',
      minWidth: 150
    },
    {
      Header: 'Corporate brand name',
      accessor: 'Corporatebrandname',
      minWidth: 150
    }
  ];

  useEffect(() => {
    id && fetchUnMappedData(id);
  }, []);

  if (!id) return <Loader />;
  if (loading) return <Loader />;

  const isSubmitButtonEnabled = mappedData.some((item) => item.isChecked === true);

  const handleDownload = () => {
    if (id && confidenceLevel && fileName)
      downloadExcelFile(ApiRoutes.DOWNLOAD_PRODUCT_EXCEL, id, {
        confidenceLevel,
        fileName
      });
  };

  return (
    <Container fluid className='container-unmapped'>
      <ToastComponent showState={showToast} toggleShow={toggleShowToast} message='Your Entry has been saved. ' />
      <Col sm={4}>
        <CustomButton onClick={handleDownload} disabled={!recordsCount}>
          {buttonLoading ? 'Downloading...' : 'Download File'}
        </CustomButton>
      </Col>
      <Row className='header-row'>
        <Col sm={3} className='unmapped-items-header'>
          <div className='count'>{mappedData.length}</div>
          <div className='heading-container'>
            <h2>External Description</h2>
            <p>From data provider’s file</p>
          </div>
        </Col>
        <Col sm={9} className='unmapped-items-header'>
          <div className='heading-container suggestion-record-container'>
            <div className={` d-flex ${showTable !== undefined ? '' : ' disabled'} `}>
              <span className='count'>{nearestMatchCount}</span>
              <div className='count-header ml-2'>
                <p className='mb-1'>Nearest Match</p>
                <span>From Unilever database for selected item</span>
              </div>
            </div>
            <div className='mr-4'>
              <SearchInputField
                onChange={(e: any) => {
                  const value = e.target.value;
                  setSearchString(value);
                  searchSuggestions(showTable, value);
                }}
                value={searchString}
                placeholder='Search...'
                width='354px'
                height='48px'
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className='header-row'>
        <Col sm={3} className='list-container'>
          {mappedData.map((item, index) => {
            return (
              <button
                key={item.Id}
                type='button'
                onClick={() => handleShowTable(index.toString(), item?.Id?.toString())}
                className={'list-item-container ' + (showTable === index ? 'active ' : ' ') + (item.isChecked ? ' selected ' : '')}
              >
                {item.Externaldesc}
                {showTable === index && <img src={ArrowImg} alt='arrow' className='arrow-logo-btn float-end' />}
              </button>
            );
          })}
        </Col>
        <Col sm={9} className='span-area'>
          {showTable !== undefined && mappedData[showTable] ? (
            <div className='table-container-unmapped'>{<Table columns={columns} data={mappedData[showTable].searchResult} />}</div>
          ) : (
            <>
              <img src={UnmappedCup} alt='cup' />
              <p>Click one of the unmapped Item to view the nearest match. </p>
            </>
          )}
        </Col>
      </Row>
      <Row className='header-row'>
        <Col sm={9} className='footer-unmapped'>
          <CustomButton secondary>Cancel</CustomButton>
          <CustomButton
            primary={isSubmitButtonEnabled}
            disabled={!isSubmitButtonEnabled}
            onClick={() => {
              submitUnMappedResult(id).then(() => {
                toggleShowToast();
                setshowTable(undefined);
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              });
            }}
          >
            Submit
          </CustomButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Unmapped;
