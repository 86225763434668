import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Arrow from 'assets/icons/CardArrowVector.svg';
import './AreaChartCard.scss';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import NewEDDropdown from '../NewEDDropdown';
import DashboardServiceService from 'services/DashboardServiceService/dashboardService.service';
import Loader from '../Loader';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { MetaData } from 'models/MetaData/metaData.model';
import MetaService from 'services/MetaService/meta.service';
import clsx from 'clsx';

interface AreaChartCardProps {
  startDate?: string;
  endDate?: string;
  country?: string;
}

export interface ValidationFilters {
  filter_by_provider: string;
}

const AreaChartCard = (props: AreaChartCardProps) => {
  const { startDate, endDate, country } = props;

  const [filters, setFilters] = useState<ValidationFilters>({
    filter_by_provider: ''
  });

  const updateFilters = (filterName: keyof ValidationFilters, value: string) => {
    setFilters({
      ...filters,
      [filterName]: value
    });

    fetchVolatilityMetrics({
      filter_by_provider: value
    });
  };

  // const handleProviderChange = (value: string) => {
  //   fetchVolatilityMetrics({
  //     filter_by_provider: value
  //   });
  // };
  // const handleReset = () => {
  //   fetchVolatilityMetrics({ filter_by_provider: '' });
  // };

  const { fetchVolatilityMetrics, volatilityMetrics, volatilityUniqueColumns, loading } = DashboardServiceService();

  useEffect(() => {
    fetchVolatilityMetrics();
    fetchMeta(ApiRoutes.HOME_PROVIDER_META, setProviderMeta, MetaData);
  }, []);

  const { fetchMeta } = MetaService();

  const [providerMeta, setProviderMeta] = useState<MetaData[]>([]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchVolatilityMetrics({
        start_date: startDate,
        end_date: endDate
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchVolatilityMetrics({
        filter_by_country: country
      });
    }
  }, [country]);

  // if (!volatilityMetrics) return <Loader />;

  return (
    <Container fluid className='area-chart-card'>
      <Row>
        <div className='card-title-container'>
          <div className='heading-container'>
            <h3 className='heading-container-text'>Logs</h3>
            <p>Hover on points to view detail.For tabular view click here.</p>
          </div>
          <img src={Arrow} alt='arrow-icon' />
        </div>
      </Row>
      <Row>
        <Col sm={8} className='area-chart-container'>
          {loading ? (
            <Loader />
          ) : (
            <ResponsiveContainer width='100%' height='100%'>
              <AreaChart
                width={400}
                height={300}
                data={volatilityMetrics}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0
                }}
              >
                <defs>
                  <linearGradient id={`#FF9E00`} x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='0%' stopColor={'#FF9E00'} stopOpacity={0.9}></stop>
                    <stop offset='99%' stopColor={'#FF9E00'} stopOpacity={0.01}></stop>
                  </linearGradient>
                  <linearGradient id={`#FF0A7A`} x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='0%' stopColor={'#FF0A7A'} stopOpacity={0.9}></stop>
                    <stop offset='99%' stopColor={'#FF0A7A'} stopOpacity={0.01}></stop>
                  </linearGradient>
                  <linearGradient id={`#9C44C0`} x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='0%' stopColor={'#9C44C0'} stopOpacity={0.9}></stop>
                    <stop offset='99%' stopColor={'#9C44C0'} stopOpacity={0.01}></stop>
                  </linearGradient>
                </defs>
                {volatilityMetrics?.length && (
                  <XAxis
                    dataKey='name'
                    label={{
                      value: `( ${volatilityMetrics[0].name} - ${volatilityMetrics[volatilityMetrics.length - 1].name} )`,
                      dy: 20
                    }}
                  />
                )}
                <YAxis label={{ value: 'No.of files', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                {volatilityUniqueColumns.map((item, index) => {
                  let strokeColor = '';
                  switch (index) {
                    case 0:
                      strokeColor = '#FF9E00';
                      break;
                    case 1:
                      strokeColor = '#FF0A7A';
                      break;
                    case 2:
                      strokeColor = '#9C44C0';
                      break;
                  }

                  return <Area key={index} type='monotone' dataKey={item} stackId='1' stroke={strokeColor} fill={strokeColor} />;
                })}
              </AreaChart>
            </ResponsiveContainer>
          )}
        </Col>

        <Col sm={4} className='right-container'>
          <p className='data-providers-heading'>Data Providers</p>
          <div className='filter-container'>
            <NewEDDropdown
              // optionItems={providerMeta ?? []}
              // currentItemIndex={2}
              // labelText='Data Provider'
              // defaultValue='All(22)'
              // resetFunc={handleReset}
              // updateValue={handleProviderChange}

              // loading={!changedFilter && metaLoading}
              value={filters.filter_by_provider}
              optionItems={providerMeta ?? []}
              currentItemIndex={2}
              resetIcon={true ? filters.filter_by_provider !== '' : false}
              resetFunc={() => updateFilters('filter_by_provider', '')}
              labelText='Data Provider'
              defaultValue='All(22)'
              updateValue={(value) => updateFilters('filter_by_provider', value)}
            />
          </div>
          <div className='list-container'>
            <ul className='data-providers'>
              {volatilityUniqueColumns.map((source, i) => (
                <div key={source} className='data-provider'>
                  <span className={clsx('data-provider-dot', `dot-${i}`)} />
                  <li className='data-provider-name'>{source}</li>
                </div>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AreaChartCard;
