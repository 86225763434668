
import { serializable, alias, object, list, primitive } from 'serializr';

export class Pagination {

	@serializable(alias('page', primitive()))
	page: number = 1;

	@serializable(alias('page_size', primitive()))
	pageSize: number = 10;

	@serializable(alias('total_count', primitive()))
	totalCount: number = 0;

}