import React, { FC } from 'react'
import prevIcon from 'assets/icons/prev-icon.svg'
import nextIcon from 'assets/icons/next-icon.svg'
import prevIconDisabled from 'assets/icons/pre-icon-disabled.svg'
import nextIconDisabled from 'assets/icons/next-icon-disabled.svg'

interface PageLinkProps {
    disabled?: boolean,
    type: "prev" | "next",
    onPageItemClick: (pageNumber: number) => void,
    redirectPageNumber: number,
}

const PageLink: FC<PageLinkProps> = (props) => {

    const { disabled, type, onPageItemClick, redirectPageNumber } = props

    let name = ""
    let arrowIcon = ""

    if (type === 'next') {
        name = "Next"
        arrowIcon =  disabled? nextIconDisabled : nextIcon
    } else {
        name = "Previous"
        arrowIcon = disabled? prevIconDisabled : prevIcon
    }

    return (
        <li className={`page-item ${disabled ? 'disabled' : ''} `}>
            <span className="page-link cursor-pointer" role="button" >
                <span aria-hidden="true" onClick={(e) => onPageItemClick(redirectPageNumber)}>
                    {
                        type === 'prev' && (<img className='left-arrow-icon' src={arrowIcon} alt={name} />)
                    }
                    {name}
                    {
                        type === 'next' && (<img className='right-arrow-icon' src={arrowIcon} alt={name} />)
                    }
                </span>
                <span className="visually-hidden">{name}</span>
            </span>
        </li>
    );
}


export default PageLink